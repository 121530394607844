import axios from '@/services/axios-http-config';
// import jwtDecode from 'jwt-decode'

const state = {
    airports: [],
    YairportFlag: false,
    DairportFlag: false,
    NairportFlag: false,
    
}

const getters = {
    airports: state => state.airports,
    YairportFlag: state => state.YairportFlag,
    DairportFlag: state => state.DairportFlag,
    NairportFlag: state => state.NairportFlag,

}


const mutations = {
    SET_AIRPORT_SYSTEM(state, payload) {
        state.airports = Array.isArray(payload) ? payload : [payload]
    },
    UPDATE_YAOUNDE_TAXI_FLAG(state, payload) {
        state.YairportFlag = payload;
    },
    UPDATE_DOUALA_TAXI_FLAG(state, payload) {
        state.DairportFlag = payload;
    },
    UPDATE_GAROUA_TAXI_FLAG(state, payload) {
        state.NairportFlag = payload;
    },

}

const actions = {
    get_AirportsparamIR({ commit }, data) {
        return axios.get('v1/airport/read').then((response) => {
            response.data != null ? commit('SET_AIRPORT_SYSTEM', response.data) : null
        });
    },
    update_YaoundeTaxiFlag({ commit }, data) {
        commit("UPDATE_YAOUNDE_TAXI_FLAG", data)
    },
    update_DoualaTaxiFlag({ commit }, data) {
        commit("UPDATE_DOUALA_TAXI_FLAG", data)
    },
    update_GarouaTaxiFlag({ commit }, data) {
        commit("UPDATE_GAROUA_TAXI_FLAG", data)
    },
}

const airportModule = {
    state,
    mutations,
    actions,
    getters
}

export default airportModule;