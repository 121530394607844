<template>
	<div class="card border-light bg-transparent m-0 mb-1 p-0">
		<picture v-if="$i18n.locale == 'fr'">
      <source srcset="@/assets/files/brand/direction4.webp" type="image/webp">
      <source srcset="@/assets/files/brand/direction4.avif" type="image/avif">
      <img
        src="@/assets/files/brand/direction4.png"
        class="bottom-0 w-100"
        alt="hero screen"
        style="right: 5%"
      />
    </picture>
    <picture v-else>
      <source srcset="@/assets/files/brand/direction6.webp" type="image/webp">
      <source srcset="@/assets/files/brand/direction6.avif" type="image/avif">
      <img
        src="@/assets/files/brand/direction6.png"
        class="bottom-0 w-100"
        alt="hero screen"
        style="right: 5%"
      />
    </picture>
		<div class="card-body fs-6 small" v-if="$Place_kits.IsGeoHSON(res.departure_point) && $Place_kits.IsGeoHSON(res.arrival_point)">
			<h5 class="card-title fw-bold">Trip Resume</h5>
      <p class="card-text fw-bold"> <span class="fw-normal"> <i class=" bi bi-person-vcard  mr-2"></i> {{$t('booking.personalD.name')}}</span> : {{res.surname}} {{res.name}}</p>
			<p class="card-text fw-bold"> <span class="fw-normal"> <i class=" bi bi-circle  mr-2"></i> {{$t('landing.booking.remote.departI')}}</span> : {{
				res.departure_point.address.aeroway?
          res.departure_point.address.aeroway
          :
              res.departure_point.address.amenity
              ?
              res.departure_point.address.amenity
              :
              res.departure_point.address.building
                  ?
                  res.departure_point.address.building
                  :
                  res.departure_point.address.town
                      ? 
                      res.departure_point.address.town
                      :
                      res.departure_point.address.suburb
                          ?
                          res.departure_point.address.suburb
                          :
                          ""
			}}</p>
			<p class="card-text fw-bold"> <span class="fw-normal"> <i class=" bi bi-geo-alt-fill  mr-2"></i> {{$t('landing.booking.remote.arrivalI')}}</span> : {{
				res.arrival_point.address.aeroway?
          res.arrival_point.address.aeroway
          :
              res.arrival_point.address.amenity
              ?
              res.arrival_point.address.amenity
              :
              res.arrival_point.address.building
                  ?
                  res.arrival_point.address.building
                  :
                  res.arrival_point.address.town
                      ? 
                      res.arrival_point.address.town
                      :
                      res.arrival_point.address.suburb
                          ?
                          res.arrival_point.address.suburb
                          :
                          ""
			}}</p>

      <p class="card-text fw-bold"> <span class="fw-normal"> <i class=" bi bi-alarm-fill  mr-2"></i> {{$t('landing.booking.remote.duration')}}</span> : {{ minutes }} Min</p>

      <p class="card-text fw-bold"> <span class="fw-normal"> <i class=" bi bi-rulers  mr-2"></i> {{$t('landing.booking.remote.distance')}}</span> : {{ distance }} Km</p>

      <p class="card-text fw-bold" v-if="res.promo"> <span class="fw-normal" > <i class=" bi bi-cash-stack  mr-2"></i> {{$t('booking.personalD.promo')}}</span> &ldquo;{{res.promo.code}}&rdquo; : {{ res.promo.price }} XAF</p>

      <p class="card-text fw-bold"> <span class="fw-normal"> <i class=" bi bi-cash-coin  mr-2"></i> {{$t('landing.booking.remote.cost')}}</span> : {{ amount }} XAF</p>

			<p class="card-text"><small class="" style="color:#2020d0">{{$t('ariaLabel.daero.desc7')}}</small></p>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {

	  name: 'ResumeDetails',

	  data() {
	    return {

	    };
	  },

	  computed: {
        ...mapGetters({
          res: "reserv",
          ajustTime : 'ajustTime',
        }),

        distance(){
          return this.$filters.roundToTwo(this.$Place_kits.convertToKilometer(this.ajustTime.totalDistance,0))
        },
        minutes(){
          // 0.65 km/h is the vitesse fixed
          return Math.round(this.$filters.roundToTwo(this.$Place_kits.getEstimationTime(0.65,this.distance)))
        },
        amount(){
          return this.$Place_kits.computedTravelCost(this.res.departure_point)
        },
      }
	};
</script>

<style lang="scss" scoped>

p {
    margin: 0 0 0.1rem 0;
    line-height: 1;
}
</style>
