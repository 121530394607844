<template>
	<div :class="[
		  'p-button-text p-button-rounded border-none font-light line-height-2 text-dark-900 dropdown ', 
		  deviceVisible
		]"
		>
    <a class="me-2" href="#" id="Dropdown" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
      <i class="bi bi-grid-3x3-gap-fill m-0 fs-4"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-end dropdown-menu-lg-center mt-3 container text-center " aria-labelledby="Dropdown">
			<!-- <serviceGroupB 
				:AllServices="services"
			/> -->
			<span class="text-danger">{{$t("nextFeature")}}</span>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {

  	name: 'bongoServices',

	props:{
		classes: {
			type:String,
			default: ""
		}
	},

	data() {
		return {
				services : [[],[]],
		};
	},

	methods:{
		...mapActions(["update_display"])
	},
	async created(){
		for (let i = 0; i < 2; i++){
			// console.log(i)
			for (let j = 0; j < 5; j++){
				let ser = new this.$services_kits.serv()
				this.services[i].push(ser)
				// console.log(this.services)
			}
			
		}
	},

	computed: {

		deviceVisible(){
			return this.classes
		},
	}
};
</script>

<style lang="scss" scoped>

	.serv{
		width: 20rem; 
		height: 20rem;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.show {
		display: block;
    visibility: visible;
}
</style>
