<template>
	<AutoComplete
    :class="custom"
    :id="idk"
    v-model="recipiant"
    :required="required"
    :suggestions="suggestions"
    @complete="completeFn($event)"
    :placeholder="placeholder"
    :optionLabel="field"
    :aria-label="alab"
    :inputStyle="sty"
	:pt="{
        panel: { style: 'z-index: 2080;' },
    }"
  	>
	    <template #option="slotProps">
	      <div :class="Icustom" v-html="itemFn(slotProps.option)">
	      </div>
	    </template>
        <template #empty>
          <div class="d-flex flex-row">
            <span role="status" aria-live="polite" class="h-100 text-center fw-bold text-wrap m-auto p-auto"> <i class="pi pi-search fw-bold text-primary"></i> {{$t('empty')}}</span>
          </div>
        </template>
  	</AutoComplete>
</template>

<script>

	import { ref } from 'vue'

	export default {
		name: 'AutoCompleteB',
		props:{
			classes: {
				type: [Object,String],
				default: ""
			},
			appendToP: {
				type: String,
				default: "body"
			},
			Iclasses: {
				type: [Object,String],
				default: ""
			},
			identifier: {
				type: String,
				default: "arrival_point"
			},
			modelValue: {
				type: [Object,String]
			},
			requiredVar:{
				type: Boolean,
				default: true
			},
			suggestionsVar: {
				type: Array,
				default(){return [];}
			},
			completeFn: {
				type: Function,
				default(){return null;}
			},
			itemFn: {
				type: Function,
				default(){return null;}
			},
			placeholder: {
				type: String,
				default: ""
			},
			fieldName: {
				type: String,
				default: ""
			},
			AriaLabel: {
				type: String,
				default: ""
			},
			inputStyle: [Object, String],
		},
		data(){
			return {
			}
		},
		computed:{
			custom(){return this.classes},
			Icustom(){return this.Iclasses},
			idk(){return this.identifier},
			required(){ return this.requiredVar},
			field(){ return this.fieldName},
			sty(){ return this.inputStyle},
			alab(){ return this.AriaLabel},
			suggestions(){ return this.suggestionsVar},
			recipiant:{
				get(){
					return this.modelValue?
								typeof this.modelValue === 'object'? 
									Object.keys(this.modelValue).length != 0?
										this.modelValue
										:
										null
									:
									typeof this.modelValue === 'string'?
										this.modelValue.length != 0?
											this.modelValue
											:
											null
										:
										null
								:null
				},
				set(value){
					this.$emit('update:modelValue', value)

				}
			}
		},
		created(){
		},

	}
</script>

<style lang="scss" scoped>

</style>