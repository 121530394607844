/* eslint-disable */

/**
 * SERVICES IMPORTATION
 * 
 */
import { reactive } from "vue";

import "@/registerServiceWorker";
import "@/sw-update-architecture";
import router from "@/router/router";
import store from "@/store";
import PrimeVue from 'primevue/config';
import Vue3ReactiveTelInput from 'vue3-reactive-tel-input'
import { Vue3Mq } from "vue3-mq";
import { createI18n } from 'vue-i18n';
import us from '@/locales/us';
import fr from '@/locales/fr';
import register from '@/service-worker/register-service-worker'
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import Place_kits from "@/v2/services/place";
import Booking_kits from "@/v2/services/booking";
import services_kits from "@/v2/services/cardBongoService";


router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});

// export function define app used 
 function CustomServices(app) {
    /**
     * Define each component as a global app component
     * 
     */

      const i18n = createI18n({
          legacy: false,
          locale: 'us',
          fallbackLocale: 'fr',
          globalInjection: true,
          messages: {
              us,
              fr,
          },
      });

      // responsive
      app.use(Vue3Mq
      // ,{
      //     breakpoints: {
      //         pt: 0,
      //         xs: 200,
      //         sm: 450,
      //         md: 1250,
      //         lg: Infinity,
      //     }
      // }
      );

      app.config.globalProperties.$appState = reactive({ theme: 'lara-light-blue', darkTheme: false });
      const roundToTwo = (num) => {
              return +(Math.round(num + "e+2") + "e-2")
          },
          CFAtoEUR = (price) => {
              return roundToTwo(price * 0.0015244902);
          },
          CFAtoUSD =  (price) => {
              return roundToTwo(price * 0.0015914331);
          },
          CFAtoCAD = (price) => {
              return roundToTwo(price * 0.0020474673);
          };
      // filter for financial thread
      app.config.globalProperties.$filters = {
          roundToTwo,
          CFAtoEUR,
          CFAtoUSD,
          CFAtoCAD,
      };
      app.config.globalProperties.$mobileDevice = function(a){
            if(
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)
                ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))
            ) 
            {
                return true;
            }
            return false;
        }
      app.config.globalProperties.$Place_kits = new Place_kits()
      app.config.globalProperties.$Booking_kits = new Booking_kits()
      app.config.globalProperties.$services_kits = services_kits





      app.use(Vue3ReactiveTelInput);
      app.use(store);
      app.use(router);
      app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
      app.use(ConfirmationService);
      app.use(ToastService);
      app.use(i18n);
      app.use(DialogService);
      //pwas reinforcements
      register()
};

export default CustomServices;