/* eslint-disable */
const en = {
    nextFeature: "Coming Soon ...",
    empty: "No results",
    LoginBooking: "Login to complete",
    BW: "BONGO Space",
    BUO: "BONGO User Option",
    translate:"Switch Language",
    userspace:{
        title: 'User Space',
        profil:{
            title:"Profile",
            name: "Name",
            surname:"Surname"
        },
        history:{
            title:"Booking  History"
        },
        wallet:{
            title:"BONGO Wallet"
        },
        notification:{
            title: "Notifications"
        },
        settings:{
            title:"User Settings"
        }
    },



    position: "Here",
    ariaLabel: {
        calendar: {
            now: "Now",
            clear: "Clear",
            Valid: "Ok",
            later: "For Later"
        },
        switch: {
            0: "Valide",
            1: "Precise",
        },
        daero: {
            label: "airport choice fields",
            placeholder: "Choose your airport",
            desc1: "Boarding will take place at",
            desc2: "The drop-off will take place at",
            desc3: "Your trip will be made in",
            desc4: "for a distance of",
            desc5: "The cost of the trip is",
            desc6: "is the person responsible for the trip",
            desc7: "Correct? Then please choose a payment method",
        },
        comp:{
            placeholder:"Choose your flight company",
            label:"Company choice fields",
            via:"Through"
        },
        cdate: {
            label: "choose date of arrival in Cameroon",
            placeholder: "choose the date of your arrival",
        },
        cdate: {
            label: "choose the date of your arrival in Cameroon",
            placeholder: "choose the date of your arrival in Cameroon"
        },
        chour: {
            label: "fields to choose the time of arrival in Cameroon",
            placeholder: "choose the time of your arrival"
        }
    },
    lang: {
        ca: "English",
        fr: "French",
    },
    landing: {
        nav: {
            brand: {
                title: "BongoRide",
                "full-name": "BongoRide",
            },
            contextualMenu: {
                client: {
                    disc: 'Logout',
                    info: 'Account Details'
                }
            },
            links: {
                1: "Home",
                2: "Booking",
                3: "Prices",
                4: "Services",
                5: "Highlight",
                6: "Booking history",
            },
            "login-btn": "Login",
            "register-btn": "Register",
            connect: "Connection",
        },
        caroussel: {
            slogan: {
                light: "Your",
                main: "Safety",
                main1: "Comfort",
                and: "and",
                main2: "Punctuality",
                prepend: "in the palm of your hand.",
            },
            "attach-word": "For all your airports' rides trust the leading and the most reliable cameroonian transfer service.",
            "btn": "Book now",
        },
        services: {
            title: "OUR SERVICES",
            "sub-title": "ours offer is really nice",
            side1: {
                title: "Remote booking",
                "sub-title": "of an airport cab in Cameroon",
                content: "This first aspect of our solution allows the user of our system to be able to book a cab from any country that he will be able to board once he lands in any airport in the country.",
            },
            side2: {
                title: "Instant bookingm",
                "sub-title": "of an airport cab in Cameroon",
                content: "This first aspect of our solution allows the user of our system to book a cab for an instant departure in any airport in the country.",
            },
        },
        powerful: {
            title: "Powerful Everywhere",
            "sub-title": "Why must you trust ours services",
            side1: {
                title: "Trip Forecasting",
                content: "In our country, having exact information about where you're going is a chance. This may help you to skip bottling, prevent road with pothole and which point is the best for onboarding. All these, we give it to you freely.",
            },
            side2: {
                title: "Cameroon mobility visualisation",
                content: "Most of Cameroon project failed cause of none study of urban mobility. It's the base of each country evolution, master this factor give the power because the knowledge is power and 21 air is cover by technologies which help us to visualise the knowledge and forecast events, state, so on. We offer it to you for nothing big. ",
            },
        },
        prices: {
            title: "Matchless Pricing",
            "sub-title": "for best security and confort.",
            "action-btn": "Book Now",
            estimation: "per trip",
            1: {
                title: "Douala Confort",
            },
            2: {
                title: "Yaoundé Confort",
            }
        },
        highlight: {
            "attach-sent": "We are large enough to address your transportation needs. You can contact us for a service and be assured you will receive a response. We guarantee best prices and excellent service. Booking a Transfer has never been so easy.",
        },
        booking: {
            remote: {
                title: "I travel later",
                departI: "Departure",
                arrivalI: "Destination",
                flightI: "Flight number",
                dFI: "Flight arrival date",
                aFI: "Flight arrival time",
                searchB: "Login to complete",
                bookB: "Order",
                fL: "Flight",
                duration: 'Estimate duration',
                distance: 'Estimate distance',
                cost: 'Amount'

            },
            instant: {
                title: "I travel now",
                departI: "Departure point"
            }
        }
    },
    booking: {
        steps: {
            1: "Pathway",
            2: "Passenger's data",
            3: "Confirmation",
            4: "Payment"
        },
        TripDetais:{
            progressBar:{
                val1: "out of",
                val2: "fields have been filled in",
                val3: "We are checking if there are cabs available for this reservation",
                val4: "Sorry, we did not find an available cab that matches your criteria. Please probably adjust the details!"
            }
        },
        bB: "Back",
        nB: "Validate",
        eb: "End",
        pb: "Print",
        travelD: {
            autoLabel: "Search a place",
            precise: "Specify the place",
            checkbox: "I confirm that the destination entered is indeed correct"
        },
        personalD: {
            name: "Alias Name",
            surname: "Alias Surname",
            email: "Email",
            span: "For sending your reservation confirmation",
            comp:"Company",
            secret1: "Alias ?",
            secret2: "Type in your alias.",
            promo: "Enter your code",
        },
        confirmD: {
            title: "Summary",
            nameL: "Name and surname",
            surnameL: "Surname",
            typeL: "Reservation Type",
            departL: "Start",
            dropOffL: "Destination",
            dateL: "Departure date",
            hourL: "at",
            coutL: "Price",
            bill: "Amount to pay",
            Cp1: "Value of the promo code:",
            Cp3: "Invalid code",
        },
        bookingHistory: {
            title: "Booking History",
            code: "Code",
            driverN: "Driver name",
            driverS: "Driver surname",
            PhoneNumber: "Phone number",
            hourL: "PickUp time",
            hourA: "dropOff time",
            flightN: "Flight number",
            cost: "price",
            moyenP: "Payment Method",
            Tcode: "Travel code",
            status: "Status",
            reviews: "Client Reviews",
            loading1: "Search...",
            loading2: "Loading data, please wait...",
            emP: "No records found.",
            action: {
                download: "Download receipt ",
                rating: "Rating",
                confirmD: "Confirm dropOff",
                comment: "Comment",
                signal: "Signal",
                title: "Options link to reservation"
            },
            ratingM: {
                header: "Rating : Trip N°",
                qst: "How was your Trip ?",
                msg: "Your comment ..."
            }

        },
        AccountDetails: {
            title: "Personal Information",
            img: "Upload new image",
            imgS: "JPG or PNG no larger than 5 MB",
            btn: "Edit",
            country: "Country",
            pH: "Select your country",
            upbtn:"Upload a new picture",
            imgSize: "Image maximal size : 2Mbytes"
        },
        msg: {
            1: "Your booking request has been sent, please check your email address",
            2: "your reservation request failed"
        },
        paymentMethod: {
            1: "Enter your promotion code",
            2: "Choose your mode of payment",
            3: "Promo code",
            4: "Check",
            5: "Use",
            6: "Ignore",
            7: "Book now",
            secret1: "Discount code?",
            secret2: "Use it!",

        },
    },
    driver: {
        title: {
            1: "Check Reservation",
            2: "Onboard history",
            3: "Checkout history",
        },
        "sub-title": "CHECK RESERVATION",
        bookingHistory: {
            title0: "Booking History",
            title1: "Onboard History",
            title2: "Checkout History",
        },
        driverD: {
            smallText: "to check a reservation please enter the reservation code",
            title0: "Create new driver",
            title1: "User informations",
            title2: "Taxi Driver Informations",
            createORD: "Update reservation ",
            updateDD: "Update driver informations",
            Dd0: "Profil",
            Dd1: "Name is required.",
            Dd2: "Phone number is required.",
            Dd3: "The email address is required.",
            Dd4: "Vehicle registration number",
            Dd5: "Vehicle registration required.",
            Dd6: "Driver's license number",
            Dd7: "Driver's license number required.",
            Dd8: "Cab number in the system",
            Dd9: "national ID card",
            Dd10: "national ID card required.",
            Dd11: "Airport name",
            Dd12: "Airport name required.",
            Dd13: "national ID card",
            Dd14: "Surname is required.",
            Dd15: "Upload a file/document.",
            Dd16: "Download the file",
            Dd17: "File expiration date",
            Dd18: "Driver's document",
            mgvd: "This reservation is valid",
            errmgvd: "This reservation does not exist",
            btn1: "Return",
            btn2: "Confirm",

            dataT: {
                title: "Taxi Driver management",
                code: "Code",
                pH: "Search...",
            },
            manyA: {
                1: "Are you sure you want to activate these cab Drivers?",
                2: "Select drivers",
                3: "Are you sure you want to desactivate these cab Drivers?"
            }

        }
    },
    admin: {
        headMsg: "ADD A NEW LOCATION",
        hd1: "Create Driver account",
        loc: "Location",
        btn: "Add",
        btn1: "Cancel",
        btn2: "Save",
        btn3: "Add new driver",
        btn4: "disable",
        btn5: "enable",
        btn6: "Import",
        btn7: "Export",
        btn8: "Places",
        btn9: "Download",
        st1: "activated",
        st2: "desactivated",
        ch0: "checkIn",
        Ures: "Booking Informations",
        codePromo: {
            title1: "promoCode creation",
            title2: "promoCode manager",
            prix: "price",
            code: "code",
            expDate: "expiration date",
            fileExpDate: "file's expiration date",
            txt1: "The amount is mandatory",
            txt2: "Code",
            cP: "counter",
            dt1: "Date of creation",
            dt2: "Expiration Date",
            dt3: "modification date",
            btn1: "Add",
            updateC: "Update code",
            smT1: "The amount is mandatory",
            smT2: "only alphanumeric characters",
            smT3: "The date is mandatory",
        },
        manyA: {
            1: "Are you sure you want to activate these promocode?",
            2: "Select drivers",
            3: "Are you sure you want to desactivate these promocodes?"
        }

    },
    appfooter: {
        column1: {
            title: "Entreprise",
            row1: "About Us",
            row2: "News",
            row3: "Investor Relations",
            row4: "Careers",
            row5: "Media Kit",
        },
        column2: {
            title: "Resources",
            row1: "Get Started",
            row2: "Learn",
            row3: "Case Studies",
        },
        column3: {
            title: "Community",
            row1: "Discord",
            row2: "Events",
            row3: "FAQ",
            row4: "Blog",
        },
        column4: {
            title: "Legal",
            row1: "Brand Policy",
            row2: "Privacy Policy",
            row3: "Terms of Service",
        }
    },
    auth: {
        forgotC: {
            head1: "Password recovery",
            title1: "Already have an account? Sign in now",
            title2: "Create your account now",
            summary: "Password recovery",
            detail: "Please check your mailbox.",
            errorM: "A problem has occurred, please try again",
            btn: "Start the recovery process"
        },
        loginC: {
            title1: "Forgot your password?",
            title2: "Create your account now!"
        },
        registerC: {
            nam: "Name",
            surn: "Surname",
            phone: "Phone number",
            pass: "Password",
            summary: "Create an account",
            btn: "create account",
            em: "Email"
        },
        profileC: {
            Hd: "Personal Informations",
            country: "country",
            pH: "Select your country"
        }
    },
    layout: {
        homeC: "Se déconnecter",
        checkDriverA: "No Driver is available for the time requested ",
        checkDriverD: "One Drivers is available for the time requested "
    }

};
export default en;