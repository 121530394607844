import store from "./store";

// notify user that a new version is ready to be install
function invokeServiceWorkerUpdateFlow(registration) {
    // console.log("tttetsttteyttdd")
    // TODO implement your own UI Notification element
    const options = {
        body: store.getters.lang == 'US'?'New version of the app is available!':"Nouvelle version de l'application disponible!",
        // image: "./img/icons/favicon.ico",
        icon: "./img/icons/icon.png",
        tag: store.getters.lang == 'US'?"new version":"nouvelle version",
        renotify: true,
        vibrate: [200, 100, 200],
        actions: [
            { 
                action: "Update", 
                title: store.getters.lang == 'US'?"Update Now":"Mettre à jour maintenant", 
                // icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZnQlXakNfuhQ--7yY0SxGFoLm7pSJ4oc8aA&usqp=CAU"
            },
            { 
                action: "Postpone", 
                title: store.getters.lang == 'US'?"Update Later":"Mettre à jour plutard", 
                // icon: "https://www.clipartmax.com/png/middle/118-1186868_watch-clipart-office-hour-date-time-icon.png"
            }
        ]
      };
    registration.showNotification(store.getters.lang == 'US'?"BONGO Update":"Mise à jour BONGO",options);
    
    
}


// check if the browser supports serviceWorker at all
if ('serviceWorker' in navigator) {
    // wait for the page to load
    window.addEventListener('load', async (event) => {
        // register the service worker from the file specified
        const registration = await navigator.serviceWorker.register('/service-worker.js')

        // ensure the case when the updatefound event was missed is also handled
        // by re-invoking the prompt when there's a waiting Service Worker
        if (registration.waiting) {
            invokeServiceWorkerUpdateFlow(registration)
        }

        // detect Service Worker update available and wait for it to become installed
        registration.addEventListener('updatefound', () => {
            if (registration.installing) {
                // wait until the new Service worker is actually installed (ready to take over)
                registration.installing.addEventListener('statechange', () => {
                    if (registration.waiting) {
                        // if there's an existing controller (previous Service Worker), show the prompt
                        if (navigator.serviceWorker.controller) {
                            invokeServiceWorkerUpdateFlow(registration)
                        } else {
                            // otherwise it's the first install, nothing to do
                            console.log('Service Worker initialized for the first time!!')
                        }
                    }
                })
            }
        })

        // detect controller change and refresh the page
        navigator.serviceWorker.addEventListener('controllerchange', (event) => {
           window.location.reload()
        })
    })
}