<template>
	<div>
		<nav class="navbar navbar-transparent bg-transparent fixed-top w-full">
		  <div class="container-fluid md:row justify-content-between w-full">
		    <div :class="{
		    	'd-flex align-items-center bg-white rounded-start rounded-end': true,
		    	'w-100': small
		    }">
		    	<button 
		    		class="navbar-toggler mx-1" 
		    		style="border:none;outline: none;" 
		    		type="button" 
		    		data-bs-toggle="offcanvas" 
		    		data-bs-target="#MenuOffCanvas" 
		    		aria-controls="MenuOffCanvas" 
		    		v-tooltip.bottom="{
				        value: 'Menu',
				        class: 'custom-error',
				      }"
				      @click.prevent="show('#MenuOffCanvas')"
		      	>
			      <span class="navbar-toggler-icon" style="border:none;outline: none;"></span>
			    </button>
			    <AutoCompleteB
            :classes="'m-0 p-0  text-truncate flex-grow-1'"
            :Iclasses="'flex align-options-center text-wrap'"
            :identifier="'arrival_point'"
            v-model="currentPlace"
            :requiredVar="true"
            :suggestionsVar="filteredPlaces"
            :completeFn="searchPlace"
            :placeholder="$t('booking.travelD.autoLabel')"
            :fieldName="'display_name'"
            :AriaLabel="'destination'"
            :inputStyle="{
            	backgroundColor: 'var(--surface-50)', 
            	width:'100% !important',
            	border:'none',
            	outline: 'none',
            	overflow: 'hidden',
	    				textOverflow: 'ellipsis',
							whiteSpace: 'nowrap'
						}"
            :itemFn="(data)=>{
              return `<span class='d-flex align-items-center text-truncate text-wrap'> <i class='pi pi-map-marker'  aria-hidden='true' style='color: #0000FF; font-size: 1.2rem'></i>   ${data.display_name}</span>`
            }"
          	/>
	      	<Button 
            v-if="$Place_kits.IsGeoHSON(currentPlace)"
            :icon="!precision?'bi bi-pin-map-fill':'pi pi-spin pi-map'" 
            aria-label="precisionToggleButton" 
            class="p-button-text p-button-rounded border-none font-light line-height-2 text-dark-900 lex-shrink-1" 
            style="font-size: 1.4rem;color: #2020d0;border:none;outline: none;" 
            @click="set_precision(!precision);"
            v-tooltip.bottom="{
			        value: $t('booking.travelD.precise'),
			        class: 'custom-error',
			      }"
			      :disabled="checked"
          	/>
	      	<button 
	      		v-else
	      		class="navbar-toggler me-0 pe-0 flex-shrink-1" 
	      		style="border:none;outline: none;" 
	      		type="button"
	      		v-tooltip.bottom="{
			        value: $t('booking.travelD.autoLabel'),
			        class: 'custom-error',
			      }"
		      	>
		      		<i class="pi pi-search me-0" style="color: #2020d0"></i>
	      	</button>
	      	<Divider layout="vertical" class="" />
      		<button 
	      		:class="{
	      			'navbar-toggler flex-shrink-1':true,
	      		}" 
	      		style="border:none;outline: none;" 
	      		type="button" 
		    		data-bs-toggle="offcanvas" 
		    		@click="$router.push(
		    		{
		    			path:  $route.path == '/' ? '/direction' : $route.path
		    		})"
		    		data-bs-target="#BookingOffCanvas" 
		    		aria-controls="BookingOffCanvas"
		      		v-tooltip.right="{
			        value: 'Make your booking now',
			        class: 'custom-error',
			      }"
		      	>
	      		<i class="bi bi-sign-turn-right-fill" style="color: #2020d0"></i>
	      	</button>
	    		<div class="d-flex flex-row mr-0 justify-content-center align-items-center" v-if="small">
	    			<serviceB 
	    			classes="rounded-circle flex-shrink-1" 
	    			v-tooltip="{
			        value: $t('BW'),
			        class: 'custom-error',
			      }" />
			    	<userAvatarB classes="rounded-circle shadow-4 mr-1 flex-shrink-1" 
			    	v-tooltip="{
			        value: $t('userspace.title'),
			        class: 'custom-error',
			      }" />
	    		</div>
   	 		</div>
   	 		<div class="d-flex flex-row mr-0 justify-content-center align-items-center w-auto" v-if="!small">
   	 			<languageB v-tooltip="{
			        value: $t('translate'),
			        class: 'custom-error',
			      }" />
	    		<serviceB 
	    			classes="rounded-circle flex-shrink-1" 
	    			v-tooltip="{
			        value: $t('BW'),
			        class: 'custom-error',
			      }" />
		    	<userAvatarB 
		    	classes="mr-7 rounded-circle shadow-4 flex-shrink-1" 
		    	v-tooltip="{
			        value: $t('userspace.title'),
			        class: 'custom-error',
			      }" />
			      
		    	<Button
            :label="$t('landing.nav.login-btn')"
            @click="
              update_display({ signin: true})
            "
            class="p-button-text border-none font-light line-height-2"
            :style="{
                'min-width': '45%',
                backgroundColor: '#2020d0',
                color: '#ffffff',
              }"
              v-if="!islogin"
            ></Button>
		    </div>
		  </div>
		</nav>
	    <div>
		    <MenuOffCanvas />
		    <AMenuOffCanvas />
		    <BookingOffCanvas />
	    </div>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex";
	import _ from "lodash";

	export default{
		name:"NavBarB",
		inject: ["mq"],
		props:{

		},
		computed: {
		    ...mapGetters({
	      	res: "reserv",
	      	lang: "lang",
        	checked: "checked",
        	rideOrientation: "rideOrientation",
	      	currentUPlace:"currentUPlace",
      		islogin: "islogin",
      		dropoff: "guessReserv",
	    }),
	    small(){
	    	let flag;
    	 	if(this.media.orientation === 'landscape')
				{
					flag = !this.media.smPlus
				}
				else{
					flag = !this.media.smPlus
				}
				// console.log(this.media)

				return flag;
	    },
	    currentPlace:{
        get(){
          return this.currentUPlace;
        },
        set(value){
          this.updateCurrentPlace(value)
        }
      },

      precision(){
          return this.$store.getters.precision;
      },
		},
		watch: {
			/* 
      Observe change on guessReserv store variable
      if a change appear then check type of change by lookingup object variable values
      in case of dropoff guess launch custom method.
       */
      dropoff(){
          if(this.dropoff){
            
              if(this.dropoff.guess_dropoff && !this.dropoff.checkOut_rejected){
                    console.log(this.dropoff.trajet_code )
                    this.ConfirmDropOff({ trajet_code: this.dropoff.trajet_code });
              }
              if(this.dropoff.checkOut){
                    this.$toast.add({severity:'success', summary:'Confirmed', detail:'BongoRide Trip completed', life: 3000});
                }
          }
      },
			'mq.current'(){
				this.media = this.mq
			},
			'mq.orientation'(){
				this.media = this.mq
			},
			currentPlace() {
				if (this.$Place_kits.IsGeoHSON(this.currentPlace)) {
            if(( !_.isEqual(this.currentPlace, this.res.departure_point) && this.rideOrientation) || (!_.isEqual(this.currentPlace, this.res.arrival_point) && !this.rideOrientation))
            {
              // this.center = [this.currentPlace.lat, this.currentPlace.lon];
              // console.log("---- I ----")
              // this.res.arrival_point = this.currentPlace;
			  console.log("------<H2O>-----")
              this.rideOrientation?this.set_arg_reserv({...this.res, arrival_point:this.currentPlace}):this.set_arg_reserv({...this.res, departure_point:this.currentPlace})
              // localStorage.setItem('arrivalP', JSON.stringify(this.res.arrival_point))
              // if (this.$Place_kits.IsGeoHSON(this.currentPlace)) {
              this.currentPlaceP = JSON.parse(JSON.stringify(this.currentPlace));
                // this.engine.setWaypoints(this.waypoints);
              // }
              // si tous les champs sont rrempli, cherche la disponibilité d'un taxi d'aéroport
            }
        }
        else
        {
          this.resetFlag(false);
        }
			},
			'res.arrival_point'() {

				this.$Place_kits.IsGeoHSON(this.res.arrival_point) && this.rideOrientation?this.updateCurrentPlace(this.res.arrival_point):null;
        
        if(this.res.arrival_point === null){
          this.updateCurrentPlace(null)
          this.currentPlaceP = null
        }
			},

		},
		methods: {
		    // calling the functions from store
		    ...mapActions([
						"resetFlag",
      			"set_arg_reserv",
      			"updateCurrentPlace",
      			"set_precision",
      			"update_display",
      			"ConfirmDropOffReservation",
      			"DeniedDropOffReservation"
		    ]),
		    ConfirmDropOff(data) {
		      this.lang == "US"
		        ? this.$confirm.require({
		            message: "Would you like to confirm your arrival?",
		            header: "confirm dropoffed from Bongo Ride cab",
		            icon: "pi pi-exclamation-triangle",
		            acceptClass: "p-button-danger",
		            accept: () => {
		              this.ConfirmDropOffReservation(data).then(() => {
		                this.$toast.add({
		                  severity: "info",
		                  summary: "Confirmed",
		                  detail: "Confirmation sended",
		                  life: 3000,
		                });
		              });
		            },
		            reject: () => {
		            	this.DeniedDropOffReservation(data).then(() => {
			              this.$toast.add({
			                severity: "error",
			                summary: "Rejected",
			                detail:
			                  "You will be able to trust it at each time routing to your onboard reservation view.",
			                life: 3000,
			              });
		              });
		            },
		          })
		        : this.$confirm.require({
		            message: "Vous souhaitez confirmer votre arrivée?",
		            header: "confirmer le dépôt du taxi Bongo Ride",
		            icon: "pi pi-exclamation-triangle",
		            acceptClass: "p-button-danger",
		            accept: () => {
		              this.ConfirmDropOffReservation(data).then(() => {
		                this.$toast.add({
		                  severity: "info",
		                  summary: "Confirmed",
		                  detail: "Confirmation sended",
		                  life: 3000,
		                });
		              });
		            },
		            reject: () => {
		            	this.DeniedDropOffReservation(data).then(() => {
			              this.$toast.add({
			                severity: "error",
			                summary: "Rejected",
			                detail:
			                  "You will be able to trust it at each time routing to your onboard reservation view.",
			                life: 3000,
			              });
		              });
		            },
		          });
		    },
		    show(id){
		    	// let node = document.querySelector(id);
          		// node.classList.toggle('show');
		    },
		    async searchPlace(event) {
	          this.filteredPlaces = await this.$Place_kits.searchPlace(
	                                            event,this.checked, 
	                                            this.res.departure_point, 
	                                            (typeof this.res.departure_point === "object" && this.res.departure_point !== null ? Object.keys(this.res.departure_point).length != 0 : false)?this.res.departure_point.lat:false, 
	                                            this.currentPlace)
	        },
		},
		data() {
			return {
				filteredPlaces: [],
				currentPlaceP: null,
				media: null
			};
		},

		mounted(){
			this.media = this.mq
		},

		created(){
			this.media = this.mq
		}
	}
</script>

<style lamg="scss" scoped>
button:focus,input:focus, .navbar-toggler:hover, .navbar-toggler:focus, button:active, input:active {
	border: none;
	outline: none;
	box-shadow:none;
/*	background-color: red;*/
}



button,
input[type=button] {
  outline: none;
}
button::-moz-focus-inner,
input[type=button]::-moz-focus-inner {
  border: 0;
}

.p-divider.p-divider-vertical {
    margin: 0 0.5rem;
    padding: 1rem 0;
}
</style>