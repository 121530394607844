const state = {
    lang: ""
}

const getters = {
    lang: state => state.lang
}

const mutations = {
    UPDATE_LANG(state, payload) {
        state.lang = payload
    }
}

const actions = {
    update_lang({ commit }, data) {
        commit("UPDATE_LANG", data)
    }
}

const userModule = {
    state,
    mutations,
    actions,
    getters
}

export default userModule;