function randomImgservice(type) {
    return require('@/assets/files/services/' + type + '.webp');
}
function randomName(){
    var names=['mobility','eats','food','delivery','drive']
    var i = Math.round((names.length-1) * Math.random());
    return names[i];
}
const serv = class service {
    constructor() {
        let type = randomName()
        return {
            url: 'https://prerun-transfer-ride.bongo.cm', // 
            shortName: type, // service name
            image:randomImgservice(type), // service brand image
            imageSize:80, // img size
            serviceSize:100, // service size
            radius: 31, // radius brand
            Servclass:'' // custom class
        }
    }
}
export default {
    randomName, 
    serv
};