<template>
	<div class="flex flex-column justify-content-center">
		<span class="self-align-center fw-bold fs-5">{{ $t('booking.bookingHistory.ratingM.qst') }}</span>
		<Rating
	        v-model="res.rating"
	        :stars="5"
	        :cancel="false"
	        class="my-2"
	      />
	      <Textarea v-model="res.comment" :arial-label="$t('booking.bookingHistory.ratingM.msg')" :placeholder="$t('booking.bookingHistory.ratingM.msg')" :autoResize="true" rows="5" cols="30" />
	</div>
</template>
<script>
	import { inject } from "vue";
	export default {
	  name: "RatingComp",
	  inject: ['dialogRef'],
	  data(){
	  	return {
	  		res: {},
	  	}
	  },
	  mounted(){
	  	console.log(this.dialogRef)
	  	if(this.dialogRef)
	  		this.res = this.dialogRef.data.res
	  }
	}
</script>