/* eslint-disable */

/**
 * DIRECTIVE IMPORTATION
 * 
 */

import CodeHighlight from '@/mixins/AppCodeHighlight';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';


// export function define app used 
 function CustomDirective(app) {
    /**
     * Define each directive as a global app directive
     * 
     */

      app.directive('tooltip', Tooltip);
      app.directive('ripple', Ripple);
      app.directive('code', CodeHighlight);
      app.directive('badge', BadgeDirective);
      app.directive('styleclass', StyleClass);
};

export default CustomDirective;