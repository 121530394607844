import axios from '@/services/axios-http-config';
// import jwtDecode from 'jwt-decode'

const state = {
    drivers: [],
    

}

const getters = {
    drivers: state => state.drivers,
    OnlineDrivers: state => state.drivers.filter((el)=> el.isOnline === true),
   
}

const mutations = {
    CLEAR_DRIVERS(state) {
        state.drivers = []
    },
    SET_DRIVERS(state, payload) {
        state.drivers = Array.isArray(payload) ? payload : [payload]
    },
    ADD_DRIVER(state, payload) {
        state.drivers.find(obj => payload._id === obj._id) != null ? state.drivers = state.drivers.map(obj => payload._id === obj._id ? payload : obj) : state.drivers.push(payload);
    },
    UPDATE_DRIVERS(state, payload) {
        let temp = state.drivers
        state.drivers = state.drivers.map(obj => payload._id === obj._id ? payload : obj)
        JSON.stringify(temp) === JSON.stringify(state.drivers) ? state.drivers.push(payload):undefined

    },
}

const actions = {
    clear_drivers({ commit }) {
        commit('CLEAR_DRIVER')
    },
    add_driver({ commit }, data) {
        commit('ADD_DRIVER', data)
    },
    update_drivers({ commit }, data) {
        commit('UPDATE_DRIVERS', data)
    },
    getDrivers({ commit }) {
        return axios.get('v1/drivers/readAll').then((response) => {
            response.data != null ? commit('SET_DRIVERS', response.data) : null
        });
    },
}

const driverModule = {
    state,
    mutations,
    actions,
    getters
}

export default driverModule;