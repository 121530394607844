<template>
	<Calendar
		:id="idk"
		:required="required"
		v-model="recipiant"
    	:inputStyle="sty"
    	:aria-label="alab"
    	:placeholder="placeholder"
    	:class="custom"

		:dateFormat="Format"
		:showTime="showTimeF"
		:disabledDates="invalidDates"
		:showSeconds="showSecondsF"
		:showIcon="showIcon"
		:ref="refL"
		:minDate="minDateD"
		:manualInput="manualInputF"
		:appendTo="appendToP"
		:pt="{
			panel: { style: 'z-index: 2080;' },
			input: { style: 'margin-right: 0;' },
		}"
	>
		<template #footer>
			<div :class="BtnDivclasse">
			<Button
			:label="BtnAriaLabelL"
			@click="hideFn(this)"
			:class="Btnclasse"
			/>
			</div>
		</template>
	</Calendar>
</template>

<script>

	import { ref } from 'vue'

	export default {
		name: 'CalendarB',
		props:{
			classes: {
				type: [Object,String],
				default: "m-auto w-full ml-0"
			},
			appendToP: {
				type: String,
				default: "body"
			},
			identifier: {
				type: String,
				default: "arrival_point"
			},
			modelValue: {
				type: [Date,Object]
			},
			requiredVar:{
				type: Boolean,
				default: true
			},
			manualInput:{
				type: Boolean,
				default: false
			},
			showTime:{
				type: Boolean,
				default: true
			},
			showSeconds:{
				type: Boolean,
				default: false
			},
			showIcon:{
				type: Boolean,
				default: false
			},
			refI: {
				type: String,
				default: "ca"
			},
			BtnAriaLabel: {
				type: String,
				default: ""
			},
			Btnclasses: {
				type: [Object,String],
				default: "m-auto w-full ml-0"
			},
			BtnDivclasses: {
				type: [Object,String],
				default: "m-auto w-full ml-0"
			},
			dateFormat: {
				type: String,
				default: "yy-mm-dd"
			},
			minDate: {
				type: [Date],
				default(){return null}
			},
			hideFn: {
				type: Function,
				default(){return null;}
			},
			placeholder: {
				type: String,
				default: ""
			},
			AriaLabel: {
				type: String,
				default: ""
			},
			inputStyle: [Object, String],
			disabledDates: {
				type: Array,
				default(){return [];}
			},
		},
		data(){
			return {
			}
		},
		computed:{
			custom(){return this.classes},
			Icustom(){return this.Iclasses},
			idk(){return this.identifier},
			required(){ return this.requiredVar},
			field(){ return this.fieldName},
			sty(){ return this.inputStyle},
			alab(){ return this.AriaLabel},
			suggestions(){ return this.suggestionsVar},

			manualInputF(){ return this.manualInput},
			showTimeF(){ return this.showTime},
			showSecondsF(){ return this.showSeconds},
			showIconF(){ return this.showIcon},
			Format(){ return this.dateFormat},


			refL(){ return this.refI},
			minDateD(){ return this.minDate},
			invalidDates(){ return this.disabledDates},

			BtnDivclasse(){ return this.BtnDivclasses},
			BtnAriaLabelL(){ return this.BtnAriaLabel},
			Btnclasse(){ return this.Btnclasses},

			recipiant:{
				get(){
					return this.modelValue?this.modelValue instanceof Date?this.modelValue:null:null
				},
				set(value){
					this.$emit('update:modelValue', value)

				}
			}
		},
		created(){
			/**
			 * enamble reactivity on data
			 */
			// console.log(this)
			// this.recipiant1 = ref(this.reactiveVar)
			// console.log(this.recipiant1?true:false)
			// this.suggestions = ref(this.suggestionsVar)
		},

	}
</script>

<style lang="scss" scoped>
</style>