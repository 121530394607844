// import Vue from 'vue'
import { createStore } from 'vuex';
import createPersistedState from "vuex-plugin-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
// Vue.use(Vuex)
/* const app = createApp(App);
app.use(Vuex); */

import user from './modules/user';
import auth from './modules/auth';
// import userlogin from './modules/auth';
import common from './modules/common';
import reserv from './modules/reservation';
// import place from './modules/place';
import airport from './modules/airport';
import company from './modules/company';
// // import ratesVar from './modules/reservation';
// import driver from './modules/managment/driver';
import MMreservation from './modules/managment/reservation';
import driver from './modules/managment/driver';
import Lang from './modules/locales';
import pmCode from './modules/managment/promo';


export default createStore({
    modules: {
        auth,
        user,
        common,
        airport, 
        company,
        reserv,
        driver,
        MMreservation,
        Lang,
        pmCode,
    },
    plugins: [
        createPersistedState({
            key: `${process.env.VUE_APP_KEY_STORAGE}`,
            fetchBeforeUse:true,
            storage: {
                getItem: key =>{ let d = ls.get(key); /*console.log({d,key});*/ return d.length != 0?d:{};},
                setItem: (key, value) => {/*console.log({value});*/ ls.set(key, value);},
                removeItem: key => ls.remove(key)
            }
        })
    ],

})