import axios from 'axios'


// axios.defaults.baseURL = process.env.VUE_APP_API_URL || "http://localhost:5000/"
// axios.defaults.headers = {
//     "Content-type": "application/json",
// }
const http = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}api/` || "http://localhost:7000/",
    headers: {
        "Content-type": "application/json",
    }
});

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
http.interceptors.request.use(
    (config) => {
        let api_key = localStorage.getItem('userToken')
            // let isOnline = localStorage.isOnline;
        if (api_key) {
            // console.log('Authorization Token added !')
            config.headers['Authorization'] = `Bearer ${ api_key }`
        }

        // console.log('GRR', (isOnline))
        // if (localStorage.isOnline) {
        // console.log('IsOnline ! ' + localStorage.isOnline)
        // console.log(config.headers);
        return config;
        // } else {
        //     console.log('IsOffline ! ' + localStorage.isOnline)
        //         // UIkit.notification({
        //         //     message: < span uk - icon = 'icon:' > < /span> You are offline !,
        //         //     status: 'danger',
        //         //     pos: "top-right",
        //         //     timeout: 5000,
        //         // });
        //     console.log('Notified !')
        //     throw new axios.Cancel('Operation canceled because network !');
        // }
    },

    (error) => {
        return Promise.reject(error)
    }
)

export default http