<template>
     <div class="d-flex justify-content-center w-50">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
    animationDuration=".5s" aria-label="Custom ProgressSpinner" />
    <!-- <bounce loading="isLoading"></bounce> -->
    </div>
</template>
<script>
    export default{
        name:"PreLoad"
    }
</script>