import { createRouter } from 'vue-router';
import { createWebHistory } from 'vue-router';
// import Dashboard from '@/views/DashboardComponent.vue';
import auth from "./middleware/auth.js"
// import authClient from "./middleware/auth-client.js";
// import the store
import store from '@/store';

const routes = [
    //     {
    //     // this root path is a default path for all user
    //     path: '/',
    //     name: 'Home',
    //     component: () =>
    //         import ('@/layout/UserLayout.vue'),
    //     children: [{
    //             path: '/',
    //             component: () =>
    //                 import ('@/views/HomeView.vue'),
    //         },
    //         { // this path will only be display when a client or supervisor is logged in 
    //             path: 'account',
    //             beforeEnter: auth,
    //             component: () =>
    //                 import ('@/components/managments/account/IndexComponent.vue'),
    //             children: [{
    //                     path: 'profile',
    //                     component: () =>
    //                         import ('@/components/managments/account/ProfileComponent.vue'),
    //                 },
    //                 { // this path will only be display when a supervisor or a client is logged in 
    //                     path: 'my_reservations',
    //                     component: () =>
    //                         import ('@/components/reservation/history/ReservHistory.vue'),
    //                 },
    //             ],
    //         }, {
    //             path: 'reservation',
    //             name: 'Reservation',
    //             component: () =>
    //                 import ('@/components/reservation/IndexComponent.vue'),
    //             children: [{
    //                     path: 'travel',
    //                     component: () =>
    //                         import ('@/views/BookingView.vue'),
    //                 },
    //                 {

    //                     beforeEnter: (req, res, next) => {
    //                         if (store.getters.checked) {
    //                             next();
    //                         } else {
    //                             next('/');
    //                         }
    //                     },
    //                     path: 'personnal',
    //                     component: () =>
    //                         import ('@/components/reservation/steps/PersonalDetails.vue'),
    //                 },
    //                 {
    //                     path: 'confirmation',
    //                     component: () =>
    //                         import ('@/components/reservation/steps/ConfirmationDemo.vue'),
    //                 },
    //                 {
    //                     beforeEnter: (req, res, next) => {
    //                         if (store.getters.checked) {
    //                             next();
    //                         } else {
    //                             next('/');
    //                         }
    //                     },
    //                     path: 'payment',
    //                     component: () =>
    //                         import ('@/components/reservation/steps/PaymentDetails.vue'),
    //                 },

    //             ],
    //         },
    //         {
    //             // this path will only be display when a supervisor or a client is logged in 
    //             path: 'my_reservations',
    //             beforeEnter: auth,
    //             component: () =>
    //                 import ('@/components/reservation/history/ReservHistory.vue'),

    //         },


    //     ],

    // },

    { // this path will only be display when a client or supervisor is logged in 
        path: '/',
        // beforeEnter: authClient, authSup,
        component: () =>
            import ('@/v2/views/BookingV'),
        children: [
            {    
                path: 'profile',
                component: () =>
                    import ('@/components/managments/account/ProfileComponent'),
            },
            {

                beforeEnter: (req, res, next) => {
                    if (store.getters.checked) {
                        next();
                    } else {
                        next('/');
                    }
                },
                path: 'identity',
                component: () =>
                    import ('@/v2/layouts/IdentityDetails'),
            },
            {
                path: 'direction',
                component: () => import('@/v2/layouts/TripDetails')
            },
             {
                path: 'payment-choose',
                component: () => import('@/v2/layouts/ResumePaymentChoose'),
            },
             {
                path: 'payment',
                component: () => import('@/v2/layouts/PaymentMethod'),
                children: [
                    {
                        path: 'paypal',
                        name: 'paypal',
                        component: () => import ('@/v2/components/PayPal'),
                    },
                    {
                        path: 'cash',
                        name: 'cash',
                        component: () => import ('@/v2/components/cashPay'),
                    },

                ],
            },
             {
                path: 'print',
                component: () => import('@/v2/layouts/ReceiptPrinter'),
            },
            { // this path will only be display when a client or supervisor is logged in 
            path: 'account/profile',
            beforeEnter: auth,
            component: () =>
                import ('@/components/managments/account/ProfileComponent')
        },

        ],
    },
    
    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;