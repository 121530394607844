<template>
    <div class="offcanvas offcanvas-start offcanvas-size-sm text-bg-white bg-white" data-bs-scroll="true" tabindex="-1" id="MenuOffCanvas" aria-labelledby="MenuOffCanvasNavbarLabel">
      <div class="offcanvas-header d-flex align-items-center border-bottom  border-cyan p-2 pb-4 mb-2 border-opacity-25">
        <picture>
          <source srcset="@/assets/files/logo/logo_blue_1.webp" type="image/webp">
          <source srcset="@/assets/files/logo/logo_blue_1.avif" type="image/avif">
          <img src="@/assets/files/logo/logo_blue_1.png" alt="BongoRide Logo" height="30" width="130" class="mr-0 lg:mr-2" >
        </picture>
        <button type="button" class="btn-close btn-close-dark mr-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <!-- <h5 class="" id="offcanvasDarkNavbarLabel">Dark offcanvas</h5> -->
      </div>
      <div class="offcanvas-body text-2xl">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <a class="nav-link hover:text-primary" aria-current="page" href="#"><i class="bi bi-info-circle mr-2"  style="font-size: 1.3rem"></i> About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link hover:text-blue-600" aria-current="page" href="#"><i class="bi bi-mortarboard-fill mr-2" style="font-size: 1.3rem"></i> Tutorial</a>
          </li>
          <li class="nav-item">
            <a class="nav-link hover:text-cyan-600" aria-current="page" href="#"><i class="bi bi-question-circle-fill mr-2" style="font-size: 1.3rem"></i> FAQ</a>
          </li>
          <li class="nav-item">
            <a target="_blank" href="https://wa.me/237676220383" aria-current="page" class="nav-link hover:text-green-600">
              <i class="pi pi-whatsapp mr-2" style="font-size: 1.3rem"></i><span class="">Support</span>
            </a>
          </li>

          
        </ul>
      </div>
    </div>
</template>
<script>
	export default{
		name:"MenuOffCanvas",
		props:{

		}
	}
</script>

<style lamg="scss" scoped>
	@import './offcanvas-size.scss';
</style>