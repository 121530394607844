// import axios from '@/axios-http-config';

const state = {
    ondoing: false,
    error: "",
    success: "",
    showCreate: false,
    showUpdate: false,
    showManyActivation: false,
    showManyDeactivation: false,
    submitted: false,
    sidebar_driver: false,

}

const getters = {
    sidebar_driver: state => state.sidebar_driver,
    ondoing: state => state.ondoing,
    error: state => state.error,
    success: state => state.success,
    menu: state => state.menu,
    showCreate: state => state.showCreate,
    showUpdate: state => state.showUpdate,
    submitted: state => state.submitted,
    showManyActivation: state => state.showManyActivation,
    showManyDeactivation: state => state.showManyDeactivation,
}

const mutations = {
    UPDATE_ON_DOING(state) {
        state.ondoing = !state.ondoing;
        // state.on_doing = !state.on_doing;
    },
    UPDATE_SIDEBAR_DRIVER(state, payload) {
        state.sidebar_driver = payload;
    },
    UPDATE_OPEN_MENU(state) {
        state.openMenu = !state.openMenu
    },
    UPDATE_ERR_MSG(state, payload) {
        state.error = payload
    },
    UPDATE_SUCC_MSG(state, payload) {
        state.success = payload
    },
    UPDATE_SHOWCREATE(state, payload) {
        state.showCreate = payload
    },
    UPDATE_SHOWUPDATE(state, payload) {
        state.showUpdate = payload
    },
    UPDATE_SHOWMANYACTIVATION(state, payload) {
        state.showManyActivation = payload
    },
    UPDATE_SHOWMANYDEACTIVATION(state, payload) {
        state.showManyDeactivation = payload
    },
    UPDATE_SUBMITTED(state, payload) {
        state.submitted = payload
    },
    SET_ON_DOING(state, payload) {
        state.ondoing = payload
    },
}

const actions = {
    update_on_doing({ commit }) {
        commit('UPDATE_ON_DOING')
    },
    update_sidebar_driver({ commit }, data) {
        commit('UPDATE_SIDEBAR_DRIVER', data)
    },

    update_showCreate({ commit }, data) {
        commit('UPDATE_SHOWCREATE', data)
    },
    update_showUpdate({ commit }, data) {
        commit('UPDATE_SHOWUPDATE', data)
    },
    update_showManyActivation({ commit }, data) {
        commit('UPDATE_SHOWMANYACTIVATION', data)
    },
    update_showManyDeactivation({ commit }, data) {
        commit('UPDATE_SHOWMANYDEACTIVATION', data)
    },
    update_submitted({ commit }, data) {
        commit('UPDATE_SUBMITTED', data)
    },
    set_ondoing({ commit }, data) {
        commit('SET_ON_DOING', data)
    },
}

const commonModule = {
    state,
    mutations,
    actions,
    getters
}

export default commonModule;