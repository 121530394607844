<script>
    import { mapGetters, mapActions } from "vuex";
    import { ref } from "vue";
    import "leaflet/dist/leaflet.css";
    import {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LTooltip,
    // LPopup,
    LFeatureGroup,
    LPolyline,
    LControlZoom,
    LCircleMarker
    // LPolygon,
    // LRectangle,
    } from "@vue-leaflet/vue-leaflet";
    import axios from "@/services/axios-http-config";
    import url from "@/assets/files/precision1.png";
    import car from "@/assets/files/car.png";

    export default {
    name: "MapComp",
    data(){
        return {
            url,
            car,
            mapObject: null,
            centre: ref(this.center),
            params:null,
            zoom: this.zoomm,
            pos: null,
        }
    },
    mounted(){
            this.$Place_kits.IsGeoHSON(this.res.departure_point)
            ? 
                this.$Place_kits.IsGeoHSON(this.res.arrival_point)
                ?
                    this.fit_Bounds()
                    :
                    this.centre.value = [
                                this.res.departure_point.lat,
                                this.res.departure_point.lon,
                                ]
                :console.log("oups")
    },
    computed:{
        ...mapGetters({
            res: "reserv",
            userlogin: "userlogin",
            lang: "lang",
            pointTo: "checked",
            coordinates: "coordinates",
            showMap: "showMap",
            precision: "precision",
            DairportFlag: "DairportFlag",
            YairportFlag: "YairportFlag",
            NairportFlag: "NairportFlag",
            ajustTime : 'ajustTime',
            rideOrientation: "rideOrientation",
            drivers: "OnlineDrivers",
            currentUPlace:"currentUPlace"
            }),
        currentPlace:{
          get(){
            return this.currentUPlace;
          },
          set(value){
            this.updateCurrentPlace(value)
          }
        },
        distance(){
          return this.ajustTime?this.$filters.roundToTwo(this.$Place_kits.convertToKilometer(this.ajustTime.totalDistance,0)):0
        },
        minutes(){
          // 0.65 km/h is the vitesse fixed
          return Math.round(this.$filters.roundToTwo(this.$Place_kits.getEstimationTime(0.65,this.distance)))
        },
        amount(){
          return this.$Place_kits.computedTravelCost(this.res.departure_point)
        },
        confirm:{
            get(){
                return this.$store.getters.confirm;
            },
            set(value){
                value = true;
                this.update_confirm(value);

                if(value == true){
                    this.fit_Bounds()
                }
            }
            
        },
        waypoints() {

            return this.pointTo ?
            [
                {
                    lat: this.res.departure_point.lat,
                    lng: this.res.departure_point.lon,
                },
                { 
                    lat: this.position.lat, 
                    lng: this.position.lon 
                },
            ]
            :
            [];
        },
        url_precision(){
            return url;
        }
    },
    mounted() {
        this.centre.value = [this.position.lat, this.position.lon];
    },
    watch:{
        center(){
            // !(
            //     (this.$Place_kits.IsGeoHSON(this.res.departure_point)) 
            //     || 
            //     (this.$Place_kits.IsGeoHSON(this.res.arrival_point))
            // )?
            this.centre.value = this.center
            // :
            // null;
        },
        pointTo(){
            if(this.pointTo == true)
            { 

                this.fit_Bounds();
            }
            
        },
        /**
         * Map Precision management
         */
        precision(){
            if(this.precision){
                // center the map to the arrival point
                // and set the marker to the center
                this.centre.value = this.rideOrientation?[this.res.arrival_point.lat, this.res.arrival_point.lon]:[this.res.departure_point.lat, this.res.departure_point.lon];
                this.pos = this.rideOrientation?[this.res.arrival_point.lat, this.res.arrival_point.lon]:[this.res.departure_point.lat, this.res.departure_point.lon];
            }else{
                // if the precision is toggle off
                if(this.params !== null){ // check if the user have change the marker
                    // if it's the case, make a reverse search to know the place details base on LatLng informations
                    axios.get(`v1/place/Rbest/${this.params}`).then((dt) => {
                        this.rideOrientation?this.set_arg_reserv({...this.res, arrival_point: dt.data}):this.set_arg_reserv({...this.res, departure_point: dt.data});
                        this.pos = [dt.data.lat, dt.data.lon];
                    });
                }
            }
        },

        /**
         * permettre de mettre a jour le centre de la map
         */
        'res.departure_point'() {
            console.log("OKKK")
            this.$Place_kits.IsGeoHSON(this.res.departure_point)?this.centre.value = [this.res.departure_point.lat, this.res.departure_point.lon]:null;
            !this.$Place_kits.SameState(this.res.arrival_point,this.res.departure_point)?this.set_arg_reserv({...this.res, arrival_point: null}):null;
            this.rideOrientation?this.updateCurrentPlace(this.res.arrival_point):this.updateCurrentPlace(this.res.departure_point)
        },
        'res.arrival_point'() {
            if(this.pointTo == true)
            { 
                this.fit_Bounds();
            }
            else
            {
                this.$Place_kits.IsGeoHSON(this.res.departure_point)
                    ?
                    this.$Place_kits.SameState(this.res.arrival_point,this.res.departure_point)
                        ?
                        this.centre.value = [this.res.arrival_point.lat, this.res.arrival_point.lon]
                        :
                        null
                    :
                    this.$Place_kits.IsGeoHSON(this.res.arrival_point)
                        ? 
                        this.centre.value = [this.res.arrival_point.lat, this.res.arrival_point.lon]
                        :
                        this.centre.value = [this.position.lat, this.position.lon]

                this.resetFlag(false);
            }
            console.log("------<H1O>-----")
            !this.$Place_kits.SameState(this.res.arrival_point,this.res.departure_point) && !this.rideOrientation?this.set_arg_reserv({...this.res, departure_point: null}):null;
        },
        // 'res.arrival_point'() {
        //   this.centre.value = [this.res.arrival_point.lat, this.res.arrival_point.lon];
        // },
        position(){
            if(!(this.$Place_kits.IsGeoHSON(this.res.departure_point)) && !(this.$Place_kits.SameState(this.res.arrival_point,this.res.departure_point))){
                this.centre.value = [this.position.lat, this.position.lon];
                
            }
        },
    },
    methods: {
    // calling the functions from store
        ...mapActions([
        "autocomplete_client_data",
        "resetbutton",
        "reservationRequest",
        "resetFlag",
        "resetShowMap",
        "update_YaoundeTaxiFlag",
        "update_DoualaTaxiFlag",
        "update_GarouaTaxiFlag",
        "update_confirm",
        "updateCurrentPlace",
        "set_arg_reserv"
    ]),
    fit_Bounds(){

        if((this.$Place_kits.IsGeoHSON(this.res.departure_point)) && (this.$Place_kits.IsGeoHSON(this.res.arrival_point)))
        {

            this.$refs.Rmap.leafletObject.fitBounds([[this.res.arrival_point.lat, this.res.arrival_point.lon], [this.res.departure_point.lat, this.res.departure_point.lon]])
        }
        else{
            this.$refs.Rmap.leafletObject.flyTo(this.$Place_kits.IsGeoHSON(this.res.departure_point)?[this.res.departure_point.lat, this.res.departure_point.lon]:[this.res.arrival_point.lat, this.res.arrival_point.lon],18);
        }

        // let bound = []
        // if(this.$Place_kits.IsGeoHSON(this.res.departure_point))
        // {

        //     bound.push([this.res.departure_point.lat, this.res.departure_point.lon])
        // }
        // if(this.$Place_kits.IsGeoHSON(this.res.arrival_point))
        // {

        //     bound.push([this.res.arrival_point.lat, this.res.arrival_point.lon])
        // }
        // console.log(bound)
        // bound.length != 0?this.$refs.Rmap.leafletObject.fitBounds(bound):undefined

    },
    doSomethingOnReady(){
        this.track = this.$refs.track.leafletObject;
        console.log( this.track.getBounds());
    },
    changeDateField() {
        // check if the flight_number field is filled
        if (this.res.flight_number) {
            return true;
        } else {
            return false;
        }
    },
    goBack(){
        console.log(this.pointTo)
        this.resetShowMap(false);
        this.resetFlag(false);
        this.update_YaoundeTaxiFlag(false);
        this.update_DoualaTaxiFlag(false);
        this.update_GarouaTaxiFlag(false);
        this.res.departure_point = null;
    },
    changeM(event) {
        // console.log(this.$refs.Rmap.leafletObject.getCenter())
        // console.log(event)
        if ( 
            // !this.pointTo && 
            this.precision 
            // && (this.$Place_kits.IsGeoHSON(this.res.departure_point))
            ) {
            // console.log(event);
            this.pos = this.$refs.Rmap.leafletObject.getCenter();
            this.params = new URLSearchParams({
            lat: this.pos.lat,
            lon: this.pos.lng,
            });
        }
    },
    },
    components: {
        LMap,
        LIcon,
        LTileLayer,
        LMarker,
        LTooltip,
        LFeatureGroup,
        LPolyline,
        LControlZoom,
        LCircleMarker
        // LPopup,
    },
    props:{
        zoomm:{
            type: Number,
            default: 7,
        },
        center:{
            type: [Array,Object],
            required: true,
        },
        position: {
            type: Object,
            required: true
        },
    }
};
</script>

<template>
    <!-- eslint-disable -->
    <div  style="width: 100%" class="h-100">
        <!-- MAP -->
        <l-map 
            ref="Rmap"
            :zoom="zoom" 
            :center="centre.value"
            v-model="zoom" 
            v-model:zoom="zoom"
            @movestart="changeM($event)"
            @move="changeM($event)"
            @moveend="changeM($event)"
            @ready="fit_Bounds()"
            :options="{zoomControl: false}"
            :zoomAnimation="true"
            >
            <LFeatureGroup
                ref="_featureGroup"

                >
                <l-tile-layer 
                    layerType="base"
                    name="Default Base"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>

                <!-- begin zoom controller -->
                <l-control-zoom position="bottomright" class="leaflet-control-zoom"></l-control-zoom>
                <!-- begin zoom controller -->

                <!-- begin prercision marker -->
                <l-marker
                    v-if=" !this.pointTo && ($Place_kits.IsGeoHSON(this.res.arrival_point)) && precision"
                    :lat-lng="pos"
                    >
                    <l-tooltip :options="{ permanent: true, interactive: true }">
                      Precise
                    </l-tooltip>
                </l-marker>
                <!-- end prercision marker -->

                <!-- begin the arrival point when there is not precision enable and avaibility is not checked -->
                <l-marker
                    v-if=" !this.pointTo && ($Place_kits.IsGeoHSON(this.res.arrival_point)) && !precision"
                    :lat-lng="[
                        this.res.arrival_point.lat,
                        this.res.arrival_point.lon,
                        ]"
                    >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                            <span class=" pi pi-map-marker text-blue-900"> 
                                {{
                                    this.res.arrival_point.address.aeroway?
                                        this.res.arrival_point.address.aeroway
                                        :
                                            this.res.arrival_point.address.amenity
                                            ?
                                            this.res.arrival_point.address.amenity
                                            :
                                            this.res.arrival_point.address.building
                                                ?
                                                this.res.arrival_point.address.building
                                                :
                                                this.res.arrival_point.address.town
                                                    ? 
                                                    this.res.arrival_point.address.town
                                                    :
                                                    this.res.arrival_point.address.suburb
                                                        ?
                                                        this.res.arrival_point.address.suburb
                                                        :
                                                        ""
                                }}
                            </span>
                        </span>
                      </l-tooltip>
                </l-marker>
                <!-- end the arrival point when there is not precision enable and avaibility is not checked -->

                <!-- begin the arrival point when there is not precision enable and avaibility is checked -->
                <!-- <l-circle-marker
                    v-if="  this.pointTo && TypeC && !precision && !rideOrientation"
                  :lat-lng="[
                        this.res.arrival_point.lat,
                        this.res.arrival_point.lon,
                        ]"
                  :radius="8"
                  color="blue"
                /> -->
                <l-marker
                    v-if=" this.pointTo && $Place_kits.IsGeoHSON(res.arrival_point) && !precision"
                    :lat-lng="[
                        this.res.arrival_point.lat,
                        this.res.arrival_point.lon,
                        ]"
                    >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                            <span class=" pi pi-map-marker text-blue-900"> 
                                {{
                                    this.res.arrival_point.address.aeroway?
                                        this.res.arrival_point.address.aeroway
                                        :
                                            this.res.arrival_point.address.amenity
                                            ?
                                            this.res.arrival_point.address.amenity
                                            :
                                            this.res.arrival_point.address.town
                                                ? 
                                                this.res.arrival_point.address.town
                                                :
                                                this.res.arrival_point.address.suburb
                                                    ?
                                                    this.res.arrival_point.address.suburb
                                                    :
                                                    ""
                                }}
                            </span>
                        </span>
                      </l-tooltip>
                </l-marker>
                <!-- end the arrival point when there is not precision enable and avaibility is checked -->

                <!-- begin airports cabs placement -->

                <l-marker
                    :lat-lng="[driver.lastPosition.lat, driver.lastPosition.lon]"
                    v-for="driver in drivers"
                    >
                    <l-icon
                    :icon-size="[40, 40]"
                    :icon-anchor="[22, 94]"
                    :icon-url="car"
                    >
                    </l-icon>
                </l-marker>
                <!-- end airports cabs placement -->

                <!-- begin the departure point when there is not precision enable and avaibility is checked -->
                <l-circle-marker
                    v-if="this.pointTo && $Place_kits.IsGeoHSON(res.departure_point) && !precision"
                  :lat-lng="[this.res.departure_point.lat,this.res.departure_point.lon]"
                  :radius="8"
                  color="#2880CA"
                >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                            <span class=" pi pi-map-marker text-blue-900"> 
                                {{
                                    this.res.departure_point.address.aeroway?
                                        this.res.departure_point.address.aeroway
                                        :
                                        this.res.departure_point.address.amenity
                                            ?
                                            this.res.departure_point.address.amenity
                                            :
                                            this.res.departure_point.address.building
                                                ?
                                                this.res.departure_point.address.building
                                                :
                                                this.res.departure_point.address.town
                                                    ? 
                                                    this.res.departure_point.address.town
                                                    :
                                                    this.res.departure_point.address.suburb
                                                        ?
                                                        this.res.departure_point.address.suburb
                                                        :
                                                        ""
                                }}
                            </span>
                        </span>
                    </l-tooltip>
                </l-circle-marker>
                <!-- end the departure point when there is not precision enable and avaibility is checked -->

                <!-- begin tracking current user position when the departure point is not defined and not precision enabled -->
                <l-marker
                    key="al"
                    v-if="!($Place_kits.IsGeoHSON(this.res.departure_point) || ($Place_kits.IsGeoHSON(this.res.arrival_point))) && ($Place_kits.IsGeoHSON(this.position)) && !precision"
                    :lat-lng="[this.position.lat,this.position.lon]"
                    >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                            <span class=" pi pi-map-marker text-blue-900"> 
                                {{
                                    this.position.address.aeroway?
                                        this.position.address.aeroway
                                        :
                                        this.position.address.amenity
                                            ?
                                            this.position.address.amenity
                                            :
                                            this.position.address.building
                                                ?
                                                this.position.address.building
                                                :
                                                this.position.address.town
                                                    ? 
                                                    this.position.address.town
                                                    :
                                                    this.position.address.suburb
                                                        ?
                                                        this.position.address.suburb
                                                        :
                                                        ""
                                }}
                            </span>
                        </span>
                    </l-tooltip>
                </l-marker>
                <!-- end tracking current user position when the departure point is not defined and not precision enabled -->

                <!-- begin placing the departure point when the precion and showmap are not enables -->
                <l-circle-marker
                    v-if="!this.pointTo && ($Place_kits.IsGeoHSON(this.res.departure_point)) && !precision"
                  :lat-lng="[this.res.departure_point.lat,this.res.departure_point.lon]"
                  :radius="8"
                  color="#2880CA"
                >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                            <span class=" pi pi-map-marker text-blue-900"> 
                                {{
                                    this.res.departure_point.address.aeroway?
                                        this.res.departure_point.address.aeroway
                                        :
                                        this.res.departure_point.address.amenity
                                            ?
                                            this.res.departure_point.address.amenity
                                            :
                                            this.res.departure_point.address.building
                                                ?
                                                this.res.departure_point.address.building
                                                :
                                                this.res.departure_point.address.town
                                                    ? 
                                                    this.res.departure_point.address.town
                                                    :
                                                    this.res.departure_point.address.suburb
                                                        ?
                                                        this.res.departure_point.address.suburb
                                                        :
                                                        ""
                                }}
                            </span>
                        </span>
                    </l-tooltip>
                </l-circle-marker>
               <!--  <l-marker
                    v-if=" !this.pointTo && ($Place_kits.IsGeoHSON(this.res.departure_point)) && !precision"
                    :lat-lng="[this.res.departure_point.lat,this.res.departure_point.lon]"
                    >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                            <span class=" pi pi-map-marker text-blue-900"> 
                                {{
                                    this.res.departure_point.address.amenity
                                        ?
                                        this.res.departure_point.address.amenity
                                        :
                                        this.res.departure_point.address.building
                                            ?
                                            this.res.departure_point.address.building
                                            :
                                            this.res.departure_point.address.town
                                                ? 
                                                this.res.departure_point.address.town
                                                :
                                                this.res.departure_point.address.suburb
                                                    ?
                                                    this.res.departure_point.address.suburb
                                                    :
                                                    this.res.departure_point.address.aeroway
                                }}
                            </span>
                        </span>
                      </l-tooltip>
                </l-marker> -->
                <!-- end placing the departure point when the precion and showmap are not enables -->

                <!-- begin drawing the path when availabitity exist and not precision enable-->
                <l-polyline
                    v-if=" this.pointTo && $Place_kits.IsGeoHSON(res.departure_point) && $Place_kits.IsGeoHSON(res.arrival_point) && !precision && coordinates.length != 0"
                    :lat-lngs="coordinates"
                    color="blue"
                    >
                    <l-tooltip
                        :options="{
                          permanent: true,
                          interactive: false
                        }"
                      >
                        <span>
                          <span class="bi bi-alarm-fill text-blue-900"><span class="ml-1">{{minutes}} MIN</span></span><br/>
                          <span class="bi bi-rulers text-blue-900"><span class="ml-1">{{distance}} KM</span></span><br/>
                          <span class="bi bi-cash-coin text-blue-900"><span class="ml-1">{{amount}} XAF</span></span><br/>
                        </span>
                    </l-tooltip>
                </l-polyline>
                <!-- begin drawing the path when availabitity exist and not precision enable-->
            </LFeatureGroup>
        </l-map>
        <!-- MAP -->
    </div>
</template>

<style lang="scss" scoped>

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>
