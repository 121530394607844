<template>
  <div class="dropdown me-2">
    <a class="me-2" href="#" id="Dropdown" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
      <i class="bi bi-translate m-0 fs-4"></i>
    </a>

    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-center mt-3" aria-labelledby="Dropdown">
      <li>
      <a class="dropdown-item" href="#"><i :class="'flag flag-' + $i18n.locale.toUpperCase()"></i>{{country.name}} <i class="fa fa-check text-success ms-2"></i></a>
      </li>
      <li><hr class="dropdown-divider" /></li>
      <li v-for="ob,i in countries" :key="i" @click="changeC(ob)">
      <a class="dropdown-item" href="#"><i :class="'flag flag-' + ob.code"></i>{{ob.name}}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LangC",
  computed: {
    ...mapGetters({
      lang: "lang",
    }),
  },
  data() {
    return {
      country: { name: "English", code: "US" },
      i: 0, 
    };
  },
  created() {
    this.$i18n.locale.toUpperCase() == "US"
      ? (this.country = { name: this.$t("lang.ca"), code: "US" })
      : (this.country = { name: this.$t("lang.fr"), code: "FR" });
  },
  mounted() {},
  watch: {},
  methods: {
    // calling the functions from store
    ...mapActions(["update_lang"]),
    changeC(newValue) {
      this.country = newValue;
      // console.log(this.country.code.toLowerCase());
      this.$i18n.locale = this.country.code.toLowerCase();
      this.country.code == "US"
        ? ((this.country = { name: this.$t("lang.ca"), code: "US" }),
          this.update_lang("US"))
        : ((this.country = { name: this.$t("lang.fr"), code: "FR" }),
          this.update_lang("FR"));
    },
  },
  computed: {
    countries() {
      return [
        { name: this.$t("lang.fr"), code: "FR" },
        { name: this.$t("lang.ca"), code: "US" },
      ];
    },
    logoName() {
      this.i++;
      return `Logo ${this.i}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.p-dropdown {
  border: none;

  .p-dropdown-trigger {
    display: none;
  }
}
</style>