
import { isEqual, words } from 'lodash';
import axios from "@/v2/services/axios-http-config";

/**
 * @module
 * Place Services
 * @author dtvni
 * @version 1
 */

export default class Place_kits {
	/**
     * Edit distance between two string
     * @method
     * @param {string} s1 - first string
     * @param {string} s2 - second string
     * @return {integer} costs[-1]
     */
	editDistance(s1, s2) {
		// pass all strings to lower case
		s1 = s1.toLowerCase();
		s2 = s2.toLowerCase();

		let costs = new Array();
		for (let i = 0; i <= s1.length; i++) {
			let lastValue = i;
			for (let j = 0; j <= s2.length; j++) {
				if (i == 0)
				{
				  costs[j] = j;
				}
				else {
				  if (j > 0) {
				    let newValue = costs[j - 1];
				    if (s1.charAt(i - 1) != s2.charAt(j - 1))
				    {
				      newValue = Math.min(Math.min(newValue, lastValue),
				                  costs[j]) + 1;
				    }
				    costs[j - 1] = lastValue;
				    lastValue = newValue;
				  }
				}
			}
			if (i > 0)
			{
				costs[s2.length] = lastValue;
			}
		}
		return costs[s2.length];
	}

	/**
     * Search a key inside an object attributes
     * @method
     * @param {string} subject - partern's string
     * @param {object} objects - object to explore
     * @return {array} matches
     */
	search(subject, objects){
		let matches = [];
		let regexp = new RegExp(subject, 'g'); // link a regex to the partern entry
		for (let key in objects) { // for each keys in the object as attributes, verify if the value is equal or near of the partern
			if (objects[key].normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().match(regexp) ||  // normalize the value and replace diacritic symbols with empty string
			this.editDistance(objects[key].normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase(),subject)/subject.length < 0.4) { // or check the nearest's the distance between value and partern
				matches.push(objects[key]);
			}
		}
		return matches;
	}

	/**
     * Check if two place are in the state
     * @method
     * @param {object} place1 - first place
     * @param {object} place2 - second place
     * @return {boolean} flag
     */
	SameState(place1, place2){
		let flag = this.IsGeoHSON(place1) && this.IsGeoHSON(place2) ?place1.address.state == place2.address.state:false;
		return flag;
	}

	/**
     * Check if place is a GeoJSON object
     * @method
     * @param {object} place - place to check
     * @return {boolean} flag
     */
	IsGeoHSON(place){
		let flag = false
		if(typeof place === "object" && place !== null ? Object.keys(place).length != 0 : false) 
            if(place.lat && place.address)
            	flag = true
		return flag;
	}

	/**
     * Search a key inside an object attributes using deep search
     * @method
     * @param {string} subject - partern's string
     * @param {object} objects - object to explore
     * @return {boolean} false - not found, true - found
     */
	advancedSearch(subject, objects, currentPlace){
		// console.log(currentPlace)
		if(Object.values(objects).some(i => i.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().includes(currentPlace.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase()))){
			return true;
		}
		else{
			let a = words(subject)
			let flag = false;
			a.forEach(el=>{
				if(Object.values(objects).some(i => i.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().includes(el.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase()))){
					flag = true;
				}
			})
			return flag;
		}
	}

	/**
     * Search a place
     * @method
     * @param {object} event - event object's
     * @param {boolean} checked - object to explore
     * @param {object} departure_point - starting point object
     * @param {boolean} departure_point_flg - starting point object
     * @param {string} currentPlace - current user need place
     * @return {array} suggestions
     */
	async searchPlace(event, checked, departure_point, departure_point_flg, currentPlace) {
		let suggestions = []
		if (!checked) {
			// Only make search, if there is value in query input
			if (event.query !== "") {
				let beta = []
				await axios.get(`v1/place/best/${event.query}`).then((dt) => {
					if (dt.data !== null) {
						if (dt.data.length !== 0) {
							// console.log(dt)
							dt.data.forEach((el) => {
								if(!departure_point_flg)
								{
									// console.log("gj")
									if(this.advancedSearch(currentPlace,el.address, currentPlace)){
										let a = this.search(currentPlace.toLowerCase(), el.address);
										let dst = a.map(els=> this.editDistance(els,currentPlace));
										beta.push({
											data: el,
											score: Math.min.apply(Math, dst)
										})
									}
								}
								else if (departure_point.address.state == el.address.state || 
									departure_point.address.county == el.address.county)
								{
									// console.log("ffd")
									if(this.advancedSearch(currentPlace,el.address, currentPlace)){
										let a = this.search(currentPlace.toLowerCase(), el.address);
										let dst = a.map(els=> this.editDistance(els,currentPlace));
										beta.push({
											data: el,
											score: Math.min.apply(Math, dst)
										})
									}
								}
							});

							// check if the previous result is important
							for (let j = 0; j < suggestions.length; j++){
								if(this.advancedSearch(currentPlace,suggestions[j].address, currentPlace)){
									let a = this.search(currentPlace.toLowerCase(), suggestions[j].address);
									let dst = a.map(els=> this.editDistance(els,currentPlace));
									beta.push({
										data: suggestions[j],
										score: Math.min.apply(Math, dst)
									})
								}
							}
							// sort the beta array base on score ascending
							beta.sort(function(a, b) {
								if (a.score > b.score) return 1;
								if (a.score < b.score) return -1;
								return 0;
							});
							// lookup for duplications
							let temp = []
							if(beta.length > 0){
								temp.push(beta[0].data)
							}
							for (let i = 1; i < beta.length; i++){
								let flag = false
								for (let j = 0; j < temp.length; j++){
									if(isEqual(beta[i].data, temp[j])){
										flag = true
									}
								}
								if(flag == false){
									temp.push(beta[i].data)
								}
							}
							// set the suggestion
							suggestions = [...new Set([...temp]),];
							/*console.log(this.suggestions)
							console.log(beta)
							console.log("ended")*/
						}
					}
				});
			}
		}
		return suggestions
	}


	/**
     * Get the travel Cost of booking 
     * @method
     * @param {object} startPoint - departure point
     * @return {number} val
     */
	computedTravelCost(startPoint) {
      let val = 0;
      // console.log(this.reserv.departure_point.address)
      if (this.IsGeoHSON(startPoint)) {
        switch (startPoint.address.state) {
          case "Centre":
              val = parseInt(process.env.VUE_APP_YAOUNDE_CONFORT);
            break;
          case "Littoral":
              val = parseInt(process.env.VUE_APP_DOUALA_CONFORT);
            break;
          case "North":
            val =  parseInt(process.env.VUE_APP_DOUALA_CONFORT);
            break;
        }
      }

      return val;
    }


    /**
     * Get estimation time
     * @method
     * @param {number} vitesse - reference the constance vitesse suppose make by driver in km/h
     * @param {number} kiloDistance - reference the constance kiloDistance
     * @return {number} time - of distance between starting and ending point in format of unit passed in contruction
     */
    getEstimationTime(vitesse, kiloDistance) {
        /**
         * We know vitesse = distance / time 
         * To extract time, we just need to compute time = distance / vitesse
         * for reason of vitesse unit, we'll use kilodistance for computation
         */
        let time = kiloDistance / (vitesse > 0 ? vitesse : 1);
        return time;
    }

    /**
     * Get the travel distance of booking to kilometer
     * @method
     * @param {double} distance - total distance
     * @param {integer} unit - 1 for mille entry and 0 for meter
     * @return {double} val
     */
    convertToKilometer(distance, unit) {
        let kiloDistance;
        if (unit == 1) {
            /* We know that 1.60934km = 1mi */
            kiloDistance = distance * 1.60934;
        } else {
            /* We know that 0.001km = 1meter */
            kiloDistance = distance * 0.001;
        }
        return kiloDistance;
    }
}