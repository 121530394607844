/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";

import '@/startup/LoadAssets'
import ComponentsB from '@/startup/ComponentsB'
import Directive from '@/startup/LoadDirective'
import ComponentsP from '@/startup/LoadPrimeComp'
import Services from '@/startup/LoadServices'
import Layouts from '@/startup/LayoutB'

const app = createApp(App);

Services(app);
Directive(app);
ComponentsB(app);
ComponentsP(app);
Layouts(app);

app.mount('#app');

if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_PWA_LOCAL_SERVE === 'true') {
  console.log(`PWA Local Serve: ${process.env.VUE_APP_PWA_LOCAL_SERVE}`) // eslint-disable-line no-console
  console.log(`Node Env: ${process.env.NODE_ENV}`) // eslint-disable-line no-console
}
