/* eslint-disable */
const fr = {
    nextFeature: "Bientôt disponible ...",
    empty: "Aucun resultat",
    LoginBooking: "Se connecter pour terminer",
    BW: "Espace BONGO",
    BUO: "Option utilisateur BONGO",
    translate:"Changer de langue",
    userspace:{
        title: 'Espace utilisateur',
        profil:{
            title:"Profil",
            name: "Nom",
            surname:"Prénom"
        },
        history:{
            title:"Historique des réservations"
        },
        wallet:{
            title:"Portefeuille BONGO"
        },
        notification:{
            title: "Notifications"
        },
        settings:{
            title:"Paramètres de l'utilisateur"
        }
    },



    position: "Ici",
    ariaLabel: {
        calendar: {
            now: "Maintenant",
            clear: "Effacer",
            Valid: "Ok",
            later: "Pour Plutard"
        },
        switch: {
            0: "Valider",
            1: "Preciser",
        },
        daero: {
            label: "champs de choix d'aeroport",
            placeholder: "Choisir votre aéroport",
            desc1: "L'embarquement aura lieu à",
            desc2: "Le débarquement s'effectuera à",
            desc3: "Votre trajet s'effectuera en",
            desc4: "Pour une distance de",
            desc5: "Les frais du trajet s'élève à",
            desc6: "est la personne responsable du trajet",
            desc7: "Correct ? Alors, veuillez choisir un mode de payment",
        },
        comp:{
            placeholder:"Choisis ta compagnie aérienne",
            label:"Champs de choix de compagnie",
            via:"Via"
        },
        cdate: {
            label: "champs de choix de la date d'arrivé au Cameroun",
            placeholder: "choisir la date de votre arrivé"
        },
        chour: {
            label: "champs de choix d'heure' d'arrivé au Camerou",
            placeholder: "choisir l'heure de votre arrivé"
        }
    },
    lang: {
        ca: "Anglais",
        fr: "Français",
    },
    landing: {
        nav: {
            brand: {
                title: "BongoRide",
                "full-name": "BongoRide",
            },
            contextualMenu: {
                client: {
                    disc: 'Se déconnecter',
                    info: 'Infos Compte'
                }
            },
            links: {
                1: "Accueil",
                2: "Reservation",
                3: "Prix",
                4: "Services",
                5: "Atouts",
                6: "Historique de reservations"
            },
            "login-btn": "Se connecter",
            "register-btn": "S'enregistrer",
            connect: "Connexion",
        },
        caroussel: {
            slogan: {
                light: "Votre",
                main: "Sécurité",
                main1: "Confort",
                and: "et",
                main2: "Ponctualité",
                prepend: "dans votre paume de main.",
            },
            "attach-word": "Pour tous vos déplacements aux aéroports du Cameroun, faites confiance au premier et plus fiable service de transfert camerounais.",
            "btn": "Reservez maintenant",
        },
        services: {
            title: "NOS SERVICES",
            "sub-title": "nos offres sont vraiment intéressantes",
            side1: {
                title: "Reservation distante",
                "sub-title": "d'un taxi des aéroports du Cameroun ",
                content: "Ce premier aspect de notre solution permet à l'utilisateur de notre système de pouvoir reserver un taxi de n'importe quel pays qu'il pourra embarqué une fois qu'il atterira dans n'importe quel aéroport du pays.",
            },
            side2: {
                title: "Reservation instantanée",
                "sub-title": "d'un taxi des aéroports du Cameroun ",
                content: "Ce premier aspect de notre solution permet à l'utilisateur de notre système de pouvoir reserver un taxi pour un depart instantané dans n'importe quel aéroport du pays.",
            },
        },
        powerful: {
            title: "Puissant partout",
            "sub-title": "Pourquoi devez-vous faire confiance à nos services",
            side1: {
                title: "Prévision de trajet",
                content: "Dans notre pays, connaître quelques informations sur l'endroit où l'on va est une chance. Cela peut vous aider à éviter les embouteillages, à prévenir les routes avec des nids de poule et à savoir quel est le meilleur point pour l'embarquement. Tout cela, nous vous le donnons gratuitement.",
            },
            side2: {
                title: "Visualisation de la mobilité au Cameroun",
                content: "La plupart des projets camerounais ont échoué à cause de l'absence d'étude de la mobilité urbaine. C'est la base de l'évolution de chaque pays, la maîtrise de ce facteur donne le pouvoir parce que la connaissance est le pouvoir et 21 air est couvert par les technologies qui nous aident à visualiser la connaissance et de prévoir les événements, l'état, etc. Nous vous l'offrons pour pas grand chose.",
            },
        },
        prices: {
            title: "Prix incomparables",
            "sub-title": "pour une sécurité et un confort optimaux.",
            "action-btn": "Réservez maintenant",
            estimation: "par transport",
            1: {
                title: "Douala Confort",
            },
            2: {
                title: "Yaoundé Confort",
            }
        },
        highlight: {
            "attach-sent": "Nous sommes suffisamment grands pour répondre à vos besoins en matière de transport. Vous pouvez nous contacter pour un service et être assuré que vous recevrez une réponse. Nous garantissons les meilleurs prix et un excellent service. Réserver un transfert n'a jamais été aussi facile.",
        },
        booking: {
            remote: {
                title: "Réservation à distance",
                departI: "départ",
                arrivalI: "destination",
                flightI: "numéro de vol",
                dFI: "Date d'arrivée du vol",
                aFI: "Heure d'arrivée du vol",
                searchB: "Connectez-vous pour compléter",
                bookB: "Commander",
                fL: "Vol",
                duration: 'Durée estimée',
                distance: 'Distance estimée',
                cost: 'Montant'
            },
            instant: {
                title: "Réservation instantanée",
                departI: "Lieu de départ"
            }
        }
    },
    booking: {
        steps: {
            1: "Trajet",
            2: "Données du passager",
            3: "Confirmation",
            4: "Paiement"
        },
        TripDetais:{
            progressBar:{
                val1: "champs sur",
                val2: "ont été rempli",
                val3: "Nous vérifions s'il existe des Taxieurs disponible pour cette reservation",
                val4: "Désolé, nous n'avons malheureusement pas trouvé de taxi disponible correspondant à vos critères. Veuillez probablement ajuster des détails!"
            }
        },
        bB: "Retour",
        nB: "Valider",
        eb: "Finir",
        pb: "Imprimer",
        travelD: {
            autoLabel: "Recherchez un lieu",
            precise: "Precises le lieu",
            checkbox: "Je confirme que la destination entré est bel et bien correct"
        },
        personalD: {
            name: "Nom alias",
            surname: "Prénom alias",
            email: "Email",
            span: "Pour l'envoi de votre confirmation de réservation",
            comp:"Compagnie",
            secret1: "Tu ne souhaites pas partager ton identité avec le chauffeur ?",
            secret2: "Saisi ton alias.",
            promo: "Entre ton code",
        },
        confirmD: {
            title: "Récapitulatif ",
            nameL: "Nom et prénom",
            surnameL: "Prénom",
            typeL: "Type de reservation",
            departL: "Lieu de départ",
            dropOffL: "Lieu de d'arrivée",
            dateL: "Date de départ",
            hourL: "À",
            coutL: "Prix",
            bill: "Montant à payer",
            Cp1: "Valeur du code promo:",
            Cp3: "Code invalide",

        },
        bookingHistory: {
            title: "Historique des Réservations",
            code: "Code",
            driverN: "Nom du conducteur",
            driverS: "Prénom du conducteur",
            PhoneNumber: "Téléphone",
            hourL: "Heure de départ",
            hourA: "heur d'arrivé",
            flightN: "Numéro du vol",
            cost: "prix",
            moyenP: "Moyen de paiement",
            Tcode: "code du trajet",
            status: "Statut",
            reviews: "Avis des clients",
            loading1: "Recherche...",
            loading2: "Chargement des données, veuillez patienter...",
            emP: "Aucun enregistrement trouvé.",
            action: {
                download: "Télécharger le Récu ",
                rating: "Évaluation",
                confirmD: "Confirmer le débarquement",
                comment: "Commenter",
                signal: "Signaler",
                title: "Options lié à cette reservation"
            },
            ratingM: {
                header: "Évaluation : Voyage N°",
                qst: "Comment a été e trajet ?",
                msg: "Votre commentaire ..."
            }

        },
        AccountDetails: {
            title: "Informations Personnelles",
            img: "Charger une nouvelle image",
            imgS: "JPG ou PNG inférieur à 5 MB",
            btn: "Modifier",
            country: "Pays",
            pH: "Selectionner votre pays",
            upbtn: "Charger une nouvelle image",
            imgSize: "Taille maximal de l'image : 2Mbytes"
        },
        msg: {
            1: "Votre requête de réservation a été envoyée, veuillez vérifier votre adresse email. Nous vous prions aussi de bien vouloir vous rassurez que votre appareil reste allumé lors du trajet pour pouvoir confirmer votre débarquement ",
            2: "votre requête de reservation a échoué, veuillez reéssayer "
        },
        paymentMethod: {
            1: "Si vous avez un code veuillez le saisir ",
            2: "Choisissez votre mode de paiement",
            3: "Code de promotion",
            4: "Vérifier",
            5: "Utiliser",
            6: "Ignorer",
            7: "Réserver",
            secret1: "Code de réduction ?",
            secret2: " utilises le !",
        }
    },
    driver: {
        title: {
            1: "Vérifier la réservation",
            2: "Trajet en cours",
            3: "Trajet(s) terminé(s)",
        },
        "sub-title": "VÉRIFIER LA RÉSERVATION",
        bookingHistory: {
            title0: "Historique des réservations",
            title1: "Historique des trajets en cours",
            title2: "Historique des trajet(s) terminé(s)",
        },

        driverD: {
            smallText: "pour vérifier une réservation veuillez entrer le code de réservation",
            title0: "Création de Taxieur",
            title1: "Informations du client",
            title2: "Informations taxieur",
            createORD: "Mise à jour de la réservation",
            updateDD: "Mise à jour du Taxieur",
            Dd0: "Profil",
            Dd1: "Le nom est obligatoire",
            Dd2: "Le Numéro de téléphone est obligatoire.",
            Dd3: "L'adresse email est requis.",
            Dd4: "Numéro d'immatriculation du véhicule",
            Dd5: "Numéro d'Immatriculation du véhicule requis.",
            Dd6: "Numéro du permis de conduire",
            Dd7: "Numéro du permis requis.",
            Dd8: "Numéro du taxi dans le système",
            Dd9: "Numéro de la CNI",
            Dd10: "Numéro de cni requis.",
            Dd11: "Nom de l'aéroport",
            Dd12: "Nom de l'aéroport requis.",
            Dd13: "CNI",
            Dd14: "Le prénom est obligatoire.",
            Dd15: "Télécharger un fichier(s)/document(s).",
            Dd16: "Télécharger le fichier",
            Dd17: "Date d'expiration du fichier",
            Dd18: "Document du chauffeur",
            mgvd: "Cette reservation est valide",
            errmgvd: "Cette réservation n'existe pas",
            btn1: "Retour",
            btn2: "Confirmer",

            dataT: {
                title: "Gestion des Taxieurs",
                code: "Code",
                pH: "Recherche...",

            },
            manyA: {
                1: "Êtes-vous sûre de vouloir activer ces Taxieurs?",
                2: "Selectionner les taxieurs",
                3: "Êtes-vous sûre de vouloir désactiver ces Taxieurs?"

            }
        }
    },
    admin: {
        headMsg: "AJOUTER UN NOUVEL EMPLACEMENT",
        hd1: "Création de Taxieur",
        loc: "Emplacement",
        btn: "Ajouter",
        btn1: "Annuler",
        btn2: "Enregistrer",
        btn3: "Ajouter",
        btn4: "désactiver",
        btn5: "activer",
        btn6: "Importer",
        btn7: "Exporter",
        btn8: "Places",
        btn9: "Télécharger",
        st1: "Activé",
        st2: "désactivé",
        ch0: "checkIn",
        Ures: "Informations de Réservation",
        codePromo: {
            title1: "Création du code de promotion",
            title2: "Gestion du code de promotion",
            prix: "price",
            code: "code",
            expDate: "Date d'expiration",
            fileExpDate: "Date d'expiration du fichier",
            txt1: "Le montant est obligatoire",
            txt2: "Code",
            txt3: "counter",
            cP: "Compteur",
            dt1: "Date de création",
            dt2: "Date d'expiration",
            dt3: "Date de modification",
            btn1: "Ajouter",
            updateC: "Mise à jour du Code",
            smT1: "Le prix est obligatoire",
            smT2: "caractères alphanumériques obligatoires",
            smT3: "La date est obligatoire",

        },
        manyA: {
            1: "Êtes-vous sûre de vouloir activer ces codepromos?",
            2: "Selectionner les promocodes",
            3: "Êtes-vous sûre de vouloir désactiver ces codepromos"

        }



    },
    appfooter: {
        column1: {
            title: "Entreprise",
            row1: "À propos de nous",
            row2: "Actualités",
            row3: "Relations investisseurs",
            row4: "Carrières",
            row5: "Dossier de presse",
        },
        column2: {
            title: "Resources",
            row1: "Démarrez",
            row2: "Apprendre",
            row3: "Études de cas",
        },
        column3: {
            title: "Communauté",
            row1: "Discorde",
            row2: "Événements",
            row3: "FAQ",
            row4: "Blog",
        },
        column4: {
            title: "Mentions légales",
            row1: "Politique de la marque",
            row2: "Politique de confidentialité",
            row3: "Conditions d'utilisation",
        }
    },
    auth: {
        forgotC: {
            head1: "Récupération de mot de passe",
            title1: "Possedez déjà un compte? Connectez vous maintenant!",
            title2: "Créer votre compte maintenat!",
            summary: "Recupération de mot de passe",
            detail: "Veuillez Vérifier votre boite mail.",
            errorM: "Un problème est survenu, veuillez reéssayer",
            btn: "Lancer la récupération"
        },
        loginC: {
            title1: "Mot de passe oublié?",
            title2: "Créer votre compte maintenant!"

        },
        registerC: {
            nam: "Nom",
            surn: "Prénom",
            phone: "Téléphone",
            pass: "Mot de passe",
            summary: "Création de compte",
            btn: "Créer le compte",
            em: "Email"
        },
        profileC: {
            Hd: "Informations Personnelles",
            country: "pays",
            pH: "Selectionner votre pays",

        }
    },
    layout: {
        homeC: "Se déconnecter",
        checkDriverA: "Aucun chauffeur n'est disponible pour la période demandée ",
        checkDriverD: "Un chauffeur est disponible pour la période demandée "
    }


};

export default fr;