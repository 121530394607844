import axios from '@/services/axios-http-config';
// import jwt from 'jsonwebtoken';
const state = {
    pmCode: {},
    pmCodes: [],
    pmselected: null,
    placeselected: null,
    _pmcodeFvalue: 0,
    _pmcodeTvalue: 0,
    _pmcodeTvalueReset: 1,
    _pmcodeExpDate: null,
    vcode: false, //vcode: valid promo code
    ignorecode: false,
    ncode: true,
    icode: false,
    preventRobotMalware: true,
    togglePreventRobotMalware: false,
    buttonStatusCnt: 0,
    myTimeout: 0,
    controlButton: false,
    freeTicket: true,

}

const getters = {
    pmCode: state => state.pmCode,
    pmCodes: state => state.pmCodes,
    pmselected: state => state.pmselected,
    placeselected: state => state.placeselected,
    _pmcodeFvalue: state => state._pmcodeFvalue,
    _pmcodeTvalue: state => state._pmcodeTvalue,
    _pmcodeTvalueReset: state => state._pmcodeTvalueReset,
    vcode: state => state.vcode,
    ncode: state => state.ncode,
    icode: state => state.icode,
    _pmcodeExpDate: state => state._pmcodeExpDate,
    preventRobotMalware: state => state.preventRobotMalware,
    buttonStatusCnt: state => state.buttonStatusCnt,
    myTimeout: state => state.myTimeout,
    togglePreventRobotMalware: state => state.togglePreventRobotMalware,
    controlButton: state => state.controlButton,
    ignorecode: state => state.ignorecode,
    freeTicket: state => state.freeTicket,
   

}

const mutations = {
    UPDATE_CONTROL_BUTTON(state,payload){
        state.controlButton = payload;
    },
    UPDATE_PM_CODE(state,payload){
        state.pmCode = payload;
    },
    CLEAR_PMCODE(state) {
        state.pmCode = {}
    },
    SETVALIDC(state) {
        state.vcode = true;
        state.icode = false;
        state.ncode = false;
    },
    DISABLEDBUTTON(state) {
        state.buttonStatusCnt = state.buttonStatusCnt + 1;

        if (state.buttonStatusCnt == 19) {
            const d = new Date();
            let time_start = d.getTime();
            state.preventRobotMalware = false;
            state.buttonStatusCnt = 0;
            state.myTimeout = state.preventRobotMalware;
            localStorage.setItem("computeButtonSession", JSON.stringify(time_start));

        }

    },
    RESETBUTTON(state) {
        state.preventRobotMalware = true;
    },
    SETNC(state) {
        state.ncode = true;
        state.vcode = false;
        state.icode = false;
    },
    RESET_PCVALUE(state) {
        state._pmcodeTvalueReset = 0;
    },
    USE_PMCODE(state) {
        state.controlButton = true;
        state.ignorecode = true;
    },
    DEFAULT_PMCODEB(state) {
        state.controlButton = false;
    },
    DEFAULT_PROMOCODEB(state, payload) {
        state.controlButton = payload;
        state.ignorecode = payload;
    },
    D_IGNOREB(state, payload) {
        state.ignorecode = payload;
    },
    SETINVALIDC(state) {
        state.icode = true;
        state.vcode = false;
        state.ncode = false;
    },
    CLEAR_PMSELECTED(state) {
        state.pmselected = []
    },
    COMPUTE_VALUE(state, payload) {
        state._pmcodeTvalue = payload
    },
    COMPUTE_EXPDATE(state, payload) {
        state._pmcodeExpDate = payload
    },
    SET_PMCODES(state, payload) {
        state.pmCodes = Array.isArray(payload) ? payload : [payload]
    },

    SET_PMCODE(state, payload) {
        state.pmCode = payload
    },
    UPDATE_PMCODES(state, payload) {
        state.pmCodes = state.pmCodes.map(obj => payload._id === obj._id ? payload : obj)

    },
    SET_SELECTEDPMCODES(state, payload) {
        state.pmselected = payload
    },
    UPDATE_PMCODESFROMBACKEND(state, payload) {
        state.pmCodes.find(obj => payload._id === obj._id) != null ? state.pmCodes = state.pmCodes.map(obj => payload._id === obj._id ? payload : obj) : state.pmCodes.push(payload);
    },
    HIDE_PAYMENTMETHOD(state, payload) {
        state.freeTicket = payload;
    },
    LAST_HOUR_CHECK(state){
        state.icode = true;
        state.vcode = false;
        state.ncode = false;
        state.controlButton = false;

    }
}

const actions = {
    update_control_button({commit},data){
        commit('UPDATE_CONTROL_BUTTON',data);
    },
    update_pm_code({commit},data){
        
        commit('UPDATE_PM_CODE',data)
        
    },
    clear_pmcode({ commit }) {
        commit('CLEAR_PMCODE')
    },
    disablePromoCode({ commit }, data) {
        console.log('update_pm_code')
        return axios.post('v1/promo/update', data).then((response) => {
            response.data != null ? commit('SETVALIDC') : null
        })
    },
    checkPromoCode({ commit }, data) {

        return axios.post('v1/promo/check', data).then((response) => {

                response.data != null ? commit('SETVALIDC') : null
                commit("UPDATE_PM_CODE", response.data)
                commit("COMPUTE_VALUE", response.data.price)
                commit("COMPUTE_EXPDATE", response.data.endedAt)
                    // commit("DISABLEDBUTTON");
                commit("USE_PMCODE");

                // state.controlButton = true;

            })
            .catch(error => {
                commit('SETINVALIDC');
                commit("DISABLEDBUTTON");
                commit("DEFAULT_PMCODEB");
            });

    },
    pmcodeDialogButton({ commit }, data) {
        commit("DEFAULT_PROMOCODEB", data);
        commit("SETNC");
    },
    pmcodelastHourCheck({ commit }, data) {
        commit("LAST_HOUR_CHECK");
    },
    disableIgnoreButton({ commit }, data) {
        commit("D_IGNOREB", data);
    },
    resetbutton({ commit }) {
        commit("RESETBUTTON");
    },
    resetInputfield({ commit }) {
        commit('SETNC');
        commit("RESET_PCVALUE");

    },
    set_pmcode({ commit }, data) {
        commit('SET_PMCODE', data)
    },
    hide_paymentmethod({ commit }, data) {
        commit("HIDE_PAYMENTMETHOD", data);
    },
}

const pmCodeModule = {
    state,
    mutations,
    actions,
    getters
}

export default pmCodeModule;