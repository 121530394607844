<template>
  	<div>
		<div :class="['d-flex flex-row bd-highlight w-full my-1 justify-content-center',isMobile?'p-0 m-0 pb-4 mb-2':'']">
			<InlineMessage
				:style="{
					color: '#2020d0'
				}"
				severity="info"
				class="border-primary sm w-auto text-xl m-0 p-0">
				<div class="flex align-items-center">
					<!-- <i class="pi pi-qrcode" style="font-size: 2rem"></i> -->
					<div class="ml-2">
						<!-- {{ $t("booking.paymentMethod.secret2") }} <br/>  -->
						<span class="text-2xl bold">{{ $t("booking.paymentMethod.secret1") }}</span></div>
				</div>
			</InlineMessage>
		</div>
		<div :class="['d-flex flex-row justify-content-between bd-highlight w-full',isMobile?'p-0 m-0 pb-4 mb-2':'']">
			<div class="d-flex flex-row bd-highlight align-items-start w-full m-0" v-if="true">
				<div class="flex-shrink-1">
					<div class="d-flex flex-column-reverse h-100 mr-1 flex-fill">
						<i class="bi bi-cash-stack my-2"></i>
					</div>
				</div>
				<div class="w-100">
					<div class="d-flex flex-column flex-grow-1">
						<InputText 
						id="promo"
						type="text"
						v-model.trim="code"
						:placeholder="$t('booking.personalD.promo')"
						:title="$t('booking.personalD.promo')"
						required
						:style="{
						backgroundColor: 'var(--surface-100)', 
						width:'100% !important',
						border: (errorMessage == null && successMessage == null) ? 'none':'',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap'}"
						:class="{ 'p-invalid': errorMessage, 'border border-success':successMessage, 'bord': (errorMessage == null && successMessage == null) }" 
						aria-describedby="mask"
						/>
						<small class="p-error fs-6" id="mask" v-if="errorMessage">{{ errorMessage || '&nbsp;' }}</small>
						<small class="text-success fs-6" id="mask" v-if="successMessage">{{ successMessage.value || '&nbsp;' }} XAF</small>
						<small class="text-success fs-6" id="mask" v-if="successMessage">{{ successMessage.newcost || '&nbsp;' }} XAF</small>
					</div>
				</div>
				<div class="flex-shrink-1" v-if="!$store.getters.controlButton && code?code !== '':false">
						<div class="d-flex flex-row justify-content-evenly ">
						<Button
							:label="$t('booking.paymentMethod.4')" 
							class="ml-1  w-auto" 
							@click="verifyPmCode()" 
							rounded
							:style="{
							backgroundColor: '#2020d0',
							color: '#ffffff',
							}"
							/>
				</div>
				</div>

				<div class="flex-shrink-1" v-if="$store.getters.controlButton">
						<div class="d-flex flex-row justify-content-evenly ">
							<Button
								:label="$t('booking.paymentMethod.5')" 
								class="mx-1 flex-shrink-1 w-auto" 
								rounded
								@click="usePmCode()"  
								:style="{
								backgroundColor: '#2020d0',
								color: '#ffffff',
								}"
								
								/>
						</div>
				</div>

				<div class="flex-shrink-1" v-if="$store.getters.controlButton">
						<div class="d-flex flex-row justify-content-evenly ">
							<Button
								:label="$t('booking.paymentMethod.6')" 
								rounded
								class="ml-1 flex-shrink-1 w-auto" 
								@click="verifyPmCode_Ignore()" 
								:style="{
								backgroundColor: '#2020d0',
								color: '#ffffff',
								}"
								/>
						</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from "vuex";
	export default {

	  	name: 'PmCode',

	  	data() {
		    return {

		    };
	  	},

		props:{
      		isMobile: Boolean,
		},

	  	computed: {
			...mapGetters({
				reserv: "reserv",
				userlogin: "userlogin",
				lang: "lang",
	      		pmCode: "pmCode",
			}),

			code:{
				get(){
					return this.pmCode.code
				},
				set(value){
					this.update_pm_code({code:value})
					this.pmcodeDialogButton(false)
				}
			},

			errorMessage(){
				return this.$store.getters.icode && this.$store.getters.pmCode.code && !this.$store.getters.pmCode.price ? this.$t("booking.confirmD.Cp3") : null;
			},

			successMessage(){
				return this.$store.getters.vcode && this.$store.getters.pmCode.code && this.$store.getters.pmCode.price ? 
				{
					value: this.$t("booking.confirmD.Cp1")+" "+this.$store.getters.pmCode.price, 
					newcost:this.reserv.usePromo == null || !this.reserv.usePromo?`${this.$t("booking.confirmD.bill")} ${(this.reserv.cost - this.$store.getters.pmCode.price) >= 0 ? (this.reserv.cost - this.$store.getters.pmCode.price): 0}` : `${this.$t("booking.confirmD.bill")} ${this.reserv.cost >= 0 ? this.reserv.cost: 0}`
				}: null;
			},
		},


		mounted() {
			this.autoComplient(this.userlogin);
			if(this.reserv.usePromo){
				 this.checkPromoCode({ code: this.reserv.promo.code })
			 		.then(()=>{
			 			if(this.$store.getters.icode){
			 				this.set_arg_reserv({...this.reserv, promo: null, usePromo: false})
							this.setPrice(this.reserv.cost + this.reserv.promo.price)
			 				this.update_pm_code({})
			 				this.pmcodeDialogButton(false);
			 			}
			 		})
			}
		},


		methods: {
			...mapActions([
				"update_display", 
				"autoComplient",
				"resetFlag",
				"resetShowMap",
				"update_pm_code",
				"checkPromoCode",
				"pmcodeDialogButton",
				"update_pm_code",
				"set_arg_reserv",
				"setPrice"
				]),

			verifyPmCode() {
				if (this.pmCode.code) {
					if(this.userlogin.usedPmCode?.includes(this.pmCode.code)){
			          this.$toast.add({severity:'warn', summary:"Warn Message", detail:"this promo code has already been used", life: 5000});
			        }
			        else{
			          this.checkPromoCode({ code: this.pmCode.code });
			        }
				}
			},
			verifyPmCode_Ignore() {
				if (this.pmCode.code) {
					this.pmcodeDialogButton(false);
					this.update_pm_code({})
				}
				if(this.reserv.usePromo){
					this.setPrice(this.reserv.cost + this.reserv.promo.price)
					this.set_arg_reserv({...this.reserv , promo: null, usePromo: false})
				}
			},
			usePmCode(){
				if(!this.reserv.usePromo)
				{
					this.set_arg_reserv({...this.reserv, promo: this.$store.getters.pmCode, usePromo: true})
					this.setPrice(this.reserv.cost - this.$store.getters.pmCode.price)
				}
			}
		},

	};
</script>

<style lamg="scss" scoped>
.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
    border-color: #183462;
    border-radius: 6px;
}
</style>
