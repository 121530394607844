<template>
	<div class="card border-light bg-transparent m-0 mb-1 p-0">
		<picture v-if="$i18n.locale == 'fr'">
      <source srcset="@/assets/files/brand/direction4.webp" type="image/webp">
      <source srcset="@/assets/files/brand/direction4.avif" type="image/avif">
      <img
        src="@/assets/files/brand/direction4.png"
        class="bottom-0 w-100"
        alt="hero screen"
        style="right: 5%"
      />
    </picture>
    <picture v-else>
      <source srcset="@/assets/files/brand/direction6.webp" type="image/webp">
      <source srcset="@/assets/files/brand/direction6.avif" type="image/avif">
      <img
        src="@/assets/files/brand/direction6.png"
        class="bottom-0 w-100"
        alt="hero screen"
        style="right: 5%"
      />
    </picture>
		<div class="card-body fs-6">
			<h5 class="card-title fw-bold">Protocoles and Prevention</h5>
			<!-- <p class="card-text fw-bold"> {{res.surname}} {{res.name}} <span class="fw-normal">{{$t('ariaLabel.daero.desc6')}}</span>  </p>

			<p class="card-text"><small class="text-muted">{{$t('ariaLabel.daero.desc7')}}</small></p> -->
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {

	  name: 'PrintInfo',

	  data() {
	    return {

	    };
	  },

	  computed: {
        ...mapGetters({
          // res: "reserv",
          // ajustTime : 'ajustTime',
        }),
      }
	};
</script>

<style lang="scss" scoped>
</style>
