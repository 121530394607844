  <template>
  <Dialog v-model:visible="display.signup" :style="{width: 'auto'}" :header="$t('auth.registerC.summary')" position="center" :modal="true" class="p-fluid" @hide="clear()" @show="show()">
  <!--     <template #header>
          <img src="../../assets/files/jpeg/background.jpg" style="height: 15rem" />
      </template>
      <template #title>
          Bongo Ride
      </template>
      <template #subtitle>
          Connexion
      </template> -->
      <form @submit.prevent="accountCreate()">
        <div class="field col-12 mt-4"> 
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <span class="p-float-label">
              <InputText type="text" id="nam" v-model="name" :style="{backgroundColor: 'var(--surface-200)'}" required/>
              <label for="nam" >{{$t("auth.registerC.nam") }}</label>
              
            </span>
          </div>
        </div>
        <div class="field col-12"> 
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <span class="p-float-label">
              <InputText type="text" id="surn" v-model="surname" :style="{backgroundColor: 'var(--surface-200)'}" required/>
              <label for="surn" >{{$t("auth.registerC.surn") }}</label>
              
            </span>
          </div>
        </div>
        <div class="field col-12 my-3"> 
        
        <!-- <div class="p-inputgroup"> -->
          <!-- <span class="p-inputgroup-addon">
            <i class="pi pi-phone"></i>
          </span> -->
          <!-- <span class="p-float-label"> -->
            <!-- <InputText type="tel" id="phone" v-model="phone_number" :style="{backgroundColor: 'var(--surface-200)'}" required/> -->
            <vue3-reactive-tel-input 
              v-model="phone_number"  
              :rootClass="['p-inputgroup w-full']" 
              :divInputClass="['p-float-label w-full']" 
              :rootStyle="{
                border:'none'
              }" 
              :listStyle="{backgroundColor: 'var(--surface-200)'}" 
              :dropdownStyle="{
                borderBottomLeftRadius: '6px',
                borderTopLeftRadius: '6px',
                background: '#e9ecef',
                color: '#6c757d',
                borderLeft: '1px solid #ced4da',
                borderTop: '1px solid #ced4da',
                borderBottom: '1px solid #ced4da',
                paddingTop: '0.80rem',
                paddingRight: '0.00075rem',
                paddingBottom: '0.80rem',
                paddingLeft: '0.00075rem',
                }"
              :inputClass="['p-inputtext p-component']"
              :inputStyle="{
                backgroundColor: 'var(--surface-200)',
                border: '1px solid #ced4da',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                borderBottomLeftRadius: '0',
                borderTopLeftRadius: '0',
                height: '100%',
                width: '100%'
              }"
              :Placeholder="' '"
              :inputId="'phone'" 
              :badInputClass="'border border-danger p-filled'" 
              :goodInputClass="'p-filled'" 
              required 
              >
                <label for="phone" >{{$t("auth.registerC.phone") }}</label>
              </vue3-reactive-tel-input>
            <!-- 
          </span> -->
        <!-- </div> -->
      </div>
      <div class="field col-12"> 
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-at"></i>
          </span>
          <span class="p-float-label">
            <InputText type="email" id="email" v-model="email" :style="{backgroundColor: 'var(--surface-200)'}" required/>
            <label for="email">Email</label>
          </span>
        </div>
      </div>
      <div class="field col-12 "> 
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon" @click="toggle_pwd()">
            <i :class="icon"></i>
          </span>
          <span class="p-float-label">
            <!-- <Password v-model="password" toggleMask="type!='text'">
                <template #header>
                    <h6>Pick a password</h6>
                </template>
                <template #footer>
                    <Divider />
                    <p class="mt-2">Suggestions</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                </template>
            </Password> -->
            <InputText :type="type" id="pass" v-model="password" :class="invalide==false?'':'p-invalid'" @keypress="go()" :style="{backgroundColor: 'var(--surface-200)'}" required/>
            <label for="pass" >{{$t("auth.registerC.pass") }}</label>
            
          </span>
        </div>
      </div>
      <div class="field col-12 "> 
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon" @click="toggle_pwd()">
            <i :class="icon"></i>
          </span>
          <span class="p-float-label">
            <InputText :type="type" id="cpass" v-model="password_confirmation" :class="invalide==false?'':'p-invalid'" @keypress="go()" :style="{backgroundColor: 'var(--surface-200)'}" required/>
            <label for="cpass" >{{$t("auth.registerC.pass") }}</label>
            
          </span>
        </div>
      </div>
      <div class="field col-12 text-center">
        <Button type="submit" :label="$t('auth.registerC.btn')" icon="pi pi-user-plus" v-if="!isworking" class="p-button-rounded bg-blue-900 mr-2 mt-3 mb-2"></Button>

        <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" v-else fill="var(--surface-ground)" animationDuration=".5s"/>
      </div>
      </form>
      <template #footer>
        <div class="mt-0 col-12 text-center">
          <Button class="p-button-link px-0 text-center"   @click="updateR({forgot:true})"  >{{$t("auth.loginC.title1") }}</Button>
        </div>
        <div class="mt-0 col-12 text-center">
          <Button class="p-button-link px-0 text-center"   @click="updateR({signin:true})"  >{{$t("auth.forgotC.title1") }}</Button>
        </div>
      </template>
  </Dialog>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Register',
  beforeUpdate(){
    console.log(this.display)
  },
  data(){
    return {
      invalide: false
    }
  },
  computed:{
    ...mapGetters({
      signup: 'signup',
      type: 'typePInp',
      isworking:'ondoing',
      icon: 'typePIcon',
      islogin: 'islogin',
      userlogin: 'userlogin',
      display: 'display',
      error:'error',
      success:'success', 
      lang: 'lang'
    }),
    email:{
      get(){
        return this.signup.email;
      },
      set(value){
        this.update_signup({...this.signup,email:value});
      }
    },
    name:{
      get(){
        return this.signup.name;
      },
      set(value){
        this.update_signup({...this.signup,name:value});
      }
    },
    surname:{
      get(){
        return this.signup.surname;
      },
      set(value){
        this.update_signup({...this.signup,surname:value});
      }
    },
    phone_number:{
      get(){
        return this.signup.phone;
      },
      set(value){
        this.update_signup({...this.signup,phone_number:(value !== undefined?value.valid:false) ?`(+${value.countryCallingCode}) ${value.formatted}`:"",phoneObj:value, phone: value.formatted});
      }
    },
    password_confirmation:{
      get(){
        return this.signup.password_confirmation;
      },
      set(value){
        this.update_signup({...this.signup,password_confirmation:value});
      }
    },
    password:{
      get(){
        return this.signup.password;
      },
      set(value){
        this.update_signup({...this.signup,password:value});
      }
    }
  },
  methods:{
    ...mapActions(['createAccount','clear_signup','update_signup','update_display','toggle_pwd','update_on_doing']),
    accountCreate(){
      this.update_on_doing()
      // console.log(this.signup)
      this.signup.role="Client"
      if (this.signup.password == this.signup.password_confirmation){
        if (this.signup.phone_number !== "")
        {
          this.update_signup({...this.signup,lang:this.lang});
          this.createAccount(this.signup)
            .then(()=>{
              //this.clear_signup()
              this.update_on_doing()
              this.updateR({signup:false})
              // console.log(this.success)
              this.$i18n.locale == "FR"
              ?(this.$toast.add({severity:'success', summary: this.$t("auth.registerC.summary") , detail:`${this.success}`, life: 5000}))
              :(this.$toast.add({severity:'success', summary: this.$t("auth.registerC.summary"), detail:`${this.success}`, life: 5000}))
              /* this.$router.push({name:'Dashboard'}) */
            })
            .catch((error)=>{
              this.update_on_doing()
              this.$i18n.locale == "FR"
              ?(this.$toast.add({severity:'warn', summary:  this.$t("auth.registerC.summary"), detail:`${error.response.data.message}`, life: 5000}))
              :((this.$toast.add({severity:'warn', summary: this.$t("auth.registerC.summary"), detail:`${error.response.data.message}`, life: 5000})))

            })
          } else {
            this.update_signup({...this.signup,phone: ""});
             this.$i18n.locale == "FR"
              ?(this.$toast.add({severity:'warn', summary:  this.$t("auth.registerC.summary"), detail:`Numero de téléphone invalide`, life: 5000}))
              :((this.$toast.add({severity:'warn', summary: this.$t("auth.registerC.summary"), detail:`Invalid phone number`, life: 5000})))
            this.update_on_doing()
          }
      }else{
        this.update_signup({...this.signup,password: "",password_confirmation: ""});
        this.invalide = true
        this.update_on_doing()
      }
      
    },
    go(){
      this.invalide=false
    },
    updateR(data){
        this.update_display(data)
    },
    clear(){
      this.update_signup({})
      if (this.display.signup){
        this.updateR({signup:false})
      }
    },
    show(){
      this.update_signup({})
    }
  },
  unmounted(){
    this.updateR({signup:false})
  }
}
</script>
