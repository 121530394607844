<template>
  <div id="step1" class="p-0 mt-0" > 
      <div :class="['p-0 m-0 pb-4 mb-2', !isMobile?'border-bottom border-cyan  border-opacity-25':([1,2].includes(step)?'border-bottom border-cyan  border-opacity-25':'d-none')]">
        <div class="d-flex flex-row bd-highlight w-full" v-if="!isMobile ?(true):(step === 1)">
          <div class="flex-shrink-1">
            <div class="d-flex flex-column-reverse h-100 mr-1 flex-fill">
                <i class="bi bi-geo-alt-fill" style="color:#2880CA"></i>
                <i class="bi bi-grip-vertical my-2"></i>
                <i class="bi bi-circle" style="color:#2880CA"></i>
            </div>
          </div>
          <div class="flex-grow-1">
            <div :class="{
                  'd-flex':true,
                  'flex-column-reverse':rideOrientation,
                  'flex-column':!rideOrientation,
                }">
              <div class="d-flex flex-grow-1"> 
                <AutoCompleteB
                      :classes="'m-0 p-0 w-full text-truncate'"
                      :Iclasses="'flex align-options-center text-wrap'"
                      :identifier="'arrival_point'"
                      v-model="currentPlace"
                      :requiredVar="true"
                      :suggestionsVar="filteredPlaces"
                      :completeFn="searchPlace"
                      :placeholder="$t('booking.travelD.autoLabel')"
                      :fieldName="'display_name'"
                      :AriaLabel="'destination'"
                      :inputStyle="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'}"
                      :itemFn="(data)=>{
                        return `<span class='d-flex align-items-center text-truncate text-wrap'> <i class='pi pi-map-marker'  aria-hidden='true' style='color: #0000FF; font-size: 1.2rem'></i>   ${data.display_name}</span>`
                      }"
                      appendToP="#step1"
                    />
              </div>
              <div class="d-flex justify-content-center text-xl flex-shrink-1">
                <span :class="{
                      'd-flex':true,
                      'flex-row-reverse':rideOrientation,
                      'flex-row':!rideOrientation,
                    }">
                  <!-- <i class="bi bi-geo-fill" v-tooltip.bottom="{
                        value: 'Your Location',
                        class: 'custom-error',
                      }"> </i> --> City
                  <i class="bi bi-signpost-fill mx-2" style="color:blue;" v-tooltip.bottom="{
                        value: 'To',
                        class: 'custom-error',
                      }"></i>
                  <!-- <i class="bi bi-airplane-fill mx-2" v-tooltip.bottom="{
                        value: 'Airport of Cameroon (ADC)',
                        class: 'custom-error',
                      }"> </i> --> Airport
                </span>
              </div>
              <div class="d-flex flex-grow-1">
                <DropDownB
                  :classes="'m-0 p-0 w-full text-truncate'"
                  :identifier="'departure1'"
                  v-model="departure_point"
                  :requiredVar="true"
                  :closedVar="true"
                  :optionsVar="airport"
                  :clickFn="TairportRR"
                  :valueFn="(data)=>{return data.address.aeroway;} "
                  :optionFn="(data)=>{return data.address.aeroway;} "
                  :placeholder="$t('ariaLabel.daero.placeholder')"
                  :fieldName="'address.aeroway'"
                  :AriaLabel="$t('ariaLabel.daero.label')"
                  :inputStyle="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'}"
                  :style="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'}"
                  :title="$t('ariaLabel.daero.label')"
                  :appendToP="isMobile?'#app':'#step1'"
                  v-if="rideOrientation"
                />
                <DropDownB
                  :classes="'m-0 p-0 w-full text-truncate'"
                  :identifier="'departure1'"
                  v-model="arrival_point"
                  :requiredVar="true"
                  :closedVar="true"
                  :optionsVar="airport"
                  :clickFn="TairportRR"
                  :valueFn="(data)=>{return data.address.aeroway;} "
                  :optionFn="(data)=>{return data.address.aeroway;} "
                  :placeholder="$t('ariaLabel.daero.placeholder')"
                  :fieldName="'address.aeroway'"
                  :AriaLabel="$t('ariaLabel.daero.label')"
                  :inputStyle="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'}"
                  :style="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'}"
                  :title="$t('ariaLabel.daero.label')"
                  :appendToP="isMobile?'#app':'#step1'"
                  v-else
                />
              </div>
            </div>
          </div>
          <div class="flex-shrink-1">
            <div :class="{
                      'd-flex':true, 
                      'flex-column-reverse':$Place_kits.IsGeoHSON(currentPlace) && !rideOrientation, 
                      'flex-column':$Place_kits.IsGeoHSON(currentPlace) && rideOrientation, 
                      'align-items-between':$Place_kits.IsGeoHSON(currentPlace), 
                      'align-items-center':!$Place_kits.IsGeoHSON(currentPlace), 
                      'h-100':true, 
                      'sm:mr-1':true}">
              <!-- <button class="navbar-toggler me-1 align-self-center" style="border:none;outline: none;" >
                <i class="bi bi-arrow-down-up" style="color: #000000;font-size: 1.7rem; vertical-align: middle;"></i>
              </button> -->
              <Button 
                icon="bi bi-arrow-down-up" 
                aria-label="Order" 
                :class="{
                  'p-button-text p-button-rounded border-none font-light line-height-2 text-dark-900':true, 
                  'mb-auto':$Place_kits.IsGeoHSON(currentPlace) && rideOrientation,
                  'mt-auto':$Place_kits.IsGeoHSON(currentPlace) && !rideOrientation,
                }" 
                style="font-size: 1.4rem;" 
                @click="transform"
                />
                <Button 
                v-if="$Place_kits.IsGeoHSON(currentPlace)"
                :icon="!precision?'bi bi-pin-map-fill':'pi pi-spin pi-map'" 
                aria-label="Order" 
                class="p-button-text p-button-rounded border-none font-light line-height-2 text-dark-900" 
                style="font-size: 1.4rem;" 
                @click="set_precision(!precision);"
                :disabled="checked"
                />
            </div>
          </div> 
        </div>  
        <div :class="['d-flex flex-row bd-highlight w-full', !isMobile ?' mt-3':'']" v-if="!isMobile ?(true):(step === 2)">
          <div class="flex-shrink-1">
            <div class="d-flex flex-column-reverse h-100 mr-1 flex-fill">
                <i class="bi bi-calendar my-2"></i>
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex flex-grow-1">
              <CalendarB 
              :classes="'m-0 p-0 w-full text-truncate'"
              :identifier="'cal2'"
              v-model="pickup_date_D"
              :requiredVar="true"
              :disabledDates="invalidDates"
              :placeholder="$t('ariaLabel.cdate.placeholder')"
              :AriaLabel="$t('ariaLabel.cdate.label')"
              :inputStyle="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: !isMobile ?'98%':'100%'
                      }"
              :refI="'ca'"
              :dateFormat="'yy-mm-dd'"
              :showTime="true"
              :showSeconds="false"
              :showIcon="false"
              :minDate="minDate"
              :manualInput="false"
              :hideFn="hide"
              :BtnAriaLabel="$t('ariaLabel.calendar.Valid')"
              :Btnclasses="'p-button border-none'"
              :BtnDivclasses="'d-flex justify-content-end'"
              :appendToP="isMobile?'#app':'#step1'"

            />
            </div>
          </div>
          <div class="flex-shrink-1" v-if="!isMobile" >
            <div class="d-flex align-items-center h-100 sm:mr-1">
              <Button 
                icon="bi bi-arrow-down-up" 
                aria-label="Order" 
                class="p-button-text p-button-rounded border-none font-white line-height-2 text-white-900 invisible" 
                style="font-size: 1.4rem;" 
                disabled
                />
            </div>
          </div> 
        </div>
        <div :class="['d-flex flex-row bd-highlight w-full', !isMobile ?' mt-3':'']" v-if="!isMobile ?true:(step === 2)">
          <div class="flex-shrink-1">
            <div class="d-flex flex-column-reverse h-100 mr-1 flex-fill">
                <i class="bi bi-airplane-fill my-2"></i>
            </div>
          </div>
          <div class="w-100">
            <div class="d-flex flex-grow-1">
              <DropDownB
                  :classes="'m-0 p-0 w-full text-truncate'"
                  :identifier="'flightC'"
                  v-model="flight_company"
                  :requiredVar="true"
                  :closedVar="true"
                  :optionsVar="Company_name"
                  :clickFn="CompanyRR"
                  :valueFn="(data)=>{return data.code + ' (' + data.name + ') ';} "
                  :optionFn="(data)=>{return data.code + ' (' + data.name + ') ';} "
                  :placeholder="$t('ariaLabel.comp.placeholder')"
                  :fieldName="'name'"
                  :AriaLabel="$t('ariaLabel.comp.label')"
                  :inputStyle="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '287px'
                      }"
                  :style="{
                        backgroundColor: 'var(--surface-100)', 
                        width:'100% !important',
                        border:'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }"
                  :appendToP="isMobile?'#app':'#step1'"
                  :title="$t('ariaLabel.comp.label')"
                />
            </div>
          </div>
          <div class="flex-shrink-1" v-if="!isMobile" >
            <div class="d-flex align-items-center h-100 sm:mr-1">
              <Button 
                icon="bi bi-arrow-down-up" 
                aria-label="Order" 
                class="p-button-text p-button-rounded border-none font-white line-height-2 text-white-900 invisible" 
                style="font-size: 1.4rem;" 
                disabled
                />
            </div>
          </div> 
        </div>
        <div class="d-flex flex-column justify-content-center bd-highlight w-full mt-2" v-if="!isMobile">
          <div class="w-100 m-auto h-2 justify-content-center">
            <ProgressBar :value="fields > 0? fields*100/4 : 1" class="text-truncate">  </ProgressBar>
          </div>
            <small class="align-self-center fs-6 fw-semibold text-muted">{{ fields }} {{ $t("booking.TripDetais.progressBar.val1") }} {{ 4 }} {{ $t("booking.TripDetais.progressBar.val2") }} </small>
        </div>
        <div class="d-flex flex-column justify-content-center bd-highlight w-full mt-5" v-if="!isMobile ?(!checked && isworking && fields == 4):(fields == 4 && false)">
            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
              animationDuration=".5s" aria-label="Driver avaibility checking" />
            <small class="align-self-center fs-6 fw-bold text-muted">{{ fields }} {{ $t("booking.TripDetais.progressBar.val1") }} {{ 4 }} {{ $t("booking.TripDetais.progressBar.val2") }} </small>
            <small class="align-self-center fs-6 fw-semibold text-muted text-break">{{ $t("booking.TripDetais.progressBar.val3") }}</small>
        </div>
        <div class="d-flex flex-column justify-content-center bd-highlight w-full mt-5" v-if="!isMobile ?(!checked && !isworking && fields == 4):(fields == 4 && false)">
            <i style="font-size: 2rem" class="bi bi-taxi-front-fill align-self-center text-danger">  </i>
            <small class="align-self-center fs-6 fw-bold text-muted">{{ fields }} {{ $t("booking.TripDetais.progressBar.val1") }} {{ 4 }} {{ $t("booking.TripDetais.progressBar.val2") }} </small>
            <small class="align-self-center fs-6 fw-semibold text-muted text-break">{{ $t("booking.TripDetais.progressBar.val4") }}</small>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center bd-highlight w-full mt-2 border-bottom border-cyan p-2 m-0 pb-4 mb-2 border-opacity-25  fs-6" v-if="!isMobile?(fields == 4 && checked && coordinates.length != 0):false">
          <div class="w-100 m-auto d-flex ">
            <span class="fw-bold mr-1 text-muted"><span class="bi bi-alarm-fill"></span> Estimate duration :</span> 
            <span>{{ minutes }} Min</span>  
          </div>
          <div class="w-100 m-auto d-flex">
            <span class="fw-bold mr-1 text-muted"><span class="bi bi-rulers"></span> Estimate Distance :</span> 
            <span>{{ distance}} Km</span> 
          </div>
          <div class="w-100 m-auto d-flex">
            <span class="fw-bold mr-1 text-muted"><span class="bi bi-cash-coin"></span> Amount :</span>
            <span>{{ amount }} XAF</span> 
          </div>
      </div>
      <PmCodeB v-if="!isMobile ?true:(step === 3)"/>
      <div class="d-flex flex-row justify-content-end bd-highlight w-full border-bottom border-cyan p-0 m-0 border-opacity-25" v-if="!isMobile ?(fields == 4 && checked):(false && fields == 4 && checked)">
          <Button v-if="!islogin" label="Login to complete" class="mb-2 w-auto" @click="update_display({ signin: true})" 
            :style="{
                    'min-width': '45%',
                    backgroundColor: '#2020d0',
                    color: '#ffffff',
                  }"
            />
          <Button v-else :label="$t('landing.booking.remote.bookB')" class="mb-2  w-auto" @click="$router.push({path:'/identity'})" 
            :style="{
                    'min-width': '45%',
                    backgroundColor: '#2020d0',
                    color: '#ffffff',
                  }"
            />
      </div>
      <div id="map" class="w-full h-full map d-none"></div>
      <LRoutingMachine
          v-if="checker"
          :mapObject="mapObject"
          :waypoints="waypoints"
          :customControl="customControl"
      />
      <div 
        aria-label="Page navigation"
        v-if="isMobile"
        class="pagination d-flex flex-row justify-content-evenly w-full mb-1 m-0 p-0"
        :style="[isMobile?'position:absolute;':'']"
        >
          <Button 
            label="Back" 
            @click="step-=1" 
            rounded 
            :class="['mb-1 w-auto', step < 2?'disabled':'']" 
            :disabled="step < 2" v-if="step > 1"
              :style="{
                  'min-width': '45%',
                  backgroundColor: '#2020d0',
                  color: '#ffffff',
                }" 
            />
          <Button 
            label="Next" 
            @click="step+=1" 
            rounded 
            :class="['mb-1 w-auto', getFullFilState <= step?'disabled':'']" 
            :disabled="getFullFilState <= step" v-if="step < TOTAL_STEPS"
              :style="{
                  'min-width': '45%',
                  backgroundColor: '#2020d0',
                  color: '#ffffff',
                }" 
            />

          <Button 
            v-if="!isMobile ?(fields == 4 && checked ? !islogin:false):(step === 3 && fields == 4 && checked ? !islogin : false)" 
            :label="$t('LoginBooking')" 
            class="mb-1 w-auto" 
            @click="update_display({ signin: true})" 
            rounded 
            :style="{
                'min-width': '45%',
                backgroundColor: '#2020d0',
                color: '#ffffff',
              }"
          />
          <Button 
            v-if="!isMobile ?(fields == 4 && checked ? islogin:false):(step === 3 && fields == 4 && checked ? islogin : false)" 
            :label="$t('landing.booking.remote.bookB')" 
            class="mb-1  w-auto" 
            @click="$router.push({path:'/identity'})" 
            rounded 
            :style="{
                  'min-width': '45%',
                  backgroundColor: '#2020d0',
                  color: '#ffffff',
                }"
            />
      </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";	
  import LRoutingMachine from "@/services/LRoutingMachine.vue";
  import "leaflet/dist/leaflet.css";
  import _ from "lodash";
  export default{
		name:"TripDetails",
    inject: ["mq"],
		props:{
      isMobile: Boolean,
		},
    components:{
      LRoutingMachine:LRoutingMachine,
    },
    computed: {
        ...mapGetters({
          res: "reserv",
          islogin: "islogin",
          checked: "checked",
          display: 'display',
          coordinates: "coordinates",
          lang: "lang",
          showMap: "showMap",
          airports: "airports",
          Company_name: "Company_name",
          isworking:'ondoing',
          confirm : "confirm",
          rideOrientation: "rideOrientation",
          ajustTime : 'ajustTime',
          currentUPlace:"currentUPlace"
        }),
        getFullFilState(){
          console.log(this.$Place_kits.IsGeoHSON(this.res.departure_point) , this.$Place_kits.IsGeoHSON(this.res.arrival_point))
          let flag = 1;
          if (this.$Place_kits.IsGeoHSON(this.res.departure_point) && this.$Place_kits.IsGeoHSON(this.res.arrival_point)){
            flag = 2
          }
          if (this.fields === 4){
            flag = 3
          }
          return flag
        },
        airport(){
          let dat = this.airports;
          // console.log(!this.isMobile,!this.$Place_kits.IsGeoHSON(this.res.departure_point), this.$Place_kits.IsGeoHSON(this.res.arrival_point),  this.step === 1)
          if (!this.rideOrientation){
            dat = dat.filter(el=>this.$Place_kits.SameState(el,this.res.departure_point))
          }
          return dat
        },
        currentPlace:{
          get(){
            return this.currentUPlace;
          },
          set(value){
            this.updateCurrentPlace(value)
          }
        },
        precision(){
            return this.$store.getters.precision;
        },
        fields(){
          let count = 0

          if(this.rideOrientation){
            if(this.$Place_kits.IsGeoHSON(this.currentPlace)){
              // arrival poit is filled ?
              this.$Place_kits.IsGeoHSON(this.res.arrival_point)? count++:null
            }
            // departure point is filled ?
            this.$Place_kits.IsGeoHSON(this.res.departure_point)? count++:null
          }else{
            if(this.$Place_kits.IsGeoHSON(this.currentPlace)){
              // departure point is filled ?
              this.$Place_kits.IsGeoHSON(this.res.departure_point)? count++:null
            }
            // arrival poit is filled ?
            this.$Place_kits.IsGeoHSON(this.res.arrival_point)? count++:null
          }
          
          // the date data is filled ?
          this.pickup_date_D instanceof Date?count++:null
          // the airport company is filled ??
          if(typeof this.res.flight_company === "object" && this.res.flight_company !== null ? Object.keys(this.res.flight_company).length != 0 : false) 
            if(this.res.flight_company.name)
              count++

          return count
        },
        flight_company:{
          get(){
            return this.res.flight_company
          },
          set(value){
            // console.log(value)
            this.set_arg_reserv({...this.res, flight_company:value})
             // si tous les champs sont rrempli, cherche la disponibilité d'un taxi d'aéroport
            if(this.fields == 4){
              this.set_arg_reserv({...this.res, ADC2CITY:this.rideOrientation})
              this.IsThereAvailableDrivers()
            }
            else{
              this.updateRequestFlag(false)
            }
          }
        },
        departure_point:{
          get(){
            return this.res.departure_point
          },
          set(value){
            // console.log(value)
            this.set_arg_reserv({...this.res, departure_point:value})
             // si tous les champs sont rrempli, cherche la disponibilité d'un taxi d'aéroport
            if(this.fields == 4){
              this.set_arg_reserv({...this.res, ADC2CITY:this.rideOrientation})
              this.IsThereAvailableDrivers()
            }
            else{
              this.updateRequestFlag(false)
            }
          }
        },
        arrival_point:{
          get(){
            return this.res.arrival_point
          },
          set(value){
            // console.log(value)
            this.set_arg_reserv({...this.res, arrival_point:value})
             // si tous les champs sont rrempli, cherche la disponibilité d'un taxi d'aéroport
            if(this.fields == 4){
              this.set_arg_reserv({...this.res, ADC2CITY:this.rideOrientation})
              this.IsThereAvailableDrivers()
            }
            else{
              this.updateRequestFlag(false)
            }
          }
        },
        pickup_date_D:{
          get(){
            return new Date(this.res.pickup_date_D)
          },
          set(value){
            this.set_arg_reserv({...this.res, pickup_date_D:value})
            // si tous les champs sont rrempli, cherche la disponibilité d'un taxi d'aéroport
            if(this.fields == 4){
              this.set_arg_reserv({...this.res, ADC2CITY:this.rideOrientation})
              this.IsThereAvailableDrivers()
            }
            else{
              this.updateRequestFlag(false)
            }
          }
        },
        distance(){
          return this.$filters.roundToTwo(this.$Place_kits.convertToKilometer(this.ajustTime.totalDistance,0))
        },
        minutes(){
          // 0.65 km/h is the vitesse fixed
          return Math.round(this.$filters.roundToTwo(this.$Place_kits.getEstimationTime(0.65,this.distance)))
        },
        amount(){
          return this.$Place_kits.computedTravelCost(this.res.departure_point)
        },
        waypoints() {
            return this.checker?[
            {
              lat: this.res.departure_point.lat,
              lng: this.res.departure_point.lon,
            },
            { lat: this.res.arrival_point.lat, lng: this.res.arrival_point.lon },
          ]:[];
        },
        checker(){
          return (this.$Place_kits.IsGeoHSON(this.res.departure_point)) && (this.$Place_kits.IsGeoHSON(this.res.arrival_point)) && this.checked
        }
    },
    watch: {
      currentPlace() {
        if (this.$Place_kits.IsGeoHSON(this.currentPlace)) {
            if(( !_.isEqual(this.currentPlace, this.res.departure_point) && this.rideOrientation) || (!_.isEqual(this.currentPlace, this.res.arrival_point) && !this.rideOrientation))
            {
              // this.center = [this.currentPlace.lat, this.currentPlace.lon];
              // console.log("---- I ----")
              // this.res.arrival_point = this.currentPlace;
              console.log("------<HO>-----")
              this.rideOrientation?this.set_arg_reserv({...this.res, arrival_point:this.currentPlace}):this.set_arg_reserv({...this.res, departure_point:this.currentPlace})
              // localStorage.setItem('arrivalP', JSON.stringify(this.res.arrival_point))
              if (this.$Place_kits.IsGeoHSON(this.currentPlace)) {
                this.currentPlaceP = JSON.parse(JSON.stringify(this.currentPlace));
                // this.engine.setWaypoints(this.waypoints);
              }
              // si tous les champs sont rrempli, cherche la disponibilité d'un taxi d'aéroport
            }
        }
        else
        {
          this.resetFlag(false);
        }
      },
      'res.arrival_point'() {
        this.$Place_kits.IsGeoHSON(this.res.arrival_point) && this.rideOrientation?this.updateCurrentPlace(this.res.arrival_point):null;
        
        if(this.res.arrival_point === null){
          this.updateCurrentPlace(null)
          this.currentPlaceP = null
        }
      },

    },
    methods: {
        // calling the functions from store
        ...mapActions([
            "availabilityCheckerRequest",
            "setResDate",
            "autocomplete_client_data",
            "resetbutton",
            "setPrice",
            "set_arg_reserv",
            "set_precision",
            "get_Companies",
            "resetFlag",
            "get_AirportsparamIR",
            "resetShowMap",
            "updateRequestFlag",
            "updateRideOrientation",
            "updateCurrentPlace",
            "update_on_doing",
            "update_display"
        ]),
        transform(){
          let depart = this.res.departure_point,
            endpoint = this.res.arrival_point

          // console.log({  ...this.res, arrival_point:depart, departure_point:endpoint})
          this.set_arg_reserv({...this.res, arrival_point:depart, departure_point:endpoint})
          // this.rideOrientation = !this.rideOrientation
          this.updateRideOrientation(!this.rideOrientation)
          if(this.fields == 4){
            this.set_arg_reserv({...this.res, ADC2CITY:this.rideOrientation})
          }

        },
        async IsThereAvailableDrivers(){
        //   if (data.mode == 'n') {
        //     data.pickup_date_D = new Date();
        // }
        // commit("UPDATE_RESERVATION_HOUR");
          this.update_on_doing()
          const result = await this.$Booking_kits.availabilityCheckerRequest( this.res )
          this.update_on_doing()
          this.availabilityCheckerRequest(result)

          if (!this.checked) {
            this.$toast.add({
                severity: "warn",
                detail: this.$t("layout.checkDriverA"),
                life: 5000,
              });
          } else {
            this.setPrice(parseInt(this.amount));
            // if (this.res.departure_point.address.state == "Centre") {
            //   this.update_YaoundeTaxiFlag(true);
            // } else if (this.res.departure_point.address.state == "Littoral") {
            //   this.update_DoualaTaxiFlag(true);
            // } else if (this.res.departure_point.address.state == "North") {
            //   this.update_GarouaTaxiFlag(true);
            // } 
            this.$toast.add({
                severity: "info",
                detail: this.$t("layout.checkDriverD"),
                life: 5000,
              })
              
          }
        },
        TairportRR() {
          this.get_AirportsparamIR();
          if (this.res.departure_point == null) {
            this.resetFlag(false);
            this.flag = false;
            this.counter = 0;
            this.resetShowMap(false);
          }
        },
        CompanyRR() {
          this.get_Companies();
        },
        async searchPlace(event) {
          let place = this.rideOrientation?this.res.departure_point:this.res.arrival_point
          this.filteredPlaces = await this.$Place_kits.searchPlace(
                                            event,
                                            this.checked, 
                                            place, 
                                            this.$Place_kits.IsGeoHSON(place), 
                                            this.currentPlace)
          // console.log(this.filteredPlaces)
        },
        hide(that) {
          that.$refs.ca.overlayVisible = false;
        },
    },
    data(){
      return {
        // airport2City: true,
        minDate: null,
        invalidDates: null,
        filteredPlaces: [],
        currentPlaceP: null,
        customControl:[],
        mapObject:null,
        step: 1,
        TOTAL_STEPS: 3,
      }
    },
    created() {
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      let prevMonth = month === 0 ? 11 : month - 1;
      let prevYear = prevMonth === 11 ? year - 1 : year;
      this.minDate = new Date();
      this.minDate.setMonth(prevMonth);
      this.minDate.setFullYear(prevYear);
      this.minDate.setDate(today.getDate());
      this.invalidDates = [];
      for (let i = 1; i < today.getDate(); i++) {
        let invalidDate = new Date();
        invalidDate.setDate(i);
        this.invalidDates.push(invalidDate);
      }

      this.$Place_kits.IsGeoHSON(this.res.arrival_point) && this.rideOrientation?this.updateCurrentPlace(this.res.arrival_point):null;
      this.$Place_kits.IsGeoHSON(this.res.departure_point) && !this.rideOrientation?this.updateCurrentPlace(this.res.departure_point):null;
    },
    mounted(){
        this.mapObject = L.map("map", {
              zoom: 5,
              center: this.center,
            });
    },
	}
</script>

<style lamg="scss" scoped>
.p-autocomplete:not(.p-disabled).p-focus,
.p-calendar.p-inputwrapper-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
    border-color: #183462;
    border-radius: 6px;
}

</style> 