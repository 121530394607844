const state = {
    user: {
        role: ""
    }
}

const getters = {
    user: state => state.user
}

const mutations = {
    UPDATE_USER(state, payload) {
        state.user = payload
    }
}

const actions = {
    update_user({ commit }, data) {
        commit("UPDATE_USER", data)
    }
}

const userModule = {
    state,
    mutations,
    actions,
    getters
}

export default userModule;