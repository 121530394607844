<template>
  <a target="_blank" :href="link" :class="['flex flex-column bg-transparent justify-content-center align-items-center',classes]" :style="{'width':`${servS}px`, 'height': `${servS}px`}">
    <img :src="srce" :alt="name" :height="imgS" :width="imgS" class="image" :style="{'border-radius': `${rounder}px`}" >
    <span class="text-dark">{{name}}</span>
  </a>
</template>

<script>
export default {

  name: 'cardService',

  props:{
    url:{
      type:String
    },
    shortName:{
      type:String
    },
    image:{
      type:String
    },
    imageSize:{
      type:Number
    },
    serviceSize:{
      type:Number
    },
    radius:{
      type:Number
    },
    Servclass:{
      type: String,
      default: ''
    }
  },

  data() {
    return {

    };
  },

  computed:{
    link(){
      return this.url
    },
    srce(){
      return this.image
    },
    imgS(){
      return this.imageSize
    },
    servS(){
      return this.serviceSize
    },
    classes(){
      return this.Servclass
    },
    rounder(){
      return this.radius
    },
    name(){
      return this.shortName
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
