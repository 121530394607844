/* eslint-disable */

/**
 * COMPONENTS IMPORTATION
 * 
 */
import { defineAsyncComponent } from 'vue';
import PreLoad from '@/components/PreLoad.vue'

// const AutoCompleteB = defineAsyncComponent({
//   loader: () => import('@/v2/components/AutoComplete'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });

// const DropDownB = defineAsyncComponent({
//   loader: () => import('@/v2/components/DropDown'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const CalendarB = defineAsyncComponent({
//   loader: () => import('@/v2/components/CalendarB'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const ResumeDetails = defineAsyncComponent({
//   loader: () => import('@/v2/components/ResumeDetails'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const PrintInfo = defineAsyncComponent({
//   loader: () => import('@/v2/components/PrintInfo'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const PmCode = defineAsyncComponent({
//   loader: () => import('@/v2/components/PmCode'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const userAvatar = defineAsyncComponent({
//   loader: () => import('@/v2/components/userAvatar'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const bongoServices = defineAsyncComponent({
//   loader: () => import('@/v2/components/bongoServices'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
const modalAccount = defineAsyncComponent({
  loader: () => import('@/v2/components/modalAccount'),
  loadingComponent: PreLoad /* shows while loading */,
  delay: 0 /* delay in ms before showing loading component */,
});

import AutoCompleteB from '@/v2/components/AutoComplete'
import DropDownB from '@/v2/components/DropDown'
import CalendarB from '@/v2/components/CalendarB'
import ResumeDetails from '@/v2/components/ResumeDetails'
import PrintInfo from '@/v2/components/PrintInfo'
import PmCode from '@/v2/components/PmCode'
import userAvatar from '@/v2/components/userAvatar'
import bongoServices from '@/v2/components/bongoServices'
// import modalAccount from '@/v2/components/modalAccount'
import cardService from '@/v2/components/cardService'
import cardServiceGroup from '@/v2/components/cardServiceGroup'

// const MapComp = defineAsyncComponent({
//   loader: () => import('@/components/refactoring/booking/map/MapComp'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const LoginComponent = defineAsyncComponent({
//   loader: () => import('@/components/auth/LoginComponent'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const RegisterComponent = defineAsyncComponent({
//   loader: () => import('@/components/auth/RegisterComponent'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const ForgotComponent = defineAsyncComponent({
//   loader: () => import('@/components/auth/ForgotComponent'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const ReservHistory = defineAsyncComponent({
//   loader: () => import('@/components/reservation/history/ReservHistory'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const ProfileComponent = defineAsyncComponent({
//   loader: () => import('@/components/managments/account/ProfileComponent'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });
// const SelectLang = defineAsyncComponent({
//   loader: () => import('@/components/layout/LangComponent'),
//   loadingComponent: PreLoad /* shows while loading */,
//   delay: 0 /* delay in ms before showing loading component */,
// });

import MapComp from "@/components/refactoring/booking/map/MapComp"
import LoginComponent from "@/components/auth/LoginComponent"
import RegisterComponent from "@/components/auth/RegisterComponent"
import ForgotComponent from "@/components/auth/ForgotComponent"
import ReservHistory from "@/components/reservation/history/ReservHistory"
import ProfileComponent from "@/components/managments/account/ProfileComponent"
import SelectLang from "@/components/layout/LangComponent";

// export function define app used 
 function CustomComponents(app) {
    /**
     * Define each component as a global app component
     * 
     */

    app.component('AutoCompleteB', AutoCompleteB);
    app.component('DropDownB', DropDownB);
    app.component('CalendarB', CalendarB);
    app.component('MapComp', MapComp);
    app.component('LoginB', LoginComponent);
    app.component('RegisterB', RegisterComponent);
    app.component('ForgotB', ForgotComponent);
    app.component('ResumeB', ResumeDetails);
    app.component('PrintB', PrintInfo);
    app.component('PmCodeB', PmCode);
    app.component('userAvatarB', userAvatar);
    app.component('accountB', modalAccount);
    app.component('HistoryB', ReservHistory);
    app.component('ProfilB', ProfileComponent);
    app.component('LanguageB', SelectLang);
    app.component('serviceB', bongoServices);
    app.component('serviceCardB', cardService);
    app.component('serviceGroupB', cardServiceGroup);
};

export default CustomComponents;