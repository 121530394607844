<template>
  <span></span>
</template>
<script>
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import L from "leaflet";
import { IRouter, IGeocoder, LineOptions } from "leaflet-routing-machine";
import { mapActions } from "vuex";
import url from "@/assets/files/precision1.png";

const props = {
  mapObject: {
    type: Object,
  },
  customControl:{
    type: Object,
  },
  visible: {
    type: Boolean,
    default: true,
  },
  waypoints: {
    type: Array,
    required: true,
  },
  router: {
    type: IRouter,
  },
  plan: {
    type: L.Routing.Plan,
  },
  geocoder: {
    type: IGeocoder,
  },
  fitSelectedRoutes: {
    type: [String, Boolean],
    default: "smart",
  },
  lineOptions: {
    type: LineOptions,
  },
  routeLine: {
    type: Function,
  },
  autoRoute: {
    type: Boolean,
    default: true,
  },
  routeWhileDragging: {
    type: Boolean,
    default: false,
  },
  routeDragInterval: {
    type: Number,
    default: 500,
  },
  waypointMode: {
    type: String,
    default: "connect",
  },
  useZoomParameter: {
    type: Boolean,
    default: false,
  },
  showAlternatives: {
    type: Boolean,
    default: false,
  },
  altLineOptions: {
    type: LineOptions,
  },
};

export default {
  props,
  name: "LRoutingMachine",
  data() {
    return {
      url:url,
      ready: false,
      map: null,
      layer: null,
      cord: [],
    };
  },
  watch: {
    async mapObject() {
      if (this.mapObject == null) {
        return;
      }
      await this.add();
    },
    async waypoints() {
      if (this.waypoints == null) {
        return;
      }
     await this.add();
    },
  },
  async mounted() {
    await this.add();
  },
  beforeUnmount() {
    return this.layer ? this.layer.remove() : null;
  },
  methods: {
    ...mapActions([
      "updatecoord",
      "get_time"
    ]),
   async add() {
      if (this.mapObject == null) {
        return;
      }

      const {
        waypoints,
        fitSelectedRoutes,
        autoRoute,
        routeWhileDragging,
        routeDragInterval,
        waypointMode,
        useZoomParameter,
        showAlternatives,
      } = this;

      const options = {
        waypoints,
        fitSelectedRoutes,
        autoRoute,
        routeWhileDragging,
        routeDragInterval,
        waypointMode,
        useZoomParameter,
        showAlternatives,
        createMarker: function (i, start, n){
          var marker_icon = null;
          var DiconOptions = {
            iconUrl: '/index.png',
            iconSize: [20, 35]
            }
          var SiconOptions = {
            iconUrl: url,
            iconSize: [150, 100]
            }
            // Creating a custom icon
          var endIcon = L.icon(DiconOptions);
          var startIcon = L.icon(SiconOptions);
          var myPopup = "";
          if (i == 0) {
              // This is the first marker, indicating start
              marker_icon = startIcon
              myPopup = "Driver"
          } else if (i == n -1) {
              //This is the last marker indicating destination
              marker_icon = endIcon
              myPopup = "EndPoint"
          }
          var marker = L.marker (start.latLng, {
                      draggable: false,
                      bounceOnAdd: false,
                      bounceOnAddOptions: {
                          duration: 1000,
                          height: 800, 
                          function(){
                              (bindPopup(myPopup).openOn(map))
                          }
                      },
                      icon: marker_icon
          })
          return marker
        },
        collapsible : true,
        show: false
      };
      // this.mapObject.setView(this.waypoints[0], 9);
      const routingLayer = L.Routing.control(
        options
        );
      console.log(routingLayer)
      this.customControl.forEach(ctr=>this.mapObject.removeControl(ctr))
      
      routingLayer.addTo(this.mapObject);
      console.log(this.mapObject)
      this.layer = routingLayer;
      this.mapObject.setView(this.waypoints[0], 9);
      // console.log(routingLayer);
      var UC = this.updateC
      routingLayer.on("routeselected", function (e) {
        var coorde = e.route.coordinates;
        var instr = e.route.instructions;
        var summ = e.route.summary;
        console.log(summ);
        // console.log(instr);
        UC(coorde,summ);
        // console.log(L.geoJson(getInstrGeoJson(instr, coord)));
      },false);
      this.ready = true;
      this.customControl.push(routingLayer);
    },
    updateC(params,summary) {
      console.log("dsbdbjs ")
      this.cord.length = 0;
      params.forEach((el) => {
        this.cord.push([el.lat, el.lng]);
      });
      this.updatecoord(this.cord);
      this.get_time({...summary, totalTimeF:this.$filters.roundToTwo(Math.floor(summary.totalTime / 60))})
    },
    getInstrGeoJson(instr, coord) {
      var formatter = new L.Routing.Formatter();
      var instrPts = {
        type: "FeatureCollection",
        features: [],
      };
      for (var i = 0; i < instr.length; ++i) {
        var g = {
          type: "Point",
          coordinates: [coord[instr[i].index].lng, coord[instr[i].index].lat],
        };
        var p = {
          instruction: formatter.formatInstruction(instr[i]),
        };
        instrPts.features.push({
          geometry: g,
          type: "Feature",
          properties: p,
        });
      }
      return instrPts;
    },
  },
};
</script>