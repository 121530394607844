<template>
  <div class="container-xl p-0 m-0 mt-4">
    <div class="row">
      <div class="col-xl-4 col-lg-4">
        <!-- Profile picture card-->
        <div class="card mb-4 mb-xl-0">
          <div class="card-body text-center">
            <!-- Profile picture image-->
            <AvatarInput v-model="form.avatar" :src="srce" class="rounded-circle" alt="Cinque Terre" ></AvatarInput>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-8">
        <!-- Account details card-->
        <div class="card mb-4 p-0">
          <div class="card-header">
            {{ $t("booking.AccountDetails.title") }}
          </div>
          <div class="card-body">
            <div class="flex justify-content-end">
              <Button
                :label="$t('booking.AccountDetails.btn')"
                id="button"
                class="field col-2 lg:col-6 md-6 d-none d-md-block"
                v-if="inputStatus"
                @click.prevent="modifieInputText"
                :style="{
                      backgroundColor: '#2020d0',
                      color: '#ffffff',
                    }"
              />
              <Button
                icon="pi pi-pencil"
                id="button"
                class="field col-2 lg:col-6 md-6 d-block d-md-none"
                v-if="inputStatus"
                @click.prevent="modifieInputText"
                :style="{
                      backgroundColor: '#2020d0',
                      color: '#ffffff',
                    }"
              />
              <Button
                label="Terminer"
                id="button"
                class="field col-2 lg:col-6 md-6 d-none d-md-block"
                v-if="!inputStatus"
                @click.prevent="saveChanges"
                :style="{
                      backgroundColor: '#2020d0',
                      color: '#ffffff',
                    }"
              />
              <Button
                icon="pi pi-check"
                id="button"
                class="field col-2 lg:col-6 md-6 d-block d-md-none"
                v-if="!inputStatus"
                @click.prevent="saveChanges"
                :style="{
                      backgroundColor: '#2020d0',
                      color: '#ffffff',
                    }"
              />
            </div>

            <div class="p-fluid form fluid fluid col-12 mt-1">
              <span class="field col-6 lg:col-6 md-6">
                <label for="name" class="mb-1">{{ $t("userspace.profil.name") }}</label>
                <InputText
                  id="name"
                  v-model.trim="client.name"
                  required="true"
                  :disabled="inputStatus"
                />
              </span>
              <span class="field col-6 lg:col-6 md-6">
                <label class="mb-1" for="surname">{{
                  $t("userspace.profil.surname")
                }}</label>
                <InputText
                  id="surname"
                  v-model.trim="client.surname"
                  required="true"
                  :disabled="inputStatus"
                />
              </span>
            </div>
            <div class="p-fluid formfluid fluid col-12">
              <span class="field col-6 lg:col-6 md-6">
                <label class="mb-1" for="name">{{
                  $t("booking.bookingHistory.PhoneNumber")
                }}</label>
                <InputText
                  id="name"
                  v-model.trim="client.phone_number"
                  required="true"
                  :disabled="inputStatus"
                />
              </span>
              <span class="field col-6 lg:col-6 md-6">
                <label class="mb-1" for="country">{{
                  $t("booking.AccountDetails.country")
                }}</label>
                <Dropdown
                  v-model="country"
                  :options="countries"
                  :aria-label="$t('booking.AccountDetails.pH')"
                  :title="$t('booking.AccountDetails.pH')"
                  optionLabel="name"
                  :filter="true"
                  :placeholder="$t('booking.AccountDetails.pH')"
                  required="true"
                  :disabled="inputStatus"
                  :showClear="true"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value grid"
                      v-if="slotProps.value"
                    >
                      <img
                        src="@/assets/demo/flags/flag_placeholder.png"
                        :class="'mr-2 flag flag-' + slotProps.value.code.toLowerCase()" />
                      <div>{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item grid">
                      <img
                        src="@/assets/demo/flags/flag_placeholder.png"
                        :class="'mr-2 flag flag-' +slotProps.option.code.toLowerCase()"
                      />
                      <span>{{ slotProps.option.name }}</span>
                    </div>
                  </template>
                </Dropdown>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CreateR from './CreateReservDialog.vue'
// import jwtDecode from 'jwt-decode'

import { mapGetters, mapActions } from "vuex";
import AvatarInput from './AvatarInput.vue';

export default {
  name: "profileC",
  components: {
    // CreateR,
    // ProfileAvatar,
    AvatarInput,
  },
  computed: {
    ...mapGetters({
      client: "client",
    })
  },
  data() {
    return {
      form: {
        avatar: null
      },
      inputStatus: true,
      country: null,
      countries: [
        { name: "Afghanistan", code: "AF" },
        { name: "Åland Islands", code: "AX" },
        { name: "Albania", code: "AL" },
        { name: "Algeria", code: "DZ" },
        { name: "American Samoa", code: "AS" },
        { name: "AndorrA", code: "AD" },
        { name: "Angola", code: "AO" },
        { name: "Anguilla", code: "AI" },
        { name: "Antarctica", code: "AQ" },
        { name: "Antigua and Barbuda", code: "AG" },
        { name: "Argentina", code: "AR" },
        { name: "Armenia", code: "AM" },
        { name: "Aruba", code: "AW" },
        { name: "Australia", code: "AU" },
        { name: "Austria", code: "AT" },
        { name: "Azerbaijan", code: "AZ" },
        { name: "Bahamas", code: "BS" },
        { name: "Bahrain", code: "BH" },
        { name: "Bangladesh", code: "BD" },
        { name: "Barbados", code: "BB" },
        { name: "Belarus", code: "BY" },
        { name: "Belgium", code: "BE" },
        { name: "Belize", code: "BZ" },
        { name: "Benin", code: "BJ" },
        { name: "Bermuda", code: "BM" },
        { name: "Bhutan", code: "BT" },
        { name: "Bolivia", code: "BO" },
        { name: "Bosnia and Herzegovina", code: "BA" },
        { name: "Botswana", code: "BW" },
        { name: "Bouvet Island", code: "BV" },
        { name: "Brazil", code: "BR" },
        { name: "British Indian Ocean Territory", code: "IO" },
        { name: "Brunei Darussalam", code: "BN" },
        { name: "Bulgaria", code: "BG" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cambodia", code: "KH" },
        { name: "Cameroon", code: "CM" },
        { name: "Canada", code: "CA" },
        { name: "Cape Verde", code: "CV" },
        { name: "Cayman Islands", code: "KY" },
        { name: "Central African Republic", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands", code: "CC" },
        { name: "Colombia", code: "CO" },
        { name: "Comoros", code: "KM" },
        { name: "Congo", code: "CG" },
        { name: "Congo, The Democratic Republic of the", code: "CD" },
        { name: "Cook Islands", code: "CK" },
        { name: "Costa Rica", code: "CR" },
        { name: "Cote D'Ivoire", code: "CI" },
        { name: "Croatia", code: "HR" },
        { name: "Cuba", code: "CU" },
        { name: "Cyprus", code: "CY" },
        { name: "Czech Republic", code: "CZ" },
        { name: "Denmark", code: "DK" },
        { name: "Djibouti", code: "DJ" },
        { name: "Dominica", code: "DM" },
        { name: "Dominican Republic", code: "DO" },
        { name: "Ecuador", code: "EC" },
        { name: "Egypt", code: "EG" },
        { name: "El Salvador", code: "SV" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Estonia", code: "EE" },
        { name: "Ethiopia", code: "ET" },
        { name: "Falkland Islands (Malvinas)", code: "FK" },
        { name: "Faroe Islands", code: "FO" },
        { name: "Fiji", code: "FJ" },
        { name: "Finland", code: "FI" },
        { name: "France", code: "FR" },
        { name: "French Guiana", code: "GF" },
        { name: "French Polynesia", code: "PF" },
        { name: "French Southern Territories", code: "TF" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia", code: "GM" },
        { name: "Georgia", code: "GE" },
        { name: "Germany", code: "DE" },
        { name: "Ghana", code: "GH" },
        { name: "Gibraltar", code: "GI" },
        { name: "Greece", code: "GR" },
        { name: "Greenland", code: "GL" },
        { name: "Grenada", code: "GD" },
        { name: "Guadeloupe", code: "GP" },
        { name: "Guam", code: "GU" },
        { name: "Guatemala", code: "GT" },
        { name: "Guernsey", code: "GG" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Guyana", code: "GY" },
        { name: "Haiti", code: "HT" },
        { name: "Heard Island and Mcdonald Islands", code: "HM" },
        { name: "Holy See (Vatican City State)", code: "VA" },
        { name: "Honduras", code: "HN" },
        { name: "Hong Kong", code: "HK" },
        { name: "Hungary", code: "HU" },
        { name: "Iceland", code: "IS" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Iran, Islamic Republic Of", code: "IR" },
        { name: "Iraq", code: "IQ" },
        { name: "Ireland", code: "IE" },
        { name: "Isle of Man", code: "IM" },
        { name: "Israel", code: "IL" },
        { name: "Italy", code: "IT" },
        { name: "Jamaica", code: "JM" },
        { name: "Japan", code: "JP" },
        { name: "Jersey", code: "JE" },
        { name: "Jordan", code: "JO" },
        { name: "Kazakhstan", code: "KZ" },
        { name: "Kenya", code: "KE" },
        { name: "Kiribati", code: "KI" },
        { name: "Korea, Democratic People'S Republic of", code: "KP" },
        { name: "Korea, Republic of", code: "KR" },
        { name: "Kuwait", code: "KW" },
        { name: "Kyrgyzstan", code: "KG" },
        { name: "Lao People'S Democratic Republic", code: "LA" },
        { name: "Latvia", code: "LV" },
        { name: "Lebanon", code: "LB" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libyan Arab Jamahiriya", code: "LY" },
        { name: "Liechtenstein", code: "LI" },
        { name: "Lithuania", code: "LT" },
        { name: "Luxembourg", code: "LU" },
        { name: "Macao", code: "MO" },
        { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Mali", code: "ML" },
        { name: "Malta", code: "MT" },
        { name: "Marshall Islands", code: "MH" },
        { name: "Martinique", code: "MQ" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Mayotte", code: "YT" },
        { name: "Mexico", code: "MX" },
        { name: "Micronesia, Federated States of", code: "FM" },
        { name: "Moldova, Republic of", code: "MD" },
        { name: "Monaco", code: "MC" },
        { name: "Mongolia", code: "MN" },
        { name: "Montserrat", code: "MS" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Myanmar", code: "MM" },
        { name: "Namibia", code: "NA" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "Netherlands", code: "NL" },
        { name: "Netherlands Antilles", code: "AN" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Nicaragua", code: "NI" },
        { name: "Niger", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "Northern Mariana Islands", code: "MP" },
        { name: "Norway", code: "NO" },
        { name: "Oman", code: "OM" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Palestinian Territory, Occupied", code: "PS" },
        { name: "Panama", code: "PA" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Paraguay", code: "PY" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Pitcairn", code: "PN" },
        { name: "Poland", code: "PL" },
        { name: "Portugal", code: "PT" },
        { name: "Puerto Rico", code: "PR" },
        { name: "Qatar", code: "QA" },
        { name: "Reunion", code: "RE" },
        { name: "Romania", code: "RO" },
        { name: "Russian Federation", code: "RU" },
        { name: "RWANDA", code: "RW" },
        { name: "Saint Helena", code: "SH" },
        { name: "Saint Kitts and Nevis", code: "KN" },
        { name: "Saint Lucia", code: "LC" },
        { name: "Saint Pierre and Miquelon", code: "PM" },
        { name: "Saint Vincent and the Grenadines", code: "VC" },
        { name: "Samoa", code: "WS" },
        { name: "San Marino", code: "SM" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Senegal", code: "SN" },
        { name: "Serbia and Montenegro", code: "CS" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Singapore", code: "SG" },
        { name: "Slovakia", code: "SK" },
        { name: "Slovenia", code: "SI" },
        { name: "Solomon Islands", code: "SB" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "South Georgia and the South Sandwich Islands", code: "GS" },
        { name: "Spain", code: "ES" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Sudan", code: "SD" },
        { name: "Suriname", code: "SR" },
        { name: "Svalbard and Jan Mayen", code: "SJ" },
        { name: "Swaziland", code: "SZ" },
        { name: "Sweden", code: "SE" },
        { name: "Switzerland", code: "CH" },
        { name: "Syrian Arab Republic", code: "SY" },
        { name: "Taiwan, Province of China", code: "TW" },
        { name: "Tajikistan", code: "TJ" },
        { name: "Tanzania, United Republic of", code: "TZ" },
        { name: "Thailand", code: "TH" },
        { name: "Timor-Leste", code: "TL" },
        { name: "Togo", code: "TG" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Trinidad and Tobago", code: "TT" },
        { name: "Tunisia", code: "TN" },
        { name: "Turkey", code: "TR" },
        { name: "Turkmenistan", code: "TM" },
        { name: "Turks and Caicos Islands", code: "TC" },
        { name: "Tuvalu", code: "TV" },
        { name: "Uganda", code: "UG" },
        { name: "Ukraine", code: "UA" },
        { name: "United Arab Emirates", code: "AE" },
        { name: "United Kingdom", code: "GB" },
        { name: "United States", code: "US" },
        { name: "United States Minor Outlying Islands", code: "UM" },
        { name: "Uruguay", code: "UY" },
        { name: "Uzbekistan", code: "UZ" },
        { name: "Vanuatu", code: "VU" },
        { name: "Venezuela", code: "VE" },
        { name: "Viet Nam", code: "VN" },
        { name: "Virgin Islands, British", code: "VG" },
        { name: "Virgin Islands, U.S.", code: "VI" },
        { name: "Wallis and Futuna", code: "WF" },
        { name: "Western Sahara", code: "EH" },
        { name: "Yemen", code: "YE" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" },
      ],
      srce:{val:'http://bootdey.com/img/Content/avatar/avatar1.png'},
    };
  },
  mounted() {
    // this.readImage(),
    
    this.clientInfos().then(() => {
      // console.log(this.client);
      if(this.client.imageUrl){
        if (this.client.imageUrl.length != 0){
          console.log(this.client.imageUrl)
          this.srce.val = this.client.imageUrl;
        }
      }
    });
    this.country = this.countries.find((el) => this.client.country == el.name);
  },
  methods: {
    ...mapActions(["getClientInfos", "setClientInfos", "clientInfos"]),
    modifieInputText() {
      // console.log(jwtDecode(localStorage.getItem('userToken')));
      if (this.client?.country === "" || this.client?.country === undefined)
      {
        // console.log(this.countries.find((el) => this.client.phoneObj?.countryCode?.toLowerCase() == el.code.toLocaleLowerCase()))
        this.country = this.countries.find((el) => this.client.phoneObj?.countryCode?.toLowerCase() == el.code.toLocaleLowerCase());
      }
      this.inputStatus = false;
    },
    saveChanges() {
      if (this.country) this.client.country = this.country.name;
      this.client.lang = this.lang;
      this.setClientInfos({ ...this.client }).then(() => {
        this.$toast.add({
          severity: "success",
          summary: "Connexion",
          detail: `${this.success}`,
          life: 5000,
        });
        this.inputStatus = true;
      });
    },
  },
  computed: {
    ...mapGetters({
      client: "client",
      success: "success",
      lang: "lang",
    }),
  },
  watch:{
    'client.imageUrl'(){
      if(this.client.imageUrl){
        if (this.client.imageUrl.length != 0){
          this.srce.val = this.client.imageUrl;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.img-account-profile {
  height: 10rem;
}
.rounded-circle {
  border-radius: 50% !important;
}
// .card {
//     box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
// }
// .card .card-header {
//     font-weight: 500;
// }
// .card-header:first-child {
//     border-radius: 0.35rem 0.35rem 0 0;
// }
// .card-header {
//     padding: 1rem 1.35rem;
//     margin-bottom: 0;
//     background-color: rgba(33, 40, 50, 0.03);
//     border-bottom: 1px solid rgba(33, 40, 50, 0.125);
// }
</style>