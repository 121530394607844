<template>
  <Dialog v-model:visible="display.signin" :style="{width: 'auto'}" :header="$t('landing.nav.connect')" :modal="true" class="p-fluid" @show="myFunction()" @hide="clear()">
<!--     <template #header>
        <img src="../../assets/files/jpeg/background.jpg" style="height: 15rem" />
    </template>
    <template #title>
        Bongo Ride
    </template>
    <template #subtitle>
        Connexion
    </template> -->
    <form @submit.prevent="connect()">
      <div class="field col-12 mt-4"> 
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-at"></i>
          </span>
          <span class="p-float-label">
            <InputText type="email" id="email" v-model="email" @load="myFunction" :style="{backgroundColor: 'var(--surface-200)'}" required/>
            <label for="email">{{ $t("booking.personalD.email") }}</label>
          </span>
        </div>
      </div>
      <div class="field col-12 "> 
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon" @click="toggle_pwd()">
            <i :class="icon"></i>
          </span>
          <span class="p-float-label">
            <InputText :type="type" id="pass" v-model="password" :style="{backgroundColor: 'var(--surface-200)'}" required/>
            <label for="pass" >{{ $t("auth.registerC.pass") }}</label>
            
          </span>
        </div>
        <div class="field col-12 text-center">
          <Button type="submit" :label="$t('landing.nav.login-btn')" icon="pi pi-sign-in" v-if="!isworking" class="bg-blue-900 p-button-rounded mr-2 mt-3 mb-2"></Button>

          <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" v-else fill="var(--surface-ground)" animationDuration=".5s"/>
        </div>
        
      </div>
      </form>
      <template #footer>
        <div class="mt-0 col-12 text-center">
          <Button class="p-button-link px-0 text-center"   @click="updateL({forgot:true})" >{{ $t("auth.loginC.title1") }}</Button>
        </div>
        <div class="mt-0 col-12 text-center">
          <Button class="p-button-link px-0 text-center"   @click="updateL({signup:true})" >{{ $t("auth.loginC.title2") }}</Button>
        </div>
      </template>
  </Dialog>

</template>


<script>
import {mapGetters,mapActions} from 'vuex'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Logins',
  beforeUpdate(){
    console.log(this.display)
  },
  computed:{
    ...mapGetters({
      signin:'signin',
      type: 'typePInp',
      icon: 'typePIcon',
      isworking:'ondoing',
      islogin: 'islogin',
      userlogin: 'userlogin',
      display: 'display',
      error:'error',
      success:'success', 
      reserv: 'reserv',
      lang: 'lang',
  
      
    }),
    email:{
      get(){
        return this.signin.email;
      },
      set(value){
        this.update_signin({...this.signin,email:value});
      }
    },
    password:{
      get(){
        return this.signin.password;
      },
      set(value){
        this.update_signin({...this.signin,password:value});
      }
    }
  },
  methods:{
    ...mapActions(['login','clear_signin','update_display','update_signin','toggle_pwd','update_on_doing', 'create_reserv_onconn']),
    connect(){
      console.log("INSIDE LOGIN COMPONENT")
      localStorage.removeItem('aP');
      localStorage.removeItem('dP');
      this.update_on_doing()
      this.signin.lang = this.lang
      this.login(this.signin)     
      .then(()=>{
        
        this.clear_signin()
        this.update_on_doing()       
        this.lang == 'FR'
        ?(this.$toast.add({severity:'success', summary:'Connexion', detail:`${this.success}`, life: 5000}))
        :(this.$toast.add({severity:'success', summary:'Connection', detail:`${this.success}`, life: 5000}))
        /* this.$router.push({name:'Dashboard'}) */
        this.updateL({signin:false})
      })
      .catch((error)=>{
        this.update_on_doing()
        // console.log("##################")
        // console.log(error)
        // console.log("##################")
        this.$toast.add({severity:'warn', summary:"Connexion", detail:`${error.response.data.message}`, life: 5000});
      })
    },
    myFunction(){
      this.create_reserv_onconn(this.reserv)
      this.show()
    },
    clear(){
      this.update_signin({})
      if (this.display.signin){
        this.updateL({signin:false})
      }
      // console.log(this.signin)
    },
    updateL(data){
        this.update_display(data)
    },
    show(){
      this.update_signin({})
    }
  }
}
</script>

