<template>
  <Dialog v-model:visible="display.forgot" :draggable="true" :style="{width: 'auto'}" :header="$t('auth.forgotC.head1')"  :modal="true" class="p-fluid" @hide="clear()" @show="show()">
<!--     <template #header>
        <img src="../../assets/files/jpeg/background.jpg" style="height: 15rem" />
    </template>
    <template #title>
        Bongo Ride
    </template>
    <template #subtitle>
        Connexion
    </template> -->
    <form @submit.prevent="Forgot()">
      <div class="field col-12 mt-4"> 
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-at"></i>
          </span>
          <span class="p-float-label">
            <InputText type="email" id="email" v-model="fgt.email" :style="{backgroundColor: 'var(--surface-200)'}" required/>
            <label for="email">Email</label>
          </span>
        </div>
      </div>
      <div class="field col-12 text-center">
        <Button type="submit" :label="$t('auth.forgotC.btn')" icon="pi pi-history" v-if="!isworking" class="p-button-rounded mr-2 mt-3 mb-2"></Button>

        <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" v-else fill="var(--surface-ground)" animationDuration=".5s"/>
      </div>
      </form>
      <template #footer>
        <div class="mt-0 col-12 text-center">
          <Button class="p-button-link px-0 text-center"   @click="updateF({signin:true})" >{{ $t("auth.forgotC.title1") }}</Button>
        </div>
        <div class="mt-0 col-12 text-center">
          <Button class="p-button-link px-0 text-center"   @click="updateF({signup:true})" >{{ $t("auth.forgotC.title2") }}</Button>
        </div>
      </template>
  </Dialog>

</template>


<script>
import {mapGetters,mapActions} from 'vuex'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Forgot',
  beforeUpdate(){
    console.log(this.display)
  },
  computed:{
    ...mapGetters({
      fgt:'forgot',
      isworking:'ondoing',
      display: 'display',
      lang: 'lang'
    }),
    email:{
      get(){
        return this.fgt.email;
      },
      set(value){
        this.update_forgot({...this.fgt,email:value});
      }
    },
  },
  methods:{
    ...mapActions(['forgot','update_display','update_forgot','update_on_doing']),
    Forgot(){
      this.update_on_doing()
      // console.log(this.signin)
      this.fgt.lang = this.lang
      this.forgot(this.fgt)
      .then(()=>{
        // this.clear_forgot()
        this.update_on_doing()
        this.$i18n.locale == 'FR'
        ?(this.$toast.add({severity:'success', summary: this.$t("auth.forgotC.summary"), detail: this.$t("auth.forgotC.detail"), life: 5000}))
        :(this.$toast.add({severity:'success', summary: this.$t("auth.forgotC.summary"), detail:  this.$t("auth.forgotC.detail"), life: 5000}))
        /* this.$router.push({name:'Dashboard'}) */
        this.updateF({forgot:false})
      })
      .catch(()=>{
        this.update_on_doing()
        this.$i18n.locale == 'FR'
        ?(this.$toast.add({severity:'warn', summary:  this.$t("auth.forgotC.summary"), detail:  this.$t("auth.forgotC.errorM"), life: 5000}))
        :(this.$toast.add({severity:'warn', summary:  this.$t("auth.forgotC.summary"), detail:  this.$t("auth.forgotC.errorM"), life: 5000}))
      })
    },
    clear(){
      this.update_forgot({});
      if (this.display.forgot){
        this.updateL({forgot:false})
      }
      // console.log(this.fgt);
    },
    updateF(data){
        this.update_display(data)
    },
    show(){
      this.update_forgot({})
    }
  },
}
</script>

