<template>

	<!-- <Button
	ref="Bom"
	:class="['p-button-text p-button-rounded border-none font-light line-height-2 text-dark-900 d-flex justify-content-center', deviceVisible]"
	@click="toggleMenu"
	>
		<i class="pi pi-user" v-if="islogin == false"></i> -->
    <span>
  		<Avatar
  		:image="userlogin.imageUrl"
  		shape="circle"
  		v-if="haveUrlProfile == 'profile'"
  		@click="toggleMenu"
  		:class="['w-auto', deviceVisible]"
  		v-badge.danger=""
      :pt="{
          image: { 
            style:  ['min-width: 45%;max-width: 75%;min-height: 45%;max-height: 75%;', mq.xs?'width: 1.8rem !important;height: 1.8rem !important;':'width: 100% !important;height: 100% !important;object-fit:cover;border-radius:50%;margin:0;']
          },
          hooks: { 
            style:  ['padding:0; margin:0;object-fit:cover;']
          },
          icon: { 
            style:  ['padding:0; margin:0;object-fit:cover;']
          },
          root: { 
            style:  ['object-fit:cover;background-color:transparent;padding:0; margin:0;border:none;']
          }
      }"
  		/>

  		<Avatar
  		size="md"
  		class="p-text-primary"
  		shape="circle"
  		v-if="haveUrlProfile == 'noprofile'" 
  		@click="toggleMenu"
  		:class="['w-auto', deviceVisible]"
  		:style="['background-color:#2020d0; color: #ffffff;min-width: 45%;max-width: 75%;min-height: 45%;max-height: 75%;', mq.xs?'width: 1.8rem !important;height: 1.8rem !important;':'border-radius: 100% !important']"
  		v-badge.danger=""
      :pt="{
          hooks: { 
            style:  ['padding:0; margin:0;object-fit:cover;', mq.xs?'width: 1.8rem !important;height: 1.8rem !important;':'width: 100% !important;height: 100% !important;object-fit:cover;border-radius:50%;margin:0;']
          },
          icon: { 
            style:  ['padding:0; margin:0;object-fit:cover;max-width: 75%;min-height: 45%;max-height: 75%;', mq.xs?'width: 1.8rem !important;height: 1.8rem !important;':'width: 100% !important;height: 100% !important;object-fit:cover;border-radius:50%;margin:0;']
          },
          root: { 
            style:  ['background-color:#2020d0; color: #ffffff;padding:0; margin:0;border:none;']
          }
      }"
  		>{{ Pseudo }}</Avatar>
  		
  		<!--<span class="text-gray-700 d-lg-none" v-if="this.lang == 'FR' && haveUrlProfile == 'noprofile'"
  		>Profile</span
  		> <span class="text-gray-700 d-lg-none" v-if="this.lang == 'US' && haveUrlProfile == 'noprofile'"
  		>Profil</span
  		> -->

  		<Menu
  		ref="menu"
  		:model="contextualMenu"
  		:popup="true"
  		/>
  </span>
		<!-- <span class="text-gray-900 mx-2 font-bold" v-if="haveUrlProfile == 'profile'"
		>{{ Pseudo }}</span> 
	</Button> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {

  name: 'userAvatar',
  inject: ['mq'],
  props:{
  	classes: {
  		type:String,
  		default: ""
  	}
  },

  data() {
    return {
    	contextualMenu: [
          {
            label: `${this.$t("userspace.title")}`,
            icon: "bi bi-person-workspace bi-fw",
            command: () => {
              this.update_display(
                {
                  account:true
                }
              )
            },
          },
          {
            separator: true,
          },
          {
            label: `${this.$t("landing.nav.contextualMenu.client.disc")}`,
            icon: "pi pi-pw pi-sign-out",
            command: () => {
              this.confirmLogout();
            },
          },
        ],
      srce:{val:'http://bootdey.com/img/Content/avatar/avatar1.png'},
    };
  },


  methods: {
    ...mapActions([
      "update_display",
      "Logout",
      "clear_token"
    ]),
    toggleMenu(event) {
      // event.isTrusted = true
      // console.log(event)
      this.$refs.menu.toggle(event);
    },
    confirmLogout() {
      if(!localStorage.getItem('userToken')){
        console.log("dds")
        this.clear_token()
      }
      else{
        this.lang == "US"
          ? this.$confirm.require({
              message: "Would you like to log out?",
              header: "Log out from Bongo Ride",
              icon: "pi pi-info-circle",
              acceptClass: "p-button-danger",
              accept: () => {
                this.Logout();
                this.$router.push({ path: "/" });
                /* .then(()=>{
                              this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
                          }) */
              },
              reject: () => {
                /* this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000}); */
              },
            })
          : this.$confirm.require({
              message: "Voulez-vous vous déconnecter?",
              header: "Déconnexion de Bongo Ride",
              icon: "pi pi-info-circle",
              acceptClass: "p-button-danger",
              accept: () => {
                this.Logout();
                this.$router.push({ path: "/" });
                /* .then(()=>{
                              this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
                          }) */
              },
              reject: () => {
                /* this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000}); */
              },
            });
        }
    },
  },
  computed: {
    ...mapGetters({
      islogin: "islogin",
      user: "user",
      userlogin: "userlogin",
      menu: "menu",
      lang: "lang",
      dropoff: "guessReserv",
      res: "reserv",
      client: "client",
    }),

    deviceVisible(){
    	return this.classes
    },

    copyright() {
      return ` ${new Date().getFullYear()} TAKENCO`;
    },
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
    Pseudo() {

      if (this.islogin == true) {
        let name = this.userlogin.name;
        let surname =
          this.userlogin.surname.split(" ")[
            this.userlogin.surname.split(" ").length - 1
          ];
        // console.log(name +' ### '+ surname)

        return name.charAt(0).toUpperCase() + surname.charAt(0).toUpperCase();
      } else return "";
    },
    haveUrlProfile() {
      if (this.islogin == true) {
        if (this.userlogin.imageUrl) {
          if (this.userlogin.imageUrl != "") {
            return "profile";
          } else {
            return "noprofile";
          }
        } else {
          return "noprofile";
        }
      } else {
        return "notconnect";
      }
    },
  },

  watch: {
    '$i18n.locale'(){
      this.contextualMenu[2].label = `${this.$t("landing.nav.contextualMenu.client.disc")}`
      this.contextualMenu[0].label = `${this.$t("userspace.title")}`
    },
    'client.imageUrl'(){
      // verifie if image exist and update the value of srce.val, by doing the profile picture is changed
      if(this.client.imageUrl){
          this.srce.val = this.client.imageUrl;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.p-autocomplete:not(.p-disabled).p-focus,
.p-calendar.p-inputwrapper-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
    border-color: #183462;
    border-radius: 6px;
}

.p-avatar img {
    width: 25px !important;
    height: 25px !important;
}
</style>
