<template>
  <router-view></router-view>
</template>

<script>
import EventBus from "@/mixins/AppEventBus";
import { mapGetters, mapActions } from "vuex";
import axios from "@/services/axios-http-config";
import wss from '@/services/socket-services.js'

export default {
  name: "AppC",
  themeChangeListener: null,
  // components: {
  //   AppManualUpdate
  // },
  created() {
    // this.startNetworkStatusService()
    this.checkUserLogin().catch((error)=>{
      // console.log(error)
      if(error.response){
        if(error.response.status === 402 || error.response.status === 401){
          this.clear_token()
        }
      }
    });
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    ...mapActions([
      "checkUserLogin",
      "clear_token",
      "updateDropOffReserv",
      "update_lang",
      "updateUserProfile",
      "userReservation",
      "getDrivers",
      "update_drivers",
    ]),
  },
  computed: {
    ...mapGetters({
      userlogin: "userlogin",
      pmCodes: "pmCodes",
      lang: "lang",

    }),
  },
  mounted() {
    // console.log(window.innerHeight)
    // document.querySelector("#app").height = window.screen.innerHeight
    this.themeChangeListener = (event) => {
      const elementId = "theme-link";
      const linkElement = document.getElementById(elementId);
      const cloneLinkElement = linkElement.cloneNode(true);
      const newThemeUrl = linkElement
        .getAttribute("href")
        .replace(this.$appState.theme, event.theme);

      cloneLinkElement.setAttribute("id", elementId + "-clone");
      cloneLinkElement.setAttribute("href", newThemeUrl);
      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", elementId);
      });
      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      this.$appState.theme = event.theme;
      this.$appState.darkTheme = event.dark;
    };
    if(this.lang == "")
    {
      console.log(this.lang)
      this.$i18n.locale =
        navigator.language.substring(0, 2).toUpperCase() == "FR" ? "fr" : "us";
      this.update_lang(
        navigator.language.substring(0, 2).toUpperCase() == "FR" ? "FR" : "US"
      );
    }else{
      this.$i18n.locale = this.lang.toLowerCase()
    }
    EventBus.on("theme-change", this.themeChangeListener);
    wss(this);
    this.getDrivers()
  },
  beforeUnmount() {
    EventBus.off("theme-change", this.themeChangeListener);
  },
};
</script>