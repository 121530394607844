import axios from '@/services/axios-http-config';
// import jwtDecode from 'jwt-decode'

const state = {
    Mreservation: {},
    reservations: [],
    Rselected: null,
    places: [],
    place:{},
    costumersCounter: [],
}

const getters = {
    Rselected: state => state.Rselected,
    reservations: state => state.reservations,
    Mreservation: state => state.Mreservation,
    ReservationFilter: state => (code , city) => {
        return state.reservations.filter(el => el.checkIn == true  && city.includes(el.departure_point._id) && (el.trajet_code != null ? String(el.trajet_code).includes(code) : String(el.code).includes(code)))
    },
    ReservationToOnBoard: state => city => state.reservations.filter(el => {
        let a = new Date(el.hour),
            b = new Date();

        let a1 = new Date(el.hour); 
        a1.setDate(a.getDate()+1);
        a1.setMinutes(0);
        a1.setHours(0);

        // console.log(a1);
        // if(el.checkIn === true && city.includes(el.departure_point._id) && (b <= a || b <= a1)){
        if(el.checkIn === true && city.includes(el.departure_point._id)){
            return el
        }
    }),
    Air_P_display_name: state => state.Air_P_display_name,
    Air_IRR_display_name: state => state.Air_IRR_display_name,
    places: state => state.places,
    place: state => state.place,
    totalSales: state => state.totalSales,

}


const mutations = {
    CLEAR_RESERVATION(state) {
        state.Mreservation = {}
    },
    CLEAR_RESERVATIONS(state) {
        state.reservations = {}
    },
    SET_RESERVATION(state, payload) {
        state.Mreservation = payload
    },
    READ_PLACES(state, payload){
        state.places = Array.isArray(payload) ? payload : [payload]
    },
    SET_PLACE(state, payload){
        state.place = payload
    },
    UPDATE_DASHBOARD(state, payload){
        state.reservations = Array.isArray(payload) ? payload : [payload]

    },
    FETCH_RESERVATIONS_DATA(state, payload){
        state.reservations = Array.isArray(payload) ? payload : [payload]
    },
    SET_RESERVATIONS(state, payload) {
        state.reservations = Array.isArray(payload) ? payload : [payload]
    },
    CLEAR_SELECTED(state) {
        state.Rselected = []
    },
    SET_SELECTED(state, payload) {
        state.Rselected = payload
    },
    GET_AIRPORTSPARAM(state, payload) {
        state.Air_P = payload
    },
    CLEAR_INPUTFIELD(state) {
        state.place = {};
    },
    READ_CUSTOMERCOUNTER(state, payload){
        state.costumersCounter = payload
    }

}

const actions = {
    clear_InputField({ commit }) {
        commit('CLEAR_INPUTFIELD');
    },
    clear_selected({ commit }) {
        commit('CLEAR_SELECTED')
    },
    clear_reservation({ commit }) {
        commit('CLEAR_RESERVATION')
    },
    clear_reservations({ commit }) {
        commit('CLEAR_RESERVATIONS')
    },

    set_reservation({ commit }, data) {
        commit('SET_RESERVATION', data)
    },
    // set_reservations({ commit }, data) {
    //     commit('SET_RESERVATIONS', data)
    // },
    // set_airport_datatable({ commit }, data){
    //     commit('SET_AIRPORT_DATATABLE', data)
    // },
    set_selected({ commit }, data) {
        commit('SET_SELECTED', data)
    },
    
    getReservations({ commit }, data) {
        return axios.post('api/reservM/read_Av', data).then((response) => {
            
            response.data != null ? commit('SET_RESERVATIONS', response.data) : null
            
            commit('FETCH_RESERVATIONS_DATA', response.data)
        });
    },
    update_dashboard({commit}, data){        
        // data != null ?  commit ('SET_RESERVATIONS', data) : null  
        if(data)
        {
            return axios.post('api/reservM/read', data).then((response) => {
                response.data != null ? commit('SET_RESERVATIONS', response.data) : null
            });
        }
    },

    updatereserv({ commit }, data) {
        return axios.post('api/reservUM/update', data).then((response) => {
            commit("UPDATE_SUCC_MSG", response.data.message)
            commit('UPDATE_SHOWUPDATE', false)
            commit('CLEAR_RESERVATION')
        });
    },
    add_location({ commit }, data) {
        console.log(data)
        return axios.post('api/LStore/store', data).then((response) => {
            commit("UPDATE_SUCC_MSG", response.data.message)
            
        });
    },
    getPlaces({ commit}, data){
        return axios.post('api/Places/read', data).then((response) => {           
            response.data !=null ? commit('READ_PLACES', response.data): null
        });
    },
    set_place({ commit}, data){
        commit('SET_PLACE', data)
    },
    customersCounter({commit}, data){
        return axios.post('auth/user/read', data).then((response) => {           
            response.data !=null ? commit('READ_CUSTOMERCOUNTER', response.data): null
            
        });
    }


}

const reservMModule = {
    state,
    mutations,
    actions,
    getters
}

export default reservMModule;