import jwtDecode from 'jwt-decode'
export default function auth(to, from, next) {
    // middleware authentication , that only allow a supervisor to log in the web app
    if (localStorage.getItem('userToken')) {
        // console.log()
        if (jwtDecode(localStorage.getItem('userToken')).user) {
            if (jwtDecode(localStorage.getItem('userToken')).user.role) {
                if (jwtDecode(localStorage.getItem('userToken')).user.role === 'client') {
                    // console.log(jwtDecode(localStorage.getItem('userToken')).exp < Date.now() / 1000);
                    if (jwtDecode(localStorage.getItem('userToken')).exp < Date.now() / 1000) {
                        localStorage.removeItem('userToken');
                        next("/");
                    }
                    return next()
                } else return next("/");
            } else {
                localStorage.removeItem('userToken');
                next("/");
            }
        } else {
            localStorage.removeItem('userToken');
            next("/");
        }
    }

    return next("/");
}