<template>
	<div 
		class="row row-cols-1 row-cols-md-3 g-2"
		v-for="serviceCategorie, i in services"
		:key="i"
	>
		<div 
			class="col"
			v-for="service, j in serviceCategorie"
			:key="j"
		>
			<serviceCardB 
				:url="service.url"
				:shortName="service.shortName"
				:image="service.image"
				:imageSize="service.imageSize"
				:serviceSize="service.serviceSize"
				:radius="service.radius"
				:Servclass="service.Servclass"
			/>
		</div>
		<Divider type="solid" v-if="i < services.length - 1" />
	</div>
</template>

<script>
export default {

  name: 'cardServiceGroup',

  props:{
  	AllServices:{
  		type:Array,
  		default(){return []}
  	}
  },

  data() {
    return {

    };
  },

  computed:{
  	services(){
  		return this.AllServices
  	}
  }
};
</script>

<style lang="scss" scoped>
</style>
