<template>
	<Dropdown
		:class="custom"
	    :id="idk"
	    v-model="recipiant"
	    :required="required"
	    :placeholder="placeholder"
	    :optionLabel="field"
	    :aria-label="alab"
	    :inputStyle="sty"
	    :options="options"
	    @click="clickFn"
	    :showClear="closebtn"
	    :title="title"
		:appendTo="appendToP"
		:pt="{
			panel: { style: 'z-index: 2080;' },
			input: { style: 'margin-right: 0;' },
		}"
  	>
	    <template #value="slotProps">
	        <span v-if="slotProps.value" class='d-flex align-items-center text-truncate' v-html="valueFn(slotProps.value)">
	        </span>
	        <span v-else>
	            {{slotProps.placeholder}}
	        </span>
	    </template>
	    <template #option="slotProps">
	        <span class='d-flex align-items-center text-truncate' v-html="optionFn(slotProps.option)">
	        </span>
	    </template>
  </Dropdown>
</template>

<script>

	import { ref } from 'vue'

	export default {
		name: 'DropDownB',
		props:{
			classes: {
				type: [Object,String],
				default: "m-auto w-full ml-0"
			},
			appendToP: {
				type: String,
				default: "body"
			},
			identifier: {
				type: String,
				default: "arrival_point"
			},
			modelValue: {
				type: Object
			},
			requiredVar:{
				type: Boolean,
				default: true
			},
			closedVar:{
				type: Boolean,
				default: true
			},
			optionsVar: {
				type: Array,
				default(){return [];}
			},
			clickFn: {
				type: Function,
				default(){return null;}
			},
			valueFn: {
				type: Function,
				default(){return null;}
			},
			optionFn: {
				type: Function,
				default(){return null;}
			} ,
			placeholder: {
				type: String,
				default: "arrival_point"
			},
			fieldName: {
				type: String,
				default: "arrival_point"
			},
			AriaLabel: {
				type: String,
				default: "arrival_point"
			},
			title: {
				type: String,
				default: ""
			},
			inputStyle: [Object, String],
		},
		data(){
			return {
			}
		},
		computed:{
			custom(){return this.classes},
			idk(){return this.identifier},
			required(){ return this.requiredVar},
			closebtn(){ return this.closedVar},
			field(){ return this.fieldName},
			sty(){ return this.inputStyle},
			alab(){ return this.AriaLabel},
			options(){ return this.optionsVar},
			recipiant:{
				get(){
					return this.modelValue != null &&  typeof this.modelValue === 'object'? Object.keys(this.modelValue).length != 0?this.modelValue:null:null
				},
				set(value){
					// this.recipiant1 = Value
					this.$emit('update:modelValue', value)

				}
			}
		},
		created(){
			/**
			 * enamble reactivity on data
			 */
			// console.log(this)
			// this.recipiant1 = ref(this.reactiveVar)
			// console.log(this.recipiant1?true:false)
			// this.suggestions = ref(this.suggestionsVar)
		},

	}
</script>

<style lang="scss" scoped>

</style>