
// import { isEqual, words } from 'lodash';
import axios from "@/v2/services/axios-http-config";

/**
 * @module
 * Booking Services
 * @author dtvni
 * @version 1
 */

export default class Booking_kits {
	/**
     * Check if it's a driver available for a booking request
     * @method
     * @param {object} book - object of booking information
     * @return {Object} state
     */
	async availabilityCheckerRequest(book) {
		// flag data
		let Availability = false;
		let Msg = "";
		
        // requestion to the REST API backend
        await axios.post('v1/reservation/checkAvailability', {
            ...book,
            hour: new Date(book.hour).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            })
        }).then((response) => {
            Msg = response.data.message
            Availability = response.data.flag
        })

        return {Availability,Msg}
	}

    /**
     * Download receipt method
     * @method
     * @param {String}  ticket_name - ressource name of the receipt
     * @param {String} lang - lang of booking
     * @param {Function} resetTicketName - remove the ticket name in the env
     * @param {Function} updateCurrentPlace - update the current place in the map
     * @param {Function} set_arg_reserv - update the reservation object
     * @param {Function} updatecoord - update the coord points of path
     * @param {Function} updateRequestFlag - update request flag
     * @param {Function} buttonUnveiller - update the control bot
     * @param {Function} updateIsdone - update the progress status
     * @param {Function} resetShowMap - reset the map visibility
     * @param {Function} resetFlag - reset the flag act
     * @param {Object} router - router app object
     * @param {Function} closer - close offcanvas booking
     * @param {Function} pmCode - close offcanvas booking
     * @param {Function} updateRideOrientation - set booking orientation
     */
    downloadFile(
        ticket_name,
        lang,
        resetTicketName,
        updateCurrentPlace,
        set_arg_reserv,
        updatecoord,
        updateRequestFlag,
        buttonUnveiller,
        updateIsdone,
        resetShowMap,
        resetFlag,
        router,
        closer,
        pmCode,
        updateRideOrientation
        ) {
      axios({
        url: `${process.env.VUE_APP_API_URL}api/v1/download/${ticket_name}/${lang}`, // File URL goes here
        method: "GET",
        responseType: "blob",
      // axios({
      //   url: `${process.env.VUE_APP_API_URL}printPdf/uploadFile`, // File URL goes here
      //   method: "POST",
      //   responseType: "blob",
      //   data: { ticket_name: ticket_name, language: lang },
      }).then((res) => {
        let name = `${ticket_name}.pdf`
        resetTicketName('')
        updateCurrentPlace({})
        set_arg_reserv({
            //pickup_date : new Date().toLocaleString().slice(0,10),
            pickup_date_D: new Date(),
            // pickup_hour_D: new Date().toLocaleString().slice(12, 17),
            hour: new Date(),
            timeDifference: 0,
            cost: 10000,
            paymentMethod: null,
            language: null,
            mode: 'l',
            defaultTime: new Date().toLocaleString().slice(11, 17),
            arrival_point: {},
            departure_point:{},
            flight_company:{},
        })
        updatecoord([])
        updateRequestFlag(false)
        pmCode({})
        updateRideOrientation(true)
        // console.log("CURIOUS TO KNOW 1")
        // console.log(res.data)
        // console.log("CURIOUS TO KNOW 2")
        let blob = res.data;
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = (e) => {
          //create a document file
          let a = document.createElement("a");
          a.download = name;
          a.href = e.target.result;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          buttonUnveiller();
          updateIsdone();
          router.push({ path: "/direction" });
          resetShowMap(false);
          resetFlag(false);
          closer()
        };

      });
    }



    /**
     * Get en-EN time
     * 
     */

    getENTime(date){
        return (new Date(date).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        }))
    }
}