<template>
    <div >
        <input 
        type="file" 
        accept="image/x-icon,image/apng,image/svg+xml,image/webp,image/png,image/jpeg" 
        class="hidden " 
        ref="file" 
        @change="change"   
        id="imageUpload"/>

        <div class="col align-self-center">
            <div class="container " >
                <div class="row ">
                    <img :src="srce.val" alt="Avatar" class="rounded-circle mb-auto p-2 fit-picture" style='width:250px; heigt:250px; object-fit:cover;border-radius:50%;margin:auto;'  :width="250" :height="250"/>
                    <!-- <button @click="browse()" class="p-2 ">Charger une nouvelle image</button> -->
                    <span :class="{
                        'fw-bold':true,
                        'text-danger': warm,
                        'blink': warm,
                    } ">{{ $t('booking.AccountDetails.imgSize') }}</span>
                    <div v-if="!isworking">
                        <Button
                        :label="$t('booking.AccountDetails.upbtn')"
                        id="button"
                        class="w-full d-none d-md-block"
                        @click.prevent="browse()"
                        :style="{
                          backgroundColor: '#2020d0',
                          color: '#ffffff',
                        }"
                        
                        />
                        <Button
                        icon="pi pi-upload"
                        label=""
                        id="button"
                        class=" w-full d-block d-md-none"
                        @click.prevent="browse()"
                        :style="{
                          backgroundColor: '#2020d0',
                          color: '#ffffff',
                        }"
                        />
                    </div>
                    <ProgressSpinner
                        style="width: 50px; height: 50px"
                        strokeWidth="8"
                        v-else
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>



const props = {
    // username: {
    //     type: String,
    //     default: 'Username',
    //     required: true,
    // },
    value: File,
    src:{
        type: Object,
        required: true,
        default: true,
    },
    // size: {
    //     type: String,
    //     default: 's', // s,m or l
    //     required: false
    // },
    // border: {
    //     type: Boolean,
    //     default: true,
    //     required: false
    // },
    // bgColor: {
    //     type: String,
    //     required: false
    // },
    // borderColor: {
    //     type: String,
    //     required: false
    // },
    // textColor: {
    //     type: String,
    //     required: false
    // },
    // colorType: {
    // type: String,
    // required: false,
    // default: 'normal' // normal, pastel, or brownie        
    // },
    image:{
        type: String,
        required: false
    }
}
// import axios from 'axios';
import { mapGetters, mapActions } from "vuex";
export default {
    props,
    setup() {
        
    },
    data(){
        return{
            srce: this.src,
            file: '',
            selectedFile: '',
            warm : false,
        }
    },
    computed:{
        ...mapGetters({
            isworking: "ondoing",
        }),
    },
    methods: {
        ...mapActions(["onUpload","update_on_doing"]),
        browse(){
            this.warm = false;
            this.$refs.file.click();
        },
        change(e){
            this.$emit('input', e.target.files[0])
            let reader = new FileReader();
            this.selectedFile = e.target.files[0];
            console.log(this.getFileSize(this.selectedFile))
            let fd = new FormData();
            let size = this.getFileSize(this.selectedFile)
            if (  size > 0 && size <= 2  ){
                this.update_on_doing();
                fd.append('file', this.selectedFile)
                fd.forEach(el => console.log(el))
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    // this.srce = e.target.result;                
                }
                this.onUpload(fd).then(()=> this.update_on_doing());
            } else if (size !== -1) {
                this.warm = true
            }
            
        },
        getFileSize( file ) {
            let size = -1;
            if (file) {
                size = (file.size)/1024 ** 2;
            }
            return size
        }
    }
}
</script>
<style lang="scss" scoped>
.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
    to {
      visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
}
</style>