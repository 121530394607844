import io from "socket.io-client";
import jwtDecode from "jwt-decode";

export default  (actor)=>{
  // real time setting up
    const socket = io(process.env.VUE_APP_API_URL);
    // console.log(actor)
    let sound = new Audio('https://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3');
    let sound1 = new Audio('https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3');

    // trigger connection to server
    socket.on("connect", () => {
      // console.log("socket connect");
    });
    socket.on("updateClient", (value) => {
      // check if can decode receive jwttoken
      // if yes, see if exist an user sub-object
      // if yes, see if exist a role defineProperty
      // if yes, check if the current user id match with entry userUpdateInformations id
      if (jwtDecode(value)) {
        // console.log(jwtDecode(value));
        // console.log(jwtDecode(localStorage.getItem("userToken")));
        if (jwtDecode(value).user) {
          if (jwtDecode(value).user.role == "client") {
            if (
              jwtDecode(value).user._id ==
              jwtDecode(localStorage.getItem("userToken")).user._id
            ) {
              // console.log(jwtDecode(value));
              sound.play()
              actor.updateUserProfile(value);
            }
          }
        }
      }
    });
    socket.on("updateDriver", (value) => {
      // check if can decode receive jwttoken
      // if yes, see if exist an user sub-object
      // if yes, see if exist a role defineProperty
      // if yes, check if the current user id match with entry userUpdateInformations id
      if (jwtDecode(value)) {
        if (jwtDecode(value).user) {
          if (jwtDecode(value).user.role == "driver") {
            actor.update_drivers(jwtDecode(value).user);
          }
        }
      }
    });
    socket.on("guessDropOff", (value) => {
      /* 
        When receive guess dropoff pool request
        First, check if token is decodable 
        next, check if inside the token exists and email
        compare this email with the current user email
        if, save entry reservation data in local db variable
        after saving, a dialog will be display to ask a confirmation
        trust or decline it
        case trust, an other dialog will be display inform you that your current trip has been complete successfully
        case decline, an other dialog will be display inform you that you will be able to confirm the end of the process in your reservation history
        
        
        Also check if exists the driver field 
        next, check if it's matching with the current driver user
        if, save and the state of dropoff button will change to waiting...
        */
      console.log(jwtDecode(value).user);
      if (jwtDecode(value).user) {
        if (jwtDecode(value).user.email) {
          if (
            jwtDecode(value).user.email ==
            jwtDecode(localStorage.getItem("userToken")).user.email
          ) {
            actor.updateDropOffReserv(jwtDecode(value).user);
            // console.log(jwtDecode(value).user);
            sound1.play()
          }
        }
      }
    });
    socket.on("onboarded", (value) => {
      /* 
        When receive guess dropoff pool request
        First, check if token is decodable 
        next, check if inside the token exists and email
        compare this email with the current user email
        if, save entry reservation data in local db variable
        */
      // console.log(jwtDecode(value).user);
      if (jwtDecode(value).user) {
        if (jwtDecode(value).user.email) {
          if (
            jwtDecode(value).user.email ==
            jwtDecode(localStorage.getItem("userToken")).user.email
          ) {
            actor.updateDropOffReserv(jwtDecode(value).user);
            sound1.play()
            // console.log(jwtDecode(value).user);
          }
        }
      }
    });
    socket.on("newReservation", (value) => {
      /* 
        When receive guess dropoff pool request
        First, check if token is decodable 
        next, check if inside the token exists and email
        compare this email with the current user email
        if, save entry reservation data in local db variable
        */
      console.log(jwtDecode(value).user);
      if (jwtDecode(value).user) {
        if (jwtDecode(value).user.email) {
          if (
            jwtDecode(value).user.email ==
            jwtDecode(localStorage.getItem("userToken")).user.email
          ) {
            actor.userReservation({ email: jwtDecode(localStorage.getItem("userToken")).user.email });
            sound1.play()
          }
        }
      }
    });
    socket.on("completedDropOff", (value) => {
      /* 
        When receive guess dropoff pool request
        First, check if token is decodable 
        next, check if inside the token exists and email
        compare this email with the current user email
        if, save entry reservation data in local db variable
        after saving, a dialog will be display to ask a confirmation
        trust or decline it
        case trust, an other dialog will be display inform you that your current trip has been complete successfully
        case decline, an other dialog will be display inform you that you will be able to confirm the end of the process in your reservation history
        
        
        Also check if exists the driver field 
        next, check if it's matching with the current driver user
        if, save and the state of dropoff button will change to waiting...
        */
      // console.log(jwtDecode(value).user);
      if (jwtDecode(value).user) {
        if (jwtDecode(value).user.email) {
          if (
            jwtDecode(value).user.email ==
            jwtDecode(localStorage.getItem("userToken")).user.email
          ) {
            actor.updateDropOffReserv(jwtDecode(value).user);
            sound1.play()
            // console.log(jwtDecode(value).user);
          }
        }
      }
    });
}