<template>
    <div :class="['offcanvas text-bg-white bg-white m-0 p-0 ', !device ? 'offcanvas-start offcanvas-size-md':'offcanvas-bottom offcanvas-size-md-height']" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="BookingOffCanvas" aria-labelledby="BookingOffCanvasNavbarLabel">
      <div class="offcanvas-header d-flex align-items-start border-bottom  border-cyan p-2 pb-4 mb-2 border-opacity-25">
        <button 
            class="navbar-toggler mx-1" 
            style="border:none; outline: none;" 
            type="button" 
            data-bs-toggle="offcanvas" 
            data-bs-target="#MenuOffCanvas" 
            aria-controls="MenuOffCanvas" 
            v-tooltip.bottom="{
                value: 'Menu',
                class: 'custom-error',
              }"
              @click.prevent="show('#MenuOffCanvas')"
            >
            <span class="navbar-toggler-icon" style="border:none;outline: none;"></span>
          </button>
          <!-- <i class="bi bi-bicycle"></i>
          <i class="bi bi-geo-fill"></i>
          <i class="bi bi-geo-alt-fill"></i> -->
          <picture v-if="!device || true">
            <source srcset="@/assets/files/logo/logo_blue_1.webp" type="image/webp">
            <source srcset="@/assets/files/logo/logo_blue_1.avif" type="image/avif">
            <img src="@/assets/files/logo/logo_blue_1.png" alt="BongoRide Logo" height="30" width="130" class="mr-0 lg:mr-2" >
          </picture>
          <button type="button" class="btn-close btn-close-dark mr-2" id="closeBook" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <!-- <h5 class="" id="offcanvasDarkNavbarLabel">Dark offcanvas</h5> -->
      </div>
      <div :class="['offcanvas-body text-2xl mt-0 pb-0 h-100',device ?'mobile':'']" id="stepi">
        <router-view :isMobile="device"/>
      </div>
    </div>
</template>
<script>
	export default{
		name:"BookingOffCanvas",
    inject: ["mq"],
		props:{

		},
    computed:{
      device(){
        // console.log(navigator.userAgent||navigator.vendor||window.opera, this.mq);
        return this.mq.xs
      },
      hidden(){
        let hide = "hidden"
        // if (this.$route.name === "paypal") 
        // {
        //   hide = "auto"
        // }
        return hide
      }
    },
    watch:{
      mq(){
        console.log(this.mq)
      }
    },
    methods: {
        // calling the functions from store
        // ...mapActions([
        // "resetFlag",
        //     "set_arg_reserv",
        // ]),
        show(id){
          // let node = document.querySelector(id);
          // node.classList.toggle('show');
        },
    }
	}
</script>

<style lamg="scss" >
	@import './offcanvas-size.scss';


  #stepi[class*="mobile"] {
      overflow:v-bind(hidden);
      padding: 0;
      margin: auto 1px;
      height: 100% !important;
      width: 100% !important;
      & #step1 {
        position:relative;
        height: 100% !important;
        width: 100% !important;
        
        & .pagination[style*="position: absolute;"] {
          bottom: 0;
          width: 100%;
        }
      }
      & #step2 {
        position:relative;
        height: 100% !important;
        width: 100% !important;
        
        & .pagination[style*="position: absolute;"] {
          bottom: 0;
          width: 100%;
        }
      }

      & #step3 {
        position:relative;
        height: 100% !important;
        width: 100% !important;
        
        & .pagination[style*="position: absolute;"] {
          bottom: 0;
          width: 100%;
        }
      }

      & #step4 {
        position:relative;
        height: 100% !important;
        width: 100% !important;
        
        & .pagination[style*="position: absolute;"] {
          bottom: 0;
          width: 100%;
        }
      }

  }

</style>