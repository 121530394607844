import axios from '@/services/axios-http-config';
import jwtDecode from 'jwt-decode'

const state = {
    signin: {},
    signup: {},
    forgot: {},
    display: {
        signin: false,
        signup: false,
        forgot: false,
        account: true,
    },
    islogin: false,
    userlogin: {},
    showP: false,
    isOnline: false,
    client: {},
}

const getters = {
    client: state => state.client,
    signin: state => state.signin,
    signup: state => state.signup,
    forgot: state => state.forgot,
    showP: state => state.showP,
    islogin: state => state.islogin,
    userlogin: state => state.userlogin,
    display: state => state.display,
    typePInp: (state) => {
        return state.showP === false ? 'password' : 'text'
    },
    typePIcon: (state) => {
        return state.showP === false ? "pi pi-eye" : "pi pi-eye-slash "
    },
}

const mutations = {
    TOGGLE_PWD(state) {
        state.showP = !state.showP
    },
    UPDATE_SIGN_IN(state, payload) {
        state.signin = payload
    },
    UPDATE_FORGOT(state, payload) {
        state.forgot = payload
    },
    UPDATE_TOKEN(state, payload) {
        state.token = payload
    },
    UPDATE_DISPLAY(state, payload) {
        Object.keys(state.display).forEach(function(key, index) {
          state.display[key] = false;
        });
        Object.keys(payload).forEach(function(key, index) {
          state.display[key] = payload[key];
        });
        // state.display = payload
    },
    UPDATE_IS_LOG_IN(state, payload) {
        state.islogin = payload
    },
    UPDATE_USER_LOG_IN(state, payload) {
        state.userlogin = payload
    },
    UPDATE_SIGN_UP(state, payload) {
        state.signup = payload
    },
    LOG_IN(payload) {
        /* localStorage.setItem('api_key', payload.api_key); */
        localStorage.setItem('user_id', payload.user.id);
        localStorage.setItem('user', JSON.stringify(payload.user));
    },
    CREATE_RESERV_ONCONN(state, payload) {
        state.signin.email = payload.data.email
        state.signup.email = payload.data.email
        state.signup.name = payload.data.name
        state.signup.surname = payload.data.surname
        state.signup.phone_number = payload.data.phone_number
    },
    CHANGE_NETWORK_STATUS(state, payload) {
        state.isOnline = payload;
        localStorage.isOnline = payload;
    },
    CLIENT_INFOS(state) {
        state.client = {...state.userlogin, phone: state.userlogin?.phoneObj?.formatted  }

    },
    CLIENT_UPDATE_IMAGEURL(state, payload) {
        state.client = {...state.client,imageUrl:payload }
    }
}

const actions = {
    toggle_pwd({ commit }) {
        commit('TOGGLE_PWD')
    },
    clear_signin({ commit }) {
        commit('UPDATE_SIGN_IN', {})
    },
    create_reserv_onconn({ commit }, data) {
        commit('CREATE_RESERV_ONCONN', { data })
    },
    clear_signup({ commit }) {
        commit('UPDATE_SIGN_UP', {})
    },
    update_display({ commit }, data) {
        commit('UPDATE_DISPLAY', data)
    },
    update_signin({ commit }, data) {
        commit('UPDATE_SIGN_IN', data)
    },
    update_forgot({ commit }, data) {
        commit('UPDATE_FORGOT', data)
    },
    update_signup({ commit }, data) {
        commit('UPDATE_SIGN_UP', data)
    },
    clientInfos({ commit }) {
        commit('CLIENT_INFOS');
    },
    login({ commit }, data) {
        // console.log("LoginData")
        // console.log(data)

        return axios.post('v1/auth/login', data).then((response) => {
            // console.log(response.data)
            commit("UPDATE_SUCC_MSG", response.data.message)
            commit('UPDATE_SIGN_IN', {})
            localStorage.setItem('userToken', response.data.token)
            commit('UPDATE_USER', jwtDecode(response.data.token).user)

            commit('UPDATE_IS_LOG_IN', true)
            commit('AUTOCOMPLETE_CLIENT_DATA', jwtDecode(response.data.token).user)
            commit('UPDATE_USER_LOG_IN', jwtDecode(localStorage.getItem('userToken')).user)
        })
    },
    forgot({ commit }, data) {
        // console.log(data)
        return axios.post('v1/auth/forgot_pwd', data).then(() => {
            commit('UPDATE_FORGOT', {})
        });
    },
    createAccount({ commit }, data) {
        // console.log(data)
        data.statut = true
        return axios.post('v1/auth/signup', data).then((response) => {
            // console.log(response.data)
            if (response.status != 200) {
                commit('UPDATE_ERR_MSG', response.data.message)
            } else {
                commit("UPDATE_SUCC_MSG", response.data.message)
                commit('UPDATE_SIGN_UP', {})

            }
        });
    },
    Logout({ commit }) {
        let data
        jwtDecode(localStorage.getItem('userToken')) ? data = {...jwtDecode(localStorage.getItem('userToken')), user_id: jwtDecode(localStorage.getItem('userToken')).user._id } : null
        return axios.post('v1/auth/logout', data).then(() => {
            localStorage.removeItem('userToken')
            localStorage.removeItem('userTokener_id')
            commit('UPDATE_SIGN_UP', {})
            commit('UPDATE_IS_LOG_IN', false)
            commit('UPDATE_USER_LOG_IN', {})

        });
    },
    clear_token({ commit }){
        localStorage.removeItem('userToken')
        commit('UPDATE_SIGN_UP', {})
        commit('UPDATE_IS_LOG_IN', false)
        commit('UPDATE_USER_LOG_IN', {})
    },
    checkUserLogin({ commit }) {
        let data
            // console.log(localStorage.getItem('userToken'))
            // console.log("inside checkUserLogin 2")
        localStorage.getItem('userToken') ? (jwtDecode(localStorage.getItem('userToken')) ? data = { token: localStorage.getItem('userToken') } : null) : null
        return axios.post('v1/auth/CheckTokenValidity', data).then((response) => {
            commit('UPDATE_IS_LOG_IN', true)
            commit('UPDATE_USER_LOG_IN', jwtDecode(localStorage.getItem('userToken')).user)
            commit('AUTOCOMPLETE_CLIENT_DATA', jwtDecode(response.data.token).user)
            commit('UPDATE_USER', jwtDecode(response.data.token).user)
        });
    },
    updateUserProfile({ commit }, data) {
        localStorage.setItem('userToken', data);
        commit('UPDATE_USER', jwtDecode(data).user);
        // console.log(jwtDecode(data).user);
        // commit('UPDATE_IS_LOG_IN', true)
        // // commit('AUTOCOMPLETE_CLIENT_DATA', jwtDecode(response.data.token).user)
        commit('UPDATE_USER_LOG_IN', jwtDecode(localStorage.getItem('userToken')).user);
        commit('AUTOCOMPLETE_CLIENT_DATA', jwtDecode(localStorage.getItem('userToken')).user);
        commit('CLIENT_INFOS');
    },
    setClientInfos({ commit }, data) {
        data.con = jwtDecode(localStorage.getItem('userToken')).conn_id
        delete data._id
        console.log(data)
        return axios.post('v1/auth/update_account', data).then((response) => {
            commit("UPDATE_SUCC_MSG", response.data.message);
        });
    },
    onUpload({ commit }, data){
        // upload image avatar from the link
        return axios.post(
            `v1/auth/uploadAvatar`,
            data,            
            {
                headers: { 'Content-Type': 'multipart/form-data'}
            }).then((response) => {
                
            commit("UPDATE_SUCC_MSG", response.data.message);
            commit("CLIENT_UPDATE_IMAGEURL", response.data.filename);
        });
    },
}

const authModule = {
    state,
    mutations,
    actions,
    getters
}

export default authModule;