import axios from '@/services/axios-http-config';
// import jwtDecode from 'jwt-decode'

const state = {
    Company_name:[],
}

const getters = {
    Company_name: state=>state.Company_name,
    

}


const mutations = {
    SET_COMPANIES(state, payload) {
        state.Company_name = Array.isArray(payload) ? payload : [payload]
    },
    
}

const actions = {
    get_Companies({ commit }, data) {
        return axios.get('v1/company/read').then((response) => {

          commit('SET_COMPANIES', response.data)

        });
    },
}

const reservMModule = {
    state,
    mutations,
    actions,
    getters
}

export default reservMModule;