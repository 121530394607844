<template>
  <!-- preload state -->
  <div class="border-round border-1 surface-border p-4 surface-card w-100" v-if="!viz">
    <DataTable :value="Booklazy">
        <template #header>
        <div
        class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
        >
          <span class="block mt-2 md:mt-0 p-input-icon-left mw-10">
          <i class="pi pi-search" />
          <InputText
          v-model="filters['global'].value"
          :placeholder="$t('booking.bookingHistory.loading1')"
          />
          </span>
        </div>
      </template>
      <Column>
        <template #body="slotProps">
          <Skeleton shape="circle" size="2rem" class="mr-2" :key="slotProps" ></Skeleton>
        </template>
      </Column>
      <Column
        field="flight_company"
        :header="$t('booking.personalD.comp')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="code"
        :header="$t('booking.bookingHistory.code')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="Driversurname"
        :header="$t('booking.bookingHistory.driverS')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="arrival_point"
        :header="$t('booking.confirmD.dropOffL')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="pickup_date_D"
        :header="$t('booking.confirmD.dateL')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="pickup_hour_D"
        :header="$t('booking.bookingHistory.hourL')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="cost"
        :header="$t('booking.bookingHistory.cost')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="paymentMethod"
        :header="$t('booking.bookingHistory.moyenP')"
        :sortable="true"
        >
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column
        field="Statut"
        :header="$t('booking.bookingHistory.status')"
        :sortable="true"
        >
        <template #body="{ data }">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="data" width="100%" height="1rem" />
            </div>
        </template>
      </Column>

      <Column field="rating" :header="$t('booking.bookingHistory.reviews')">
        <template #body="slotProps">
            <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                <Skeleton :key="slotProps" width="100%" height="1rem" />
            </div>
        </template>    
      </Column>
    </DataTable>
  </div>
  <!-- end preload state -->

  <!-- datatable -->
  <DataTable
    v-if="viz"
    ref="dt"
    :value="ratesVar"
    v-model:selection="selectedReservation"
    dataKey="_id"
    :scrollable="true"
    :paginator="true"
    :rows="10"
    :filters="filters"
    :rowHover="true"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[5, 10, 25, 50, 100]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
    responsiveLayout="scroll"
    :globalFilterFields="[
    'trajet_code',
    'cost',
    'paymentMethod',
    'name',
    'Drivername',
    'Driversurname',
    'onBoard',
    'checkIn',
    'checkOut',
    'surname',
    'departure_point.display_name', 
    'arrival_point.display_name',
    'pickup_date_D',
    'phone_number',
    'email',
    'pickup_hour_D',
    'flight_company.name',
    'pickup_hour_A'
    ]"
    filterDisplay="menu"
    v-model:filters="filters"
    sortField="addedAt" 
    :sortOrder="-1"
    >
    <template #header>
      <div
      class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
      >
        <!-- <h5 class="m-0">{{ $t("booking.bookingHistory.title") }}</h5> -->
        <span class="block mt-2 md:mt-0 p-input-icon-left mw-10">
        <i class="pi pi-search" />
        <InputText
        v-model="filters['global'].value"
        :placeholder="$t('booking.bookingHistory.loading1')"
        />
        </span>
      </div>
    </template>


    <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

    <Column>
      <template #body="slotProps">
        <Button 
          type="button" 
          label="" 
          icon="pi pi-ellipsis-v"
          class="p-button-rounded p-button-primary mr-2"
          :title="` $t('booking.bookingHistory.action.tite') code N°${slotProps.data.trajet_code}`"
          @click="toggle(slotProps.data._id,$event)" 
          aria-haspopup="true" 
          aria-controls="overlay_tmenu"/>
        <TieredMenu 
        :id="slotProps.data._id" 
        :ref="slotProps.data._id" 
        :model="[
          {
          label: $t('booking.bookingHistory.action.download'),
          icon:'pi pi-fw pi-download',
          command:()=>{
          downloadFile(slotProps.data.ticket)
          }
          },

          {
          label: $t('booking.bookingHistory.action.rating'),
          visible: (slotProps.data.rating == 0 && slotProps.data.checkOut) || false,
          icon:'pi pi-fw pi-star-fill',
          command:()=>{
          renderRating(slotProps.data,$event)
          }
          },

          {
          label: $t('booking.bookingHistory.action.confirmD'),
          visible: (slotProps.data.guess_dropoff && !slotProps.data.checkOut) || false,
          icon: 'pi pi-fw pi-check-circle',
          command:()=>{
          ConfirmDropOff({ trajet_code: slotProps.data.trajet_code })
          }
          }
        ]" 
        :popup="true" />
        <!-- <Button
        icon="pi pi-lock-open"
        class="p-button-rounded p-button-warning mr-2"
        v-if="slotProps.data.onBoard && slotProps.data.guess_dropoff"
        :title="` Confirmer le débarquement lié à cette reservation ${slotProps.data.trajet_code}`"
        @click.prevent="
        ConfirmDropOff({ trajet_code: slotProps.data.trajet_code })
        "
        /> -->
      </template>
      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>
    </Column>
    <Column
      field="flight_company.name"
      :header="$t('booking.personalD.comp')"
      sortable
      >
      <template #body="{ data }">
        <span class="p-column-title">{{ $t("booking.personalD.comp") }}</span>
        {{ data.flight_company?data.flight_company.name:'' }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="trajet_code"
      :header="$t('booking.bookingHistory.code')"
      sortable
      >
      <template #body="{ data }">
      <span class="p-column-title">{{
      $t("booking.bookingHistory.code")
      }}</span>
      {{ data.trajet_code }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="Driversurname"
      :header="$t('booking.bookingHistory.driverS')"
      :sortable="true"
      >
      <template #body="slotProps">
        <span class="p-column-title">{{
        $t("booking.bookingHistory.driverS")
        }}</span>
        {{ slotProps.data.Driversurname }}
      </template>
      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="arrival_point.display_name"
      :header="$t('booking.confirmD.dropOffL')"
      sortable
      >
      <template #body="slotProps">
      <span class="p-column-title">{{
      $t("booking.confirmD.dropOffL")
      }}</span>
      {{ 
            slotProps.data.arrival_point.address.aeroway?
                      slotProps.data.arrival_point.address.aeroway
                      :
                          slotProps.data.arrival_point.address.amenity
                          ?
                          slotProps.data.arrival_point.address.amenity
                          :
                          slotProps.data.arrival_point.address.town
                              ? 
                              slotProps.data.arrival_point.address.town
                              :
                              slotProps.data.arrival_point.address.suburb
                                  ?
                                  slotProps.data.arrival_point.address.suburb
                                  :
                                  ""
          }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="pickup_date_D"
      :header="$t('booking.confirmD.dateL')"
      :sortable="true"
      >
      <template #body="slotProps">
      <span class="p-column-title">{{ $t("booking.confirmD.dateL") }}</span>
      {{ format(slotProps.data.pickup_date_D) }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
      </template> -->
    </Column>

    <Column
      field="pickup_hour_D"
      :header="$t('booking.bookingHistory.hourL')"
      :sortable="true"
      >
      <template #body="slotProps">
      <span class="p-column-title">{{
      $t("booking.bookingHistory.hourL")
      }}</span>
      {{ slotProps.data.pickup_hour_D }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

        <Column
      field="pickup_hour_A"
      :header="$t('booking.bookingHistory.hourA')"
      :sortable="true"
      >
      <template #body="slotProps">
      <span class="p-column-title">{{
      $t("booking.bookingHistory.hourA")
      }}</span>
      {{ slotProps.data.checkOut?$Booking_kits.getENTime(slotProps.data.pickup_hour_A):null }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="cost"
      :header="$t('booking.bookingHistory.cost')"
      :sortable="true"
      >
      <template #body="slotProps">
      <span class="p-column-title">{{
      $t("booking.bookingHistory.cost")
      }}</span>
      {{ slotProps.data.cost }} XAF
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputNumber  v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="paymentMethod"
      :header="$t('booking.bookingHistory.moyenP')"
      :sortable="true"
      >
      <template #body="slotProps">
      <span class="p-column-title">{{
      $t("booking.bookingHistory.moyenP")
      }}</span>
      {{ slotProps.data.paymentMethod }}
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column
      field="Statut"
      :header="$t('booking.bookingHistory.status')"
      :sortable="true"
      >
      <template #body="{ data }">
      <span class="p-column-title">{{
      $t("booking.bookingHistory.status")
      }}</span>
      <!-- <i class="pi" :class="{'text-green-500 pi-check-circle': data.activate, 'text-pink-500 pi-times-circle': !data.activate}"></i> -->
      <p
      :class="{
      'text-info': data.guess_dropoff,
      'text-success': data.checkOut,
      'text-warning': data.onBoard,

      }"
      >
      {{
      data.onBoard ? "onBoard" : (data.checkOut ? "checkOut" : (data.checkIn?"checkIn":"Waiting Dropoff Confirmation"))
      }}
      </p>
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
      </template> -->
    </Column>

    <Column field="rating" :header="$t('booking.bookingHistory.reviews')">
      <template #body="slotProps">
        <Rating v-model="slotProps.data.rating" :stars="5" :readonly="true" :cancel="false" />
      </template>

      <template #loading>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
              <Skeleton width="40%" height="1rem" />
          </div>
      </template>

      <!-- <template #filter="{ filterModel }">
          <Rating v-model="filterModel.value" :stars="5" :cancel="true" />
      </template> -->      
    </Column>
  </DataTable>
  <!-- datatable -->

  <DynamicDialog />
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { mapGetters, mapActions } from "vuex";
import axios from "@/services/axios-http-config";
import { h } from 'vue';
import Button from 'primevue/button';
import RatingComp from './ratingComp.vue'

import ProgressSpinner from 'primevue/progressspinner';


export default {
  name: "ReserveH",
  props: {
    // selected: Object,
  },
  // components:{
  //   RatingComp
  // },
  watch: {
    selectedReservation() {
      this.set_selected(this.selectedReservation);
    },
  },
  data() {
    return {
      filters: {},
      showDialog: false,
      val1: 0,
      ratingSubmitted: true,
      products: null,
      selectedReservation: null,
      viz:false,
      Booklazy: new Array(10),
    };
  },  
  beforeCreate(){
    // console.log(new Date())

  },
  beforeMount(){
    // console.log(new Date())

  },
  async created() {
    this.initFilters();
    // console.log(new Date())
    await this.userReservation({ email: this.user.email }).then(()=>this.viz=true);
    // console.log(new Date())
  },
  async mounted() {
    // console.log(new Date())
    //  load all reservations from server with this specific email
    // console.log(this.user.email);
  },
  methods: {
    ...mapActions([
      "updateuserRating",
      "set_selected",
      "userReservation",
      "ConfirmDropOffReservation",
      "update_on_doing",
    ]),
    renderRating(params){
      let param = params;
      const dialogRef = this.$dialog.open(RatingComp, {
          props: {
              header: `${this.$t('booking.bookingHistory.ratingM.header')}${param.trajet_code}`,
              style: {
                  width: '30vh',
              },
              breakpoints:{
                  '960px': '75vw',
                  '640px': '90vw'
              },
              modal: true
          },
          data: {
            res: param
          },
          templates: {
              footer: () => {
                  return [
                      !this.isworking ?
                        param.rating > 0 ?
                          h(Button, { label: 'Save', icon: 'pi pi-save', onClick: () => {console.log(param);this.evalReservation(param).then(()=>{dialogRef.close({ buttonType: 'Yes' })})}, autofocus: true }):
                          null
                        :
                      h(ProgressSpinner, { strokeWidth:'8', fill:'var(--surface-ground)', animationDuration:'.5s', style:'width:50px;height:50px', autofocus: true })

                  ]
              }
          },
          onClose: (options) => {
              const data = options.data;
              // if (data) {
              //     const buttonType = data.buttonType;
              //     const summary_and_detail = buttonType ? { summary: 'No Product Selected', detail: `Pressed '${buttonType}' button` } : { summary: 'Product Selected', detail: data.name };

              //     this.$toast.add({ severity:'info', ...summary_and_detail, life: 3000 });
              // }
          }
      });
    },
    toggle(menu,event) {
      // console.log(menu)
      // console.log(Object.keys(this.$refs).indexOf(menu));

      const option = this.$refs[menu]
      // console.log(option)
      option.toggle(event);
    },
    downloadFile(name) {
      axios({
        url: `${process.env.VUE_APP_API_URL}api/v1/download/${name}/${this.lang}`, // File URL goes here
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        let blob = res.data;
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = (e) => {
          //create a document file
          let a = document.createElement("a");
          a.download = `${name}.pdf`;
          a.href = e.target.result;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    ConfirmDropOff(data) {
      console.log("I AM READY TO DROP MESSAGE ?")
      this.lang == "US"
        ? this.$confirm.require({
            message: "Would you like to confirm your arrival?",
            header: "confirm dropoffed from Bongo Ride cab",
            icon: "pi pi-exclamation-triangle",
            acceptClass: "p-button-danger",
            accept: () => {
              this.ConfirmDropOffReservation(data).then(() => {
                this.userReservation({ email: this.user.email });
                this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Confirmation sended",
                  life: 3000,
                });
              });
            },
            reject: () => {
              this.$toast.add({
                severity: "error",
                summary: "Rejected",
                detail:
                  "You will be able to trust it at each time routing to your onboard reservation view.",
                life: 3000,
              });
            },
          })
        : this.$confirm.require({
            message: "Vous souhaitez confirmer votre arrivée?",
            header: "confirmer le dépôt du taxi Bongo Ride",
            icon: "pi pi-exclamation-triangle",
            acceptClass: "p-button-danger",
            accept: () => {
              this.ConfirmDropOffReservation(data).then(() => {
                this.userReservation({ email: this.user.email });
                this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Confirmation sended",
                  life: 3000,
                });
              });
            },
            reject: () => {
              this.$toast.add({
                severity: "error",
                summary: "Rejected",
                detail:
                  "You will be able to trust it at each time routing to your onboard reservation view.",
                life: 3000,
              });
            },
          });
    },
    // hideDialog(){
    //     this.showDialog = false;
    // },
    evalReservation(RatesVar) {
      // this.showDialog = true
      // this.set_selected(this.selectedReservation);
      this.update_on_doing()
      RatesVar.lang = this.lang;
      return this.updateuserRating({ ...RatesVar }) // store the reservation evaluation in the database
        .then(() => {
          this.update_on_doing()
          this.$toast.add({
            severity: "success",
            detail: `${this.success}`,
            life: 5000,
          });
        });
      // this.ratingSubmitted = false;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    format(date) {
      // console.log(date)
      return date
        ? new Date(date).toLocaleString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        : "-";
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        paymentMethod: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        trajet_code: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        cost: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        Drivername: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        surname: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        Driversurname: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        taxi_number: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        cni: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'departure_point.display_name': {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        phone_number: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        pickup_date_D: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.DATE_IS },
          ],
        },
        pickup_hour_D: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        pickup_hour_A: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'flight_company.name': {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'arrival_point.display_name': {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        cost: { value: null, matchMode: FilterMatchMode.EQUALS },
        onBoard: { value: null, matchMode: FilterMatchMode.EQUALS },
        checkIn: { value: null, matchMode: FilterMatchMode.EQUALS },
        checkOut: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
  },
  computed: {
    ...mapGetters({
      ratesVar: "ratesVar",
      Rselected: "Rselected",
      success: "success",
      user: "userlogin",
      isworking:'ondoing',
      reserv: "reserv",
      onBoard: "onBoard",
      lang: "lang",
    }),
  },
};
</script>

<style scoped lang="scss">
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
</style>