import axios from '@/v2/services/axios-http-config';
import {markRaw} from 'vue';
import { ConnectedOverlayScrollHandler } from 'primevue/utils';

const state = {
    reserv: {
        //pickup_date : new Date().toLocaleString().slice(0,10),
        pickup_date_D: new Date(),
        // pickup_hour_D: new Date().toLocaleString().slice(12, 17),
        hour: new Date(),
        timeDifference: 0,
        cost: 10000,
        paymentMethod: null,
        language: null,
        mode: 'l',
        defaultTime: new Date().toLocaleString().slice(11, 17),
        arrival_point: {},
        departure_point:{},
        flight_company:{},
    },
    coordinates: [],
    ticket_name: "",
    rating: 0,
    ratesVar: [],
    guessReserv: null,
    isDone: false,
    reservVerified: false,
    canOnboard: true,
    reservVerifiedBeforeConfirm: false,
    currentOnBoardData: {},
    onboardingData: {},
    onboardingFinalDataObject: {},
    onBoard: false,
    checked: false,
    confirm: false,
    promoCodeChecker: false,
    showMap: false,
    precision: false,
    locationObject: {},
    ajustTime: null,
    rideOrientation: true,
    currentUPlace: null,


    // reservationProUsers: []

}

const getters = {
    currentUPlace: state => state.currentUPlace,
    rideOrientation: state => state.rideOrientation,
    ajustTime: state => state.ajustTime,
    driverTripFlag: state => state.driverTripFlag,
    yearSaleOverFlag: state => state.yearSaleOverFlag,
    weekSaleOverFlag: state => state.weekSaleOverFlag,
    canOnboard: state => state.canOnboard,
    frequentTrip: state => state.frequentTrip,
    driverTrip: state => state.driverTrip,
    confirm: state => state.confirm,
    coordinates: state => state.coordinates,
    ticket_name: state => state.ticket_name,
    guessReserv: state => state.guessReserv,
    reserv: state => state.reserv,
    checked: state => state.checked,
    precision: state => state.precision,
    promoCodeChecker: state => state.promoCodeChecker,
    onBoard: state => state.onBoard,
    onboardingData: state => state.onboardingData,
    currentOnBoardData: state => state.currentOnBoardData,
    onboardingFinalDataObject: state => state.onboardingFinalDataObject,
    isDone: state => state.isDone,
    reservVerified: state => state.reservVerified,
    reservVerifiedBeforeConfirm: state => state.reservVerifiedBeforeConfirm,
    ratesVar: state => state.ratesVar,
    showMap: state => state.showMap,
}

const mutations = {

    // GETTER ACTIONS
    GET_R_RESERV(state, payload) {
        state.ratesVar = Array.isArray(payload) ? payload : [payload]
    },
    GET_TIME(state, payload) {
        state.ajustTime = payload;
    },

    // UPDATE ACTIONS
    UPDATE_RESERVATION(state, payload) {
        state.reserv = payload
    },
    UPDATE_RIDE_ORIENTATION(state, payload) {
        state.rideOrientation = payload
    },
    UPDATE_CURRENT_PLACE(state, payload) {
        state.currentUPlace = payload
    },
    UPDATE_PRECISION(state, payload) {
        state.precision = payload
    },
    UPDATE_CONFIRM(state, payload) {
        state.confirm = payload
    },
    UPDATE_COORDINATES(state, payload) {
        state.coordinates = payload
    },
    UPDATE_RESERVATION_HOUR(state) {
        state.reserv.hour = state.reserv.pickup_date_D;
    },
    UPDATE_GUESS_RESERVATION(state, payload) {
        state.guessReserv = payload
        
    },
    UPDATE_RESERVATION_LIST(state,payload){
        let i = 0
        state.ratesVar = state.ratesVar.map((res)=> {
            if(res._id == payload._id){
                // console.log({
                //     ...payload,
                //     departure_point: res.departure_point,
                //     arrival_point: res.arrival_point,
                //     flight_company: res.flight_company
                // })
                i++
                return payload
            }
            return res;
        })
        if(i == 0){
            state.ratesVar.push(payload)
        }
    },
    UPDATE_ISDONE(state) {
        state.isDone = !state.isDone
    },
    UPDATE_TICKET_NAME(state, payload) {
        state.ticket_name = payload;
    },
    UPDATE_RESERV_TEL(state, payload) {
        state.reserv.phone_number = payload
    },
    UPDATE_RESERV_STATUS(state, payload) {
        state.reservVerified = payload

    },
    UPDATE_RESERV_STATUS_BEFORE_CONFIRM(state, payload) {
        state.reservVerifiedBeforeConfirm = payload

    },
    UPDATE_ONBOARD_STATUS(state, payload) {
        state.onBoard = payload

    },
    UPDATE_CURRENT_ONBOARD_DATA(state, payload) {
        state.currentOnBoardData = payload;
    },
    BUTTON_UNVEILLER(state) {
        state.promoCodeChecker = !state.promoCodeChecker
    },
    RESET_TRIP(state, payload) {
        state.onboardingData = {};
        state.onBoard = false;
        state.currentOnBoardData = {};
        state.guessReserv = null;
    },
    RESETSHOWMAP(state, payload) {
        state.showMap = payload;
    },

    // SET ACTIONS
    SET_F_TRIP(state, payload) {
        state.frequentTrip = payload
    },
    SET_TOTAL_TRIP(state, payload) {
        state.driverTrip = payload
    },
    SET_D_TRIP(state, payload) {
        state.driverTripPdaySlot = payload;
    },
    SET_RESERVATION_DATE(state, payload) {
        state.reserv.pickup_date_D = payload;
    },
    SET_PRICE(state, payload) {
        state.reserv.cost = payload
    },
    SET_ARG_RESERV(state, payload) {
        state.reserv = payload
    },
    SET_PRECISION(state, payload) {
        state.precision = payload
    },
    AUTOCOMPLETE_CLIENT_DATA(state, payload) {
        state.reserv.name = payload.name
        state.reserv.surname = payload.surname
        state.reserv.email = payload.email
        state.reserv.phone_number = payload.phone_number
    },
    FLIGHTTRACKERHANDLER(payload) {
        localStorage.setItem('FlightID', payload.reserv.flight_number)
    },
    CHECKUSERCODE(state, payload) {
        state.reserv = payload
    },
    ONBOARDING_PROCESS_COMPLETE(state, payload) {
        state.onboardingData.code = payload.trajet_code || payload.code
        state.onboardingData.name = payload.name
        state.onboardingData.surname = payload.surname
        state.onboardingData.email = payload.email
        state.onboardingData.phone_number = payload.phone_number
        state.onboardingData.departure_point = payload.departure_point
    },
    ONBOARDING_ENDPOINT_API(state, payload) {},
    // AVAILABILITYCHECKERREQUEST(state, payload){
    //     state.reserv.departure_point = payload.departure_point
    //     state.reserv.pickup_date_D = payload.pickup_date_D
    //     state.reserv.pickup_hour_D = payload.pickup_hour_D
    // },
    SAVER_REQUEST_FLAG(state, payload) {
        state.checked = payload;
    },
    UPDATE_DRIVER_TRIP_PER_DAY(state, payload) {
        state.driverTripPerDayFlag = payload;
        state.driverTripFlag = !payload;
    },
    UPDATE_DRIVER_TRIP(state, payload) {
        state.driverTripPerDayFlag = !payload;
        state.driverTripFlag = payload;
    },
    UPDATE_SALES_OVER_WEEK(state, payload) {
        state.weekSaleOverFlag = payload;
        state.yearSaleOverFlag = !payload;
    },
    UPDATE_SALES_OVER_YEAR(state, payload) {
        state.yearSaleOverFlag = payload;
        state.weekSaleOverFlag = !payload;
    },




}

const actions = {
    // GETTER ACTIONS

    // UPDATE ACTIONS

    // SET ACTIONS
    updateCurrentPlace({commit},data){
        commit("UPDATE_CURRENT_PLACE",data)
    },
    get_time({ commit }, data) {
        commit('GET_TIME', data);
    },
    update_can_onbaord({ commit }, data) {
        commit('UPDATE_CAN_ONBOARD', data);
    },
    update_confirm({ commit }, data) {
        commit('UPDATE_CONFIRM', data);
    },
    update_precision({ commit }, data) {
        commit('UPDATE_PRECISION', data);
    },
    availabilityCheckerRequest({ commit }, data) {
        commit("UPDATE_SUCC_MSG", data.Msg)
        commit('SAVER_REQUEST_FLAG', data.Availability)
    },
    updateRequestFlag({commit},data){
        commit('SAVER_REQUEST_FLAG', data)
    },
    updateRideOrientation({commit},data){
        commit('UPDATE_RIDE_ORIENTATION', data)
    },
    resetShowMap({ commit }, data) {
        commit('RESETSHOWMAP', data)
    },
    updatecoord({
        commit
    }, data) {
        commit('UPDATE_COORDINATES', data);
        // console.log(data);
    },
    setResDate({
        commit
    }, data) {
        commit('SET_RESERVATION_DATE', data)
    },
    resetFlag({ commit }, data) {
        commit('SAVER_REQUEST_FLAG', data)
    },
    update_driverTripPerDay({ commit }, data) {
        commit("UPDATE_DRIVER_TRIP_PER_DAY", data)
    },
    update_driverTrip({ commit }, data) {
        commit("UPDATE_DRIVER_TRIP", data)
    },
    update_salesYearOv({ commit }, data) {
        commit("UPDATE_SALES_OVER_YEAR", data)
    },
    update_salesWeekOv({ commit }, data) {
        commit("UPDATE_SALES_OVER_WEEK", data)
    },
    setPrice({
        commit
    }, data) {
        commit('SET_PRICE', data)
    },
    resetTrip({ commit }, data) {
        commit('RESET_TRIP', data)
    },
    resetTicketName({ commit }, data) {
        commit('UPDATE_TICKET_NAME', data)
    },
    autoComplient({ commit }, data) {
        commit('AUTOCOMPLETE_CLIENT_DATA', data);
    },
    reservationRequest({ commit }, data) {
        state.reserv.timeDifference = state.ajustTime.totalTimeF;
        if (data.mode == 'n') {
            data.pickup_date_D = new Date();
        }
        return axios.post('v1/reservation/reserve', data).then((response) => {
            // console.log(response.data.ticket_name)
            commit('UPDATE_TICKET_NAME', response.data.ticket_name)
        })
    },
    updateIsdone({ commit }) {
        commit('UPDATE_ISDONE')
    },
    buttonUnveiller({ commit }) {
        commit('BUTTON_UNVEILLER')
    },
    updateReservStatus({ commit }, data) {
        commit('UPDATE_RESERV_STATUS', data)
    },
    updateReservStatusBeforeConfirm({ commit }, data) {
        commit('UPDATE_RESERV_STATUS_BEFORE_CONFIRM', data)
    },
    updateTel({
        commit
    }, data) {
        commit('UPDATE_RESERV_TEL', data)
    },
    autocomplete_client_data({ commit }, data) {
        console.log(data)
        commit('AUTOCOMPLETE_CLIENT_DATA', data)
    },
    onboardingEndPointApi({ commit }, data) { // with the button "confirm" the driver confirm the reservation and 
        //  the reservation object is updated with the driver informations and registration plate, driver licence ,...    
        return axios.post('v1/reservation/onboarding', data).then((response) => {
            commit("UPDATE_ONBOARD_STATUS", true);
            commit("UPDATE_SUCC_MSG", response.data.message);
            commit('UPDATE_CURRENT_ONBOARD_DATA', response.data.data);
        });

    },
    updateDropOffReserv({ commit }, data) {
        commit("UPDATE_GUESS_RESERVATION", data);
        commit("UPDATE_RESERVATION_LIST",data)
    },
    blogger({ commit }, data) { // store clients comments
        return axios.post('v1/reservation/comments', data).then((response) => {
            // state.onBoard = true;
            // console.log(state.onBoard)
            commit("UPDATE_SUCC_MSG", response.data.message)
        });
    },
    updateuserRating({ commit }, data) { // store the reservation evaluation in the database
        return axios.post('v1/reservation/rating', data).then((response) => {
            commit("UPDATE_SUCC_MSG", response.data.message)
        });
    },
    async userReservation({ commit }, data) { //load reservation history pro user
        // return axios.post('/api/reservationProUserR/reservationProUserLogic', data).then((response) => {
        return await axios.get(`v1/reservation/read/${data.email}`).then((response) => {
            response.data != null ? commit('GET_R_RESERV', response.data) : null
            commit("UPDATE_SUCC_MSG", response.data.message)
        });

    },
    ConfirmDropOffReservation({
        commit
    }, data) {
        /* 
        Just confirm here dropoff of particulare reservation by sending _id of reservation to backend 
        */
        return axios.post('v1/reservation/confirmDropOff', {
            currentTime: new Date(),
            id: data.trajet_code
        })
        // .then((response) => {
        //     commit("UPDATE_GUESS_RESERVATION", null)
        // });

    },
    DeniedDropOffReservation({
        commit
    }, data) {
        /* 
        Just confirm here dropoff of particulare reservation by sending _id of reservation to backend 
        */
        return axios.post('v1/reservation/deniedDropOff', {
            currentTime: new Date(),
            id: data.trajet_code
        })
        // .then((response) => {
        //     commit("UPDATE_GUESS_RESERVATION", null)
        // });

    },
    guessDropOffReservation({
        commit
    }, data) {
        /* 
        Just guess here dropoff of particulare reservation by sending _id of reservation to backend 
        */
        return axios.post('v1/reservation/guessDropOff', {
            code: state.currentOnBoardData.trajet_code
        })
        // .then((resp) => { commit("UPDATE_GUESS_RESERVATION", resp.data.data); });

    },
    addNewLocation({ commit }, data) {

    },
    set_arg_reserv({ commit }, data) {
        console.log("------<HI>-----")
        commit("SET_ARG_RESERV", data)
    },
    set_precision({ commit }, data) {
        commit("SET_PRECISION", data)
    }




}

const reservModule = {
    state,
    mutations,
    actions,
    getters
}

export default reservModule;