/* eslint-disable */

/**
 * COMPONENTS IMPORTATION
 * 
 */

import NavBarB from '@/v2/layouts/NavBar'
import MenuOffCanvas from '@/v2/layouts/MenuOffCanvas'
import AMenuOffCanvas from '@/v2/layouts/AccountMenu'
import BookingOffCanvas from '@/v2/layouts/BookingOffCanvas'
import TripDetails from '@/v2/layouts/TripDetails'


// export function define app used 
 function CustomComponents(app) {
    /**
     * Define each component as a global app component
     * 
     */

    app.component('NavBarB', NavBarB);
    app.component('MenuOffCanvas', MenuOffCanvas);
    app.component('AMenuOffCanvas', AMenuOffCanvas);
    app.component('BookingOffCanvas', BookingOffCanvas);
    app.component('TripDetails', TripDetails);
};

export default CustomComponents;